.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn-active:before {
  content: "\f08a";
  position: absolute;
  left: 0;
  width: 100%;
  color: white !important;
  font-family: FontAwesome;
  font-size: 19px;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn-active {
  margin-top: 0;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 500;
  padding: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  float: left;
  font-size: 0;
  background: black !important;
}

/* .wishlist {
  content: "\f08a";
  position: absolute;
  left: 0;
  width: 100%;
  color: blue !important;
  font-family: FontAwesome;
  font-size: 19px;
} */

@media (min-width: 768px) {
  .col-md-42 {
    width: 33.333% !important;
  }
}
