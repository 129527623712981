/* 
1. General 
2. Animate
3. Header
4. Breadcrumbs
5. Block
6. Product Functions
7. Shop
8. Blog
9. Pages
10. Footer
*/

/*===================================================================
1. General
====================================================================*/
:root {
  --wishlist-color: black;
  --wishlist-bk-color: transparent;
}
a:hover,
[class~="h1"],
[class~="h3"],
[class~="h2"] {
  color: #000;
}
.position-h-center.animation-round {
  left: 45%;
}
body.home .section .block-title.title-underline .title-right a:before,
[class~="custom-radio"] li label:before {
  position: absolute;
}
a:hover {
  text-decoration: none;
}
[class~="h3"],
[class~="h2"],
[class~="h4"],
[class~="h1"] {
  margin-bottom: 0.5rem;
}
.custom-radio li input:checked + label:before {
  background: #000;
}
.btn-primary:hover {
  color: #fff;
}
[class~="h4"],
[class~="h3"],
[class~="h1"],
[class~="h2"] {
  font-family: Barlow Semi Condensed;
}
a:focus {
  text-decoration: unset;
}
a:focus {
  outline: unset;
}
.btn-primary:hover {
  background-color: #2b929f;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  display: flex;
}
input[type="email"] {
  border-left-width: 0.75pt;
}
[class~="custom-radio"] li label:before {
  left: 0;
}
[class~="h4"],
[class~="h2"],
[class~="h3"],
[class~="h1"] {
  font-weight: 400;
}
input[type="email"] {
  border-bottom-width: 0.75pt;
}
[class~="h2"],
[class~="h1"],
[class~="h3"],
[class~="h4"] {
  line-height: 1.2;
}
.btn-default:hover:before,
[class~="btn-primary"]:hover:before {
  height: 100%;
}
input[type="email"] {
  border-right-width: 0.75pt;
}
input[type="email"] {
  border-top-width: 0.75pt;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  font-size: 15px;
}
.btn-primary:hover {
  border-left-color: #288995;
}
input[type="email"] {
  border-left-style: solid;
}
.btn-primary:hover {
  border-bottom-color: #288995;
}
.custom-radio li input {
  display: none;
}
[class~="btn-underline"][class~="center"]:hover:before {
  left: 25%;
}
.btn-primary:hover {
  border-right-color: #288995;
}
input[type="email"] {
  border-bottom-style: solid;
}
input[type="email"] {
  border-right-style: solid;
}
body.home .section .block-title.title-underline .title-right a:before {
  content: "";
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  display: inline-block;
}
.btn-primary:hover {
  border-top-color: #288995;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a {
  position: relative;
}
input[type="email"] {
  border-top-style: solid;
}
[class~="btn-default"]:hover {
  color: #fff !important;
}
input[type="email"] {
  border-left-color: #e5e5e5;
}
.btn-default:hover:before,
[class~="btn-primary"]:hover:before {
  opacity: 1;
}
.btn-default:hover:before,
[class~="btn-primary"]:hover:before {
  visibility: visible;
}
[class~="btn-default"]:hover,
[class~="btn-primary"]:hover {
  border-left-color: #000 !important;
}
[class~="btn-default"]:hover {
  border-bottom-color: #000 !important;
}
.custom-radio li input:checked + label:before {
  border-left-color: #000;
}
[class~="btn-underline"][class~="center"]:hover:before {
  right: 25%;
}
input[type="email"] {
  border-bottom-color: #e5e5e5;
}
html {
  font-family: sans-serif;
}
html {
  line-height: 1.15;
}
input[type="email"] {
  border-right-color: #e5e5e5;
}
[class~="custom-radio"] li label:before {
  border-radius: 50%;
}
html {
  -webkit-text-size-adjust: 100%;
}
input[type="email"] {
  border-top-color: #e5e5e5;
}
[class~="custom-radio"] li label:before {
  -webkit-border-radius: 50%;
}
input[type="email"] {
  border-image: none;
}
html {
  -ms-text-size-adjust: 100%;
}
input[type="email"] {
  padding-left: 0.15625in;
}
.custom-radio li input:checked + label:before {
  border-bottom-color: #000;
}
.custom-radio li label {
  margin-left: 0;
}
.custom-radio li label {
  margin-bottom: 0;
}
html {
  -ms-overflow-style: scrollbar;
}
[class~="btn-default"]:hover,
[class~="btn-primary"]:hover {
  border-right-color: #000 !important;
}
[class~="btn-underline"]:hover:before {
  width: 50%;
}
body i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  content: "\f104";
}
[class~="h5"],
[class~="h4"] {
  color: #000;
}
[class~="h5"],
[class~="h6"] {
  margin-bottom: 0.5rem;
}
[class~="h6"],
[class~="h5"] {
  font-family: Barlow Semi Condensed;
}
[class~="h5"],
[class~="h6"] {
  font-weight: 400;
}
[class~="no-space"] [class~="row"] > div {
  padding-left: 0;
}
[class~="h5"],
[class~="h6"] {
  line-height: 1.2;
}
[class~="btn-primary"]:hover,
[class~="btn-default"]:hover {
  border-top-color: #000 !important;
}
.custom-radio li label,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  margin-right: 0;
}
[class~="small-space"] [class~="row"] > div,
[class~="no-space"] [class~="row"] > div {
  padding-bottom: 0;
}
[class~="no-space"] [class~="row"] > div {
  padding-right: 0;
}
html {
  -webkit-tap-highlight-color: transparent;
}
input[type="email"] {
  padding-bottom: 5.25pt;
}
[class~="btn-primary"]:hover {
  color: #fff !important;
}
input[type="email"] {
  padding-right: 0.15625in;
}
body[class~="home"][class~="title-12"]
  [class~="section"]
  [class~="block-title"][class~="title-big"]
  h2 {
  font-size: 27pt;
}
[class~="custom-radio"] li label:before {
  -moz-border-radius: 50%;
}
[class~="no-space"] [class~="row"] > div {
  padding-top: 0;
}
input[type="email"] {
  padding-top: 5.25pt;
}
[class~="btn-primary"]:hover {
  border-bottom-color: #000 !important;
}
input[type="email"] {
  background: transparent;
}
input[type="email"] {
  border-radius: 0;
}
input[type="email"] {
  -webkit-border-radius: 0;
}
input[type="email"] {
  -moz-border-radius: 0;
}
[class~="small-space"] [class~="row"] > div {
  padding-left: 0.46875pc;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2,
body {
  margin-left: 0;
}
input[type="email"] {
  -ms-border-radius: 0;
}
body.home .section .block-title.title-underline .title-right a:before {
  background: #000;
}
[class~="button-white"]:focus,
[class~="button-white"]:hover {
  color: #fff;
}
input[type="email"] {
  -o-border-radius: 0;
}
[class~="button-white"]:focus,
[class~="button-white"]:hover {
  background-color: #000;
}
[class~="button-black"]:hover,
[class~="h6"] {
  color: #000;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  position: relative;
}
html {
  font-size: 11.25pt;
}
input[type="email"] {
  box-shadow: none;
}
.table {
  width: 100%;
}
input[type="email"] {
  -moz-appearance: none;
}
[class~="custom-radio"] li label:before {
  -ms-border-radius: 50%;
}
.table {
  max-width: 100%;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2,
body {
  margin-bottom: 0;
}
.table {
  margin-bottom: 1rem;
}
input[type="email"] {
  -o-appearance: none;
}
input[type="email"] {
  -webkit-appearance: none;
}
[class~="button-black"]:hover {
  background: transparent;
}
.table {
  background-color: transparent;
}
[class~="button"] {
  border-left-width: 0;
}
[class~="custom-radio"] li label:before,
[class~="button-black"]:hover,
input[type="number"] {
  border-left-width: 0.0625pc;
}
[class~="custom-radio"] li label:before,
[class~="button-black"]:hover,
input[type="number"] {
  border-bottom-width: 0.75pt;
}
[class~="button"] {
  border-bottom-width: 0;
}
[class~="small-space"] [class~="row"] > div {
  padding-right: 0.46875pc;
}
[class~="button-black"]:hover,
input[type="number"],
[class~="custom-radio"] li label:before {
  border-right-width: 0.75pt;
}
body i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  content: "\f105";
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  justify-content: space-between;
}
[class~="button"] {
  border-right-width: 0;
}
[class~="button"] {
  border-top-width: 0;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2,
.custom-radio li label,
body {
  margin-top: 0;
}
[class~="button"] {
  border-left-style: none;
}
[class~="button"] {
  border-bottom-style: none;
}
[class~="custom-radio"] li label:before {
  -o-border-radius: 50%;
}
[class~="button"] {
  border-right-style: none;
}
input[type="number"],
[class~="custom-radio"] li label:before,
[class~="button-black"]:hover {
  border-top-width: 0.75pt;
}
[class~="custom-radio"] li label:before {
  top: calc(50% - 6px);
}
.custom-radio li label {
  font-weight: 400;
}
[class~="custom-radio"] li label:before {
  width: 9pt;
}
[class~="button-black"]:hover,
input[type="number"],
[class~="custom-radio"] li label:before {
  border-left-style: solid;
}
body {
  margin-right: 0;
}
[class~="custom-radio"] li label:before {
  height: 0.75pc;
}
[class~="button-black"]:hover,
input[type="number"],
[class~="custom-radio"] li label:before {
  border-bottom-style: solid;
}
[class~="button"] {
  border-top-style: none;
}
.custom-radio li input:checked + label:before {
  border-right-color: #000;
}
[class~="button"] {
  border-left-color: currentColor;
}
[class~="button"] {
  border-bottom-color: currentColor;
}
[class~="button"] {
  border-right-color: currentColor;
}
[class~="button"] {
  border-top-color: currentColor;
}
[class~="small-space"] [class~="row"] > div {
  padding-top: 0;
}
.custom-radio li label {
  position: relative;
}
.custom-radio li label {
  padding-left: 1.125pc;
}
[class~="button-black"]:hover,
[class~="custom-radio"] li label:before,
input[type="number"] {
  border-right-style: solid;
}
[class~="custom-radio"] li label:before,
[class~="button-black"]:hover,
input[type="number"] {
  border-top-style: solid;
}
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-left: 0;
}
input[type="number"] {
  border-left-color: #e5e5e5;
}
input[type="number"] {
  border-bottom-color: #e5e5e5;
}
[class~="button"] {
  border-image: none;
}
body {
  font-family: Barlow Semi Condensed;
}
input[type="number"] {
  border-right-color: #e5e5e5;
}
input[type="number"] {
  border-top-color: #e5e5e5;
}
[class~="btn"],
input[type="number"] {
  border-radius: 0;
}
input[type="number"] {
  border-image: none;
}
input[type="number"] {
  padding-left: 0.15625in;
}
[class~="btn"]:not(:disabled):not([class~="disabled"]),
.custom-radio li label,
.clear-all:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.position-h-center,
[class~="position-v-top"] {
  position: absolute;
}
[class~="button-black"]:hover {
  border-left-color: #000;
}
[class~="price"] del span {
  text-decoration: line-through;
}
input[type="number"] {
  padding-bottom: 5.25pt;
}
body {
  font-size: 1rem;
}
[class~="position-v-top"] {
  top: 0;
}
[class~="x-small-space"] [class~="row"] > div {
  padding-left: 0.052083333in;
}
[class~="position-v-center"] {
  align-content: center;
}
body {
  font-weight: 400;
}
[class~="position-v-center"] {
  align-items: center;
}
input[type="number"] {
  padding-right: 0.15625in;
}
[class~="section"][class~="content-outside"]
  [class~="block-widget-wrap"]
  > div {
  margin-bottom: -21.25pc;
}
[class~="x-small-space"] [class~="row"] > div,
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"],
[class~="large-space"] [class~="row"] > div {
  padding-bottom: 0;
}
[class~="button-black"]:hover,
.custom-radio li input:checked + label:before {
  border-top-color: #000;
}
[class~="position-v-center"] {
  display: -webkit-box;
}
input[type="number"] {
  padding-top: 5.25pt;
}
body i[class~="slick-arrow"],
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  font-size: 1.25pc;
}
[class~="custom-radio"] li label:before {
  border-left-color: #868686;
}
[class~="position-v-center"] {
  display: -ms-flexbox;
}
[class~="position-v-center"] {
  display: flex;
}
[class~="position-v-center"] {
  height: 100%;
}
body i[class~="slick-arrow"] {
  line-height: 0.375in;
}
[class~="custom-radio"] li label:before {
  border-bottom-color: #868686;
}
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-right: 0;
}
input[type="number"],
.button-black:focus {
  background: transparent;
}
body i[class~="slick-arrow"] {
  text-align: center;
}
[class~="x-small-space"] [class~="row"] > div {
  padding-right: 0.052083333in;
}
body {
  line-height: 1.8;
}
.position-h-center {
  left: 50%;
}
.position-h-center {
  -webkit-transform: translateX(-50%);
}
[class~="button-black"]:hover {
  border-bottom-color: #000;
}
.position-h-center {
  transform: translateX(-50%);
}
input[type="number"] {
  -webkit-border-radius: 0;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a,
[class~="btn-primary"] {
  color: #fff;
}
[class~="btn-primary"] {
  background-color: #33aebd;
}
body.home .section .block-title.title-underline .title-right a:before {
  width: 100%;
}
[class~="x-small-space"] [class~="row"] > div {
  padding-top: 0;
}
input[type="number"] {
  -moz-border-radius: 0;
}
input[type="number"] {
  -ms-border-radius: 0;
}
[class~="button-black"]:hover {
  border-right-color: #000;
}
[class~="btn-primary"] {
  border-left-color: #33aebd;
}
ul[class~="slick-dots"] li[class~="slick-active"] button,
[class~="button-outline"][class~="white"]:hover {
  background: #000;
}
body {
  color: #868686;
}
[class~="large-space"] [class~="row"] > div,
[class~="button-white"] {
  padding-left: 22.5pt;
}
[class~="btn-primary"] {
  border-bottom-color: #33aebd;
}
[class~="button-black"]:hover {
  border-image: none;
}
[class~="btn-primary"] {
  border-right-color: #33aebd;
}
.button-black:focus {
  color: #000;
}
[class~="btn-primary"] {
  border-top-color: #33aebd;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  text-transform: uppercase;
}
body {
  text-align: left;
}
[class~="btn-primary"] {
  box-shadow: inset 0 0.75pt 0 hsla(0, 0%, 100%, 0.15),
    0 0.75pt 0.0625pc rgba(0, 0, 0, 0.075);
}
[class~="custom-radio"] li label:before {
  border-right-color: #868686;
}
body {
  background-color: transparent;
}
[class~="button-white"],
.rating.small .star:after {
  font-size: 0.135416667in;
}
.button-black:focus,
input[type="password"] {
  border-left-width: 0.0625pc;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a,
[class~="rating"] [class~="star"]:after,
[class~="button-white"],
[class~="btn-underline"] {
  display: inline-block;
}
[class~="btn-primary"],
[class~="btn-underline"],
[class~="btn-default"] {
  position: relative;
}
input[type="number"] {
  -o-border-radius: 0;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  line-height: 0.46875in;
}
body i[class~="slick-arrow"] {
  top: 50%;
}
input[type="password"],
.button-black:focus {
  border-bottom-width: 0.0625pc;
}
.button-black:focus,
input[type="password"] {
  border-right-width: 0.0625pc;
}
[class~="btn-default"],
[class~="btn-primary"] {
  z-index: 0;
}
input[type="number"] {
  box-shadow: none;
}
input[type="password"],
.button-black:focus {
  border-top-width: 0.0625pc;
}
h1,
h2 {
  margin-bottom: 0.5rem;
}
input[type="number"] {
  -moz-appearance: none;
}
input[type="number"] {
  -o-appearance: none;
}
[class~="btn-underline"] {
  padding-bottom: 0.1875pc;
}
input[type="number"] {
  -webkit-appearance: none;
}
[class~="custom-radio"] li label:before {
  border-top-color: #868686;
}
[class~="custom-radio"] li label:before {
  border-image: none;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  padding-bottom: 0.15625in;
}
[class~="button-black"],
[class~="button-white"] {
  font-weight: 500;
}
[class~="button-black"],
[class~="button-white"] {
  line-height: 40px;
}
.button-black:focus,
input[type="password"] {
  border-left-style: solid;
}
[class~="button-black"],
.rating.small .star:after,
[class~="button-white"] {
  letter-spacing: 0.125pc;
}
body i[class~="slick-arrow"] {
  -webkit-transform: translateY(-50%);
}
[class~="button-white"],
[class~="large-space"] [class~="row"] > div {
  padding-right: 22.5pt;
}
h2,
h1 {
  font-family: Barlow Semi Condensed;
}
h2,
h1 {
  font-weight: 400;
}
h2,
h1 {
  line-height: 1.2;
}
[class~="button-black"],
[class~="button-white"] {
  padding-bottom: 0pc;
}
[class~="custom-radio"] li label:before {
  content: "";
}
h1 {
  color: #000;
}
[class~="button-white"] {
  padding-top: 0pc;
}
[class~="large-space"] [class~="row"] > div {
  padding-top: 0;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  bottom: -14px;
}
input[type="password"],
.button-black:focus {
  border-bottom-style: solid;
}
.button-black:focus,
input[type="password"] {
  border-right-style: solid;
}
[class~="button-white"] {
  background-color: #fff;
}
ul.slick-dots li button {
  font-size: 0;
}
[class~="button-black"] {
  font-size: 13px;
}
body i[class~="slick-arrow"] {
  transform: translateY(-50%);
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:first-child {
  left: 0.125in;
}
input[type="password"],
.button-black:focus {
  border-top-style: solid;
}
.button-black:focus {
  border-left-color: #000;
}
body[class~="home"][class~="title-12"]
  [class~="section"]
  [class~="block-title"][class~="title-big"]
  h2 {
  text-transform: uppercase;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  letter-spacing: 2.25pt;
}
.button-black:focus {
  border-bottom-color: #000;
}
body i[class~="slick-arrow"] {
  margin-top: -7.5pt;
}
ul.slick-dots li button {
  width: 9px;
}
[class~="button-outline"]:hover,
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  background: #000;
}
ul.slick-dots li button {
  height: 9px;
}
.button-black:focus {
  border-right-color: #000;
}
.button-black:focus {
  border-top-color: #000;
}
[class~="button-black"],
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-left: 1.875pc;
}
.button-black:focus {
  border-image: none;
}
[class~="rating"] [class~="star"]:after {
  color: #f5bf1c;
}
ul.slick-dots li button {
  background: rgba(0, 0, 0, 0.3);
}
ul.slick-dots li button {
  padding-left: 0;
}
[class~="button-black"] {
  padding-right: 0.3125in;
}
[class~="button-outline"]:hover {
  color: #fff;
}
[class~="rating"] [class~="star"]:after {
  font-family: icomoon;
}
[class~="button-outline"][class~="white"] {
  background: #fff;
}
[class~="button-black"] {
  padding-top: 0in;
}
[class~="button-black"] {
  border-left-width: 0.010416667in;
}
[class~="button-black"] {
  border-bottom-width: 0.010416667in;
}
[class~="section"][class~="top-border"],
[class~="button-black"] {
  border-top-width: 1px;
}
[class~="button-black"] {
  border-right-width: 0.010416667in;
}
[class~="section"][class~="top-border"],
[class~="button-black"] {
  border-top-style: solid;
}
input[type="password"] {
  border-left-color: #e5e5e5;
}
[class~="rating"] [class~="star"]:after {
  font-size: 11.25pt;
}
[class~="button-black"] {
  border-left-style: solid;
}
h2,
h3 {
  color: #000;
}
h3,
h4 {
  margin-bottom: 0.5rem;
}
input[type="password"],
[class~="section"][class~="top-border"] {
  border-top-color: #e5e5e5;
}
input[type="password"] {
  border-bottom-color: #e5e5e5;
}
input[type="password"] {
  border-right-color: #e5e5e5;
}
body i[class~="slick-arrow"] {
  position: absolute;
}
h3,
h4 {
  font-family: Barlow Semi Condensed;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  position: relative;
}
h4,
h3 {
  font-weight: 400;
}
[class~="button-black"] {
  border-bottom-style: solid;
}
h3,
h4 {
  line-height: 1.2;
}
[class~="button-black"] {
  border-right-style: solid;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  margin-bottom: 2.5pc;
}
input[type="password"],
[class~="section"][class~="top-border"] {
  border-image: none;
}
[class~="button-black"] {
  border-left-color: #000;
}
[class~="button-black"] {
  border-bottom-color: #000;
}
input[type="password"] {
  padding-left: 0.15625in;
}
body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"],
[class~="rating"] [class~="star"]:after {
  line-height: 1;
}
[class~="section"][class~="p-t-20"] {
  padding-top: 1.25pc;
}
[class~="button-black"] {
  border-right-color: #000;
}
[class~="product-table"] tbody tr td:first-child {
  background: #f5f5f5 !important;
}
[class~="section"][class~="p-t-50"] {
  padding-top: 3.125pc;
}
body i[class~="slick-arrow"] {
  z-index: 1;
}
[class~="button-black"] {
  border-top-color: #000;
}
ul.slick-dots li button,
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-bottom: 0;
}
input[type="password"] {
  padding-bottom: 5.25pt;
}
.section.p-t-70 {
  padding-top: 4.375pc;
}
[class~="rating"] [class~="star"]:after {
  letter-spacing: 0.3125pc;
}
input[type="password"] {
  padding-right: 0.15625in;
}
input[type="password"] {
  padding-top: 5.25pt;
}
[class~="section"][class~="p-t-80"] {
  padding-top: 5pc;
}
[class~="section"][class~="p-t-100"] {
  padding-top: 1.041666667in;
}
[class~="button-black"] {
  border-image: none;
}
ul.slick-dots li button {
  padding-right: 0;
}
ul.slick-dots li button {
  padding-top: 0;
}
body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 3.125pc;
}
[class~="button-black"] {
  background-color: #000;
}
ul.slick-dots li button {
  border-radius: 50%;
}
[class~="button-black"] {
  color: #fff;
}
[class~="button-outline"],
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-bottom-width: 0.0625pc;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:first-child {
  animation: i 0.6s infinite;
}
body i[class~="slick-arrow"],
h4 {
  color: #000;
}
[class~="product-table"] tbody tr td:first-child {
  font-size: 16px;
}
[class~="button-black"],
[class~="rating"] [class~="star"]:before {
  display: inline-block;
}
input[type="password"] {
  background: transparent;
}
[class~="section"][class~="p-b-20"] {
  padding-bottom: 0.208333333in;
}
[class~="button-outline"],
.rating.small .star:before {
  font-size: 13px;
}
body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  color: #868686;
}
[class~="button-outline"],
body i[class~="slick-arrow"] {
  font-weight: 500;
}
[class~="section"][class~="p-b-50"] {
  padding-bottom: 3.125pc;
}
[class~="section"][class~="p-b-70"] {
  padding-bottom: 0.729166667in;
}
ul.slick-dots li button {
  -webkit-border-radius: 50%;
}
[class~="slick-wrap"]:hover [class~="slick-arrow"] {
  opacity: 1;
}
[class~="button-outline"] {
  line-height: 30pt;
}
[class~="section"][class~="p-b-80"] {
  padding-bottom: 80px;
}
[class~="button-outline"],
.rating.small .star:before {
  letter-spacing: 0.125pc;
}
[class~="button-outline"] {
  border-left-width: 0.010416667in;
}
input[type="password"] {
  border-radius: 0;
}
[class~="slick-wrap"]:hover [class~="slick-arrow"] {
  visibility: visible;
}
.trendingpro:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}
input[type="password"] {
  -webkit-border-radius: 0;
}
body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2,
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 300;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:hover:before {
  width: 50%;
}
[class~="button-outline"] {
  border-right-width: 0.010416667in;
}
[class~="button-outline"] {
  border-top-width: 0.010416667in;
}
body.home .section .block-title.title-underline .title-right a:before {
  height: 0.75pt;
}
[class~="rating"] [class~="star"]:before {
  color: #f5bf1c;
}
[class~="section"][class~="p-b-100"] {
  padding-bottom: 75pt;
}
h6,
h5 {
  margin-bottom: 0.5rem;
}
[class~="section"][class~="content-outside"] {
  margin-bottom: 25.625pc;
}
h5 {
  font-family: Barlow Semi Condensed;
}
input[type="password"] {
  -moz-border-radius: 0;
}
ul.slick-dots li button {
  -moz-border-radius: 50%;
}
[class~="button-outline"] {
  border-left-style: solid;
}
ul.slick-dots li button {
  -ms-border-radius: 50%;
}
h5,
h6 {
  font-weight: 400;
}
input[type="password"] {
  -ms-border-radius: 0;
}
[class~="rating"] [class~="star"]:before {
  font-family: icomoon;
}
[class~="rating"] [class~="star"]:before {
  font-size: 11.25pt;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"],
[class~="button-outline"] {
  border-bottom-style: solid;
}
input[type="password"] {
  -o-border-radius: 0;
}
input[type="password"] {
  box-shadow: none;
}
[class~="section"][class~="content-outside"] {
  padding-top: 100px;
}
[class~="section"][class~="background-1"] {
  background-color: #e0e8f1;
}
[class~="section"][class~="background-2"] {
  background-color: #dfe5dd;
}
[class~="button-outline"] {
  border-right-style: solid;
}
[class~="section"][class~="background-3"] {
  background-color: #f2eee7;
}
[class~="button-outline"] {
  border-top-style: solid;
}
[class~="rating"] [class~="star"]:before {
  line-height: 1;
}
input[type="password"] {
  -moz-appearance: none;
}
[class~="button-outline"] {
  border-left-color: #000;
}
h6,
h5 {
  line-height: 1.2;
}
[class~="rating"] [class~="star"]:before {
  letter-spacing: 0.3125pc;
}
body i[class~="slick-arrow"] {
  -webkit-transition: all 0.3s ease;
}
[class~="button-outline"] {
  border-bottom-color: #000;
}
[class~="button-outline"] {
  border-right-color: #000;
}
[class~="button-outline"] {
  border-top-color: #000;
}
h6,
h5,
[class~="button-outline"] {
  color: #000;
}
[class~="button-outline"] {
  border-image: none;
}
[class~="section"][class~="background-4"] {
  background-color: #eeecf2;
}
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.291666667in;
}
[class~="button-outline"] {
  padding-left: 0.3125in;
}
[class~="button-outline"] {
  padding-bottom: 0in;
}
body i[class~="slick-arrow"] {
  transition: all 0.3s ease;
}
[class~="button-outline"] {
  padding-right: 0.3125in;
}
input[type="password"] {
  -o-appearance: none;
}
[class~="button-outline"] {
  padding-top: 0in;
}
[class~="button-outline"] {
  background: transparent;
}
[class~="rating"] [class~="star"]:after {
  color: #e1e1e1;
}
body i[class~="slick-arrow"] {
  font-family: FontAwesome;
}
ul[class~="slick-dots"] li,
[class~="button-outline"] {
  display: inline-block;
}
[class~="select2-container"] {
  width: 100% !important;
}
input[type="password"] {
  -webkit-appearance: none;
}
[class~="section"] {
  margin-bottom: 50px;
}
ul.slick-dots li button {
  -o-border-radius: 50%;
}
h6 {
  font-family: Barlow Semi Condensed;
}
[class~="section"][class~="background-5"] {
  background-color: #cdc2bb;
}
[class~="section-padding"] {
  padding-left: 0.234375in;
}
[class~="section"][class~="background-6"] {
  background-color: #ece5e0;
}
[class~="section"][class~="background-7"] {
  background-color: #e1ddd9;
}
input[type="tel"] {
  border-left-width: 0.75pt;
}
input[type="tel"] {
  border-bottom-width: 0.75pt;
}
input[type="tel"] {
  border-right-width: 0.75pt;
}
[class~="section"][class~="background-8"] {
  background-color: #f5f5f5;
}
[class~="section"][class~="background-9"] {
  background-color: #ced3cd;
}
[class~="page-preloader"] [class~="loader"] div {
  position: absolute;
}
[class~="section-small-padding"],
[class~="section-padding"] {
  padding-bottom: 0;
}
[class~="section"][class~="background-10"] {
  background-color: #d5d8e4;
}
[class~="section"][class~="background-11"] {
  background-color: #e7ecef;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(2) {
  left: 12px;
}
[class~="section"][class~="background-12"] {
  background-color: #f1eee8;
}
img {
  border-left-width: 0;
}
img {
  border-bottom-width: 0;
}
[class~="section-padding"] {
  padding-right: 0.234375in;
}
img {
  border-right-width: 0;
}
[class~="section"][class~="newsletter-background"] {
  /* background-image: url("../images/banner/newsletter-bg.jpg"); */
}
input[type="tel"] {
  border-top-width: 0.75pt;
}
[class~="product-table"] tbody tr td:first-child {
  color: #000 !important;
}
[class~="section-small-padding"],
[class~="section-padding"] {
  padding-top: 0;
}
ul[class~="slick-dots"] li {
  vertical-align: bottom;
}
img {
  border-top-width: 0;
}
[class~="section-small-padding"] {
  padding-left: 0.625pc;
}
img {
  border-left-style: none;
}
img {
  border-bottom-style: none;
}
input[type="tel"] {
  border-left-style: solid;
}
input[type="tel"] {
  border-bottom-style: solid;
}
[class~="section-small-padding"] {
  padding-right: 0.625pc;
}
[class~="rating"] [class~="star"][class~="star-1"]:before {
  content: "";
}
ul.slick-dots li button {
  -webkit-transition: all 0.2s ease;
}
[class~="section"][class~="newsletter-background"],
[class~="section"][class~="contact-background"] {
  background-position: center center;
}
[class~="section-container"] {
  max-width: 14.84375in;
}
[class~="section"][class~="contact-background"],
[class~="section"][class~="newsletter-background"] {
  background-repeat: no-repeat;
}
ul.slick-dots li button {
  transition: all 0.2s ease;
}
[class~="section-container"] {
  margin-right: auto;
}
[class~="section-container"] {
  margin-left: auto;
}
[class~="section"][class~="contact-background"],
[class~="section"][class~="newsletter-background"] {
  background-size: cover;
}
[class~="section-row"],
.section-column-wrap {
  width: 100%;
}
[class~="page-preloader"] [class~="loader"] div {
  border-left-width: 0.041666667in;
}
[class~="section-row"] {
  display: -webkit-box;
}
[class~="section"][class~="newsletter-background"] {
  padding-top: 6.875pc;
}
[class~="section"][class~="newsletter-background"] {
  padding-bottom: 82.5pt;
}
[class~="section-row"] {
  display: -ms-flexbox;
}
input[type="tel"] {
  border-right-style: solid;
}
input[type="tel"] {
  border-top-style: solid;
}
[class~="section"][class~="contact-background"] {
  /* background-image: url("../images/banner/contact-bg.jpg"); */
}
[class~="section-row"] {
  display: flex;
}
img {
  border-right-style: none;
}
img {
  border-top-style: none;
}
input[type="tel"] {
  border-left-color: #e5e5e5;
}
ul.slick-dots li button,
.section-column-wrap,
[class~="section-column"] {
  position: relative;
}
[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"],
.no-space .row {
  margin-left: 0;
}
[class~="section-column"] {
  display: -webkit-box;
}
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  line-height: 1;
}
[class~="section"][class~="contact-background"] {
  padding-left: 0;
}
[class~="section-column"] {
  display: -ms-flexbox;
}
input[type="tel"] {
  border-bottom-color: #e5e5e5;
}
[class~="section"][class~="contact-background"] {
  padding-bottom: 0.833333333in;
}
[class~="product-table"] tbody tr td {
  border-left-width: 0.0625pc !important;
}
[class~="section"][class~="contact-background"] {
  padding-right: 0;
}
[class~="rating"] [class~="star"][class~="star-2"]:before {
  content: "";
}
img {
  border-left-color: currentColor;
}
img {
  border-bottom-color: currentColor;
}
[class~="section-column"] {
  display: flex;
}
img {
  border-right-color: currentColor;
}
[class~="rating"] [class~="star"][class~="star-3"]:before {
  content: "";
}
[class~="product-table"] tbody tr td {
  border-left-style: solid !important;
}
[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"],
ul[class~="slick-dots"] li,
[class~="small-space"] [class~="row"],
.no-space .row {
  margin-bottom: 0;
}
.section-column-wrap {
  display: -webkit-box;
}
[class~="section"][class~="contact-background"] {
  padding-top: 3.75pc;
}
.rating .star.star-4:before {
  content: "";
}
body.home .section .block-title.title-underline .title-right a:before {
  bottom: -10px;
}
ul[class~="slick-dots"] li {
  margin-left: 6px;
}
[class~="rating"] [class~="star"][class~="star-0"]:after,
[class~="rating"] [class~="star"][class~="star-5"]:before {
  content: "";
}
.section-column-wrap {
  display: -ms-flexbox;
}
input[type="tel"] {
  border-right-color: #e5e5e5;
}
.section-column-wrap {
  display: flex;
}

[class~="section-container"][class~="small"] {
  max-width: 720pt;
}
[class~="product-table"] tbody tr td {
  border-left-color: #e5e5e5 !important;
}
[class~="section-column"][class~="left"] {
  width: 49.405%;
}
img {
  border-top-color: currentColor;
}
[class~="section-mb-l"] {
  margin-bottom: 10.625pc;
}
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-top: 0;
}
[class~="position-center"] {
  align-content: center;
}
[class~="position-center"] {
  align-items: center;
}
input[type="tel"] {
  border-top-color: #e5e5e5;
}
[class~="product-table"] tbody tr td {
  border-image: none !important;
}
[class~="section-column"][class~="right"] {
  width: 50.595%;
}
input[type="tel"],
img {
  border-image: none;
}
[class~="position-center"] {
  justify-content: center;
}
[class~="product-table"] tbody tr td:first-child {
  font-weight: 500 !important;
}
body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.333333333in;
}
body.home .section .block-title.title-underline .title-right a:before {
  left: 50%;
}
input[type="tel"] {
  padding-left: 0.15625in;
}
[class~="position-center"] {
  display: -webkit-box;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(2) {
  animation: k 0.6s infinite;
}
[class~="product-table"] tbody tr td {
  border-bottom-width: 0.75pt !important;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-bottom-color: #e4e4e4;
}
img {
  vertical-align: top;
}
[class~="position-center"] {
  display: -ms-flexbox;
}
[class~="position-center"] {
  display: flex;
}
img {
  max-width: 100%;
}
[class~="product-table"] tbody tr td {
  border-bottom-style: solid !important;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(3) {
  left: 0.375in;
}
.no-space .row {
  margin-right: 0;
}
[class~="page-preloader"] [class~="loader"] div {
  border-bottom-width: 0.041666667in;
}
body.home .section .block-title.title-underline .title-right a:before {
  -webkit-transform: translateX(-50%);
}
input[type="tel"] {
  padding-bottom: 5.25pt;
}
img {
  height: auto;
}
ul[class~="slick-dots"] li,
[class~="small-space"] [class~="row"],
[class~="x-small-space"] [class~="row"],
.no-space .row {
  margin-top: 0;
}
.column-background-wrap,
input[type="tel"] {
  padding-right: 0.15625in;
}
ul[class~="slick-dots"] li {
  margin-right: 6px;
}
input[type="tel"] {
  padding-top: 5.25pt;
}
[class~="rating"] [class~="star"][class~="star-1"]:after {
  content: "";
}
input[type="tel"] {
  background: transparent;
}
input[type="tel"] {
  border-radius: 0;
}
input[type="tel"] {
  -webkit-border-radius: 0;
}
a {
  color: #000;
}
input[type="tel"] {
  -moz-border-radius: 0;
}
a {
  text-decoration: none;
}
[class~="page-preloader"] [class~="loader"] div {
  border-right-width: 0.041666667in;
}
[class~="page-preloader"] [class~="loader"] div {
  border-top-width: 0.041666667in;
}
select,
input[type="text"] {
  border-left-width: 0.75pt;
}
[class~="position-center"] {
  height: 100%;
}
input[type="tel"] {
  -ms-border-radius: 0;
}
.column-background-wrap {
  padding-left: 5.625pc;
}
input[type="tel"] {
  -o-border-radius: 0;
}
[class~="small-space"] [class~="row"] {
  margin-left: -5.625pt;
}
.column-background-wrap {
  padding-bottom: 90px;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-right: 1.875pc;
}
input[type="text"],
select {
  border-bottom-width: 0.75pt;
}
select,
input[type="text"] {
  border-right-width: 0.75pt;
}
[class~="small-space"] [class~="row"] {
  margin-right: -5.625pt;
}
[class~="pagination"] ul li a:hover {
  color: #fff;
}
input[type="tel"] {
  box-shadow: none;
}
input[type="text"],
select {
  border-top-width: 0.75pt;
}
[class~="product-table"] tbody tr td {
  border-bottom-color: #e5e5e5 !important;
}
input[type="text"],
select,
[class~="page-preloader"] [class~="loader"] div {
  border-left-style: solid;
}
.column-background-wrap {
  padding-top: 90px;
}
[class~="product-table"] tbody tr td {
  padding-left: 15pt !important;
}
input[type="tel"] {
  -moz-appearance: none;
}
[class~="rating"] [class~="star"][class~="star-2"]:after {
  content: "";
}
[class~="x-small-space"] [class~="row"] {
  margin-left: -0.052083333in;
}
[class~="page-preloader"] [class~="loader"] div,
input[type="text"],
select {
  border-bottom-style: solid;
}
.column-background-wrap {
  background: #d7e0e3;
}
input[type="tel"] {
  -o-appearance: none;
}
[class~="animation-round"] {
  animation: l 15s infinite linear;
}
body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 300;
}
input[type="tel"] {
  -webkit-appearance: none;
}
.p-t-5 {
  padding-top: 0.3125pc;
}
[class~="x-small-space"] [class~="row"],
[class~="large-space"] [class~="row"] {
  margin-bottom: 0;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-top: 0;
}
select,
[class~="page-preloader"] [class~="loader"] div,
input[type="text"] {
  border-right-style: solid;
}
body.home.title-6 .section .block-title h2 {
  font-size: 24px;
}
[class~="pagination"] ul li a:hover {
  background: #000;
}
[class~="x-small-space"] [class~="row"] {
  margin-right: -0.052083333in;
}
.p-t-10 {
  padding-top: 0.104166667in;
}
.p-t-20 {
  padding-top: 0.208333333in;
}
[class~="p-t-30"] {
  padding-top: 1.875pc;
}
[class~="p-t-50"] {
  padding-top: 3.125pc;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  font-weight: 500;
}
.p-t-70 {
  padding-top: 70px;
}
input[type="text"],
select,
[class~="page-preloader"] [class~="loader"] div {
  border-top-style: solid;
}
.p-t-80 {
  padding-top: 0.833333333in;
}
[class~="large-space"] [class~="row"] {
  margin-left: -22.5pt;
}
input[type="text"],
select {
  border-left-color: #e5e5e5;
}
input[type="text"],
select {
  border-bottom-color: #e5e5e5;
}
select,
input[type="text"] {
  border-right-color: #e5e5e5;
}
[class~="p-t-100"] {
  padding-top: 75pt;
}
select {
  border-top-color: #e5e5e5;
}
.p-b-50 {
  padding-bottom: 37.5pt;
}
select,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-image: none;
}
select {
  padding-left: 0.15625in;
}
[class~="large-space"] [class~="row"] {
  margin-right: -22.5pt;
}
[class~="p-b-70"] {
  padding-bottom: 52.5pt;
}
select {
  padding-bottom: 5.25pt;
}
select {
  padding-right: 0.15625in;
}
[class~="large-space"] [class~="row"] {
  margin-top: 0;
}
ul[class~="slick-dots"] li {
  width: 0.09375in;
}
[class~="product-table"] tbody tr td {
  padding-bottom: 15pt !important;
}
[class~="form-control"]:focus {
  border-left-width: medium;
}
[class~="form-control"]:focus {
  border-bottom-width: medium;
}
[class~="p-b-80"] {
  padding-bottom: 60pt;
}
[class~="form-control"]:focus {
  border-right-width: medium;
}
body.home .section .block-title.title-underline .title-right a:before {
  transform: translateX(-50%);
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a,
body.home .section .block-title.title-underline .title-right a:before {
  -webkit-transition: all 0.3s ease;
}
[class~="p-b-100"] {
  padding-bottom: 6.25pc;
}
[class~="form-control"]:focus {
  border-top-width: medium;
}
[class~="m-t-0"] {
  margin-top: 0;
}
[class~="product-table"] tbody tr td {
  padding-right: 15pt !important;
}
[class~="form-control"]:focus {
  border-left-style: none;
}
[class~="m-t-5"] {
  margin-top: 0.3125pc;
}
[class~="m-t-10"] {
  margin-top: 0.104166667in;
}
[class~="form-control"]:focus {
  border-bottom-style: none;
}
[class~="rating"] [class~="star"][class~="star-3"]:after {
  content: "";
}
select {
  padding-top: 5.25pt;
}
[class~="form-control"]:focus {
  border-right-style: none;
}
[class~="form-control"]:focus {
  border-top-style: none;
}
[class~="m-t-20"] {
  margin-top: 20px;
}
[class~="form-control"]:focus {
  border-left-color: currentColor;
}
[class~="product-table"] tbody tr td {
  padding-top: 15pt !important;
}
select {
  background: transparent;
}
body.home .section .block-title h2,
[class~="m-b-0"] {
  margin-bottom: 0;
}
[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-left-color: #000;
}
[class~="product-table"] tbody tr td {
  background: #fff !important;
}
[class~="m-b-10"] {
  margin-bottom: 7.5pt;
}
[class~="m-b-15"] {
  margin-bottom: 15px;
}
[class~="form-control"]:focus {
  border-bottom-color: currentColor;
}
[class~="m-b-20"] {
  margin-bottom: 0.208333333in;
}
[class~="form-control"]:focus {
  border-right-color: currentColor;
}
[class~="m-b-30"] {
  margin-bottom: 1.875pc;
}
input[type="text"] {
  border-top-color: #e5e5e5;
}
[class~="m-b-60"] {
  margin-bottom: 60px;
}
select,
input[type="text"] {
  border-radius: 0;
}
ul[class~="slick-dots"] li {
  height: 0.09375in;
}
[class~="form-control"]:focus {
  border-top-color: currentColor;
}
input[type="text"],
[class~="form-control"]:focus {
  border-image: none;
}
[class~="form-control"]:focus {
  box-shadow: none;
}
[class~="pagination"] ul li span {
  display: table-cell;
}
[class~="pagination"] ul li span,
[class~="p-0"],
.fullwidth .block-widget-wrap {
  padding-left: 0;
}
[class~="pagination"] ul li span,
.fullwidth .block-widget-wrap,
[class~="p-0"] {
  padding-bottom: 0;
}
[class~="p-0"],
[class~="pagination"] ul li span,
.fullwidth .block-widget-wrap {
  padding-right: 0;
}
[class~="p-0"],
.fullwidth .block-widget-wrap {
  padding-top: 0;
}
[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-bottom-color: #000;
}
[class~="page-preloader"] [class~="loader"],
[class~="rating"] [class~="review-count"],
[class~="content-product-list"] [class~="products-thumb"],
[class~="rating"] [class~="star"] {
  display: inline-block;
}
[class~="m-b-70"] {
  margin-bottom: 70px;
}
body.home .section .block-title.title-underline .title-right a:before {
  transition: all 0.3s ease;
}
input[type="text"] {
  padding-left: 0.15625in;
}
[class~="content-product-list"] [class~="products-thumb"] {
  vertical-align: top;
}
[class~="m-b-80"] {
  margin-bottom: 5pc;
}
[class~="m-b-90"] {
  margin-bottom: 67.5pt;
}
[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-right-color: #000;
}
input[type="text"] {
  padding-bottom: 5.25pt;
}
select,
input[type="text"] {
  -webkit-border-radius: 0;
}
[class~="rating"] [class~="review-count"] {
  margin-left: 10px;
}
[class~="page-preloader"] [class~="loader"],
.block-section {
  position: relative;
}
[class~="page-preloader"] [class~="loader"] {
  width: 0.833333333in;
}
input[type="text"] {
  padding-right: 0.15625in;
}
[class~="page-preloader"] [class~="loader"] {
  height: 80px;
}
ul[class~="slick-dots"] li {
  -webkit-transition: all 0.3s ease;
}
input[type="text"] {
  padding-top: 5.25pt;
}
body[class~="home"][class~="title-12"]
  [class~="section"]
  [class~="block-title"][class~="title-big"]
  h2 {
  letter-spacing: 0.020833333in;
}
[class~="slick-dotted"][class~="slick-slider"] {
  margin-bottom: 0;
}
input[type="text"] {
  background: transparent;
}
[class~="p-l-r"] {
  padding-left: 5.625pt;
}
[class~="p-l-r"] {
  padding-right: 7.5px;
}
/* [class~="slick-sliders"] [class~="slick-list"] {
  margin-left: -0.15625in;
} */
[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-top-color: #000;
}
[class~="hide"] {
  display: none;
}
select,
input[type="text"] {
  -moz-border-radius: 0;
}
[class~="price"] {
  line-height: 18pt;
}
[class~="price"] {
  font-size: 11.25pt;
}
select,
input[type="text"] {
  -ms-border-radius: 0;
}
[class~="price"] {
  color: #a0a0a0;
}
[class~="slick-sliders"] [class~="slick-list"] {
  margin-bottom: 0;
}
[class~="price"] {
  letter-spacing: 1.125pt;
}
/* [class~="slick-sliders"] [class~="slick-list"] {
  margin-right: -0.15625in;
} */
[class~="pagination"] ul li span {
  padding-top: 0;
}
[class~="price"] {
  font-weight: 400;
}
input[type="text"],
select {
  -o-border-radius: 0;
}
.block-section {
  width: 100%;
}
select,
input[type="text"] {
  box-shadow: none;
}
[class~="column-50"] {
  width: 50%;
}
body.home.title-6 .section .block-title h2 {
  text-transform: uppercase;
}
[class~="slick-sliders"] [class~="slick-list"] {
  margin-top: 0;
}
.column-25 {
  width: 25%;
}
[class~="block-widget-wrap"] {
  padding-left: 0.46875pc;
}
[class~="block-widget-wrap-2"],
[class~="block-widget-wrap"] {
  padding-bottom: 0pt;
}
[class~="slick-sliders"] [class~="slick-list"] {
  -webkit-transform: translateZ(0);
}
[class~="slick-sliders"] [class~="slick-list"] {
  transform: translateZ(0);
}
input[type="text"],
select {
  -moz-appearance: none;
}
[class~="pagination"] ul li span {
  float: none;
}
body.home.title-6 .section .block-title h2 {
  letter-spacing: 0.03125in;
}
[class~="pagination"] ul li span {
  width: 0.416666667in;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a,
ul[class~="slick-dots"] li {
  transition: all 0.3s ease;
}
ul[class~="slick-dots"] li {
  border-radius: 50%;
}
[class~="pagination"] ul li span {
  height: 2.5pc;
}
[class~="quantity"] [class~="minus"] {
  background: #fff;
}
[class~="pagination"] ul li span {
  line-height: normal;
}
[class~="block-widget-wrap"] {
  padding-right: 0.078125in;
}
input[type="text"] {
  -o-appearance: none;
}
[class~="block-widget-wrap-2"],
[class~="block-widget-wrap"] {
  padding-top: 0pc;
}
[class~="pagination"] ul li span {
  text-indent: 0.010416667in;
}
input[type="text"] {
  -webkit-appearance: none;
}
[class~="pagination"] ul li span {
  text-align: center;
}
[class~="pagination"] ul li span {
  vertical-align: middle;
}
[class~="quantity"] [class~="minus"] {
  float: left;
}
ul[class~="slick-dots"] li {
  -webkit-border-radius: 50%;
}
[class~="quantity"] [class~="minus"],
[class~="clearfix"]:after,
[class~="hot"] {
  display: block;
}
[class~="quantity"] [class~="minus"] {
  padding-left: 0;
}
[class~="block-widget-wrap-2"],
[class~="block-widget-wrap"] {
  width: 100%;
}
[class~="quantity"] [class~="minus"],
[class~="hot"] {
  padding-bottom: 0;
}
textarea:focus,
button:focus,
input:focus {
  outline: none;
}
[class~="block-widget-wrap-2"] {
  padding-left: 11.25pt;
}
[class~="hot"],
[class~="pagination"] ul li span[class~="current"] {
  color: #fff;
}
[class~="block-widget-wrap-2"] {
  padding-right: 15px;
}
[class~="page-preloader"] [class~="loader"] div {
  border-image: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
[class~="pagination"] ul li span {
  color: #868686;
}
[class~="quantity"] [class~="minus"] {
  padding-right: 0;
}
[class~="hot"],
[class~="quantity"] [class~="minus"] {
  padding-top: 0;
}
[class~="full-height"] {
  height: 100%;
}
[class~="quantity"] [class~="minus"] {
  margin-left: 0;
}
[class~="hot"] {
  text-align: center !important;
}
body.home .section .block-title,
[class~="hot"] {
  text-align: center;
}
[class~="quantity"] [class~="minus"] {
  margin-bottom: 0;
}
[class~="quantity"] [class~="minus"],
[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"] {
  margin-right: 0;
}
[class~="quantity"] [class~="minus"] {
  margin-top: 0;
}
[class~="hot"] {
  font-size: 0.875pc;
}
[class~="quantity"] [class~="minus"] {
  vertical-align: text-top;
}
[class~="page-preloader"] [class~="loader"] div {
  opacity: 1;
}
[class~="hot"] {
  padding-left: 0.072916667in;
}
[class~="clearfix"]:after {
  clear: both;
}
[class~="hot"] {
  padding-right: 0.072916667in;
}
[class~="btn-default"]:before,
[class~="clearfix"]:after {
  content: "";
}
[class~="quantity"] [class~="minus"] {
  overflow: visible;
}
[class~="btn-default"]:before {
  position: absolute;
}
[class~="quantity"] [class~="minus"] {
  text-decoration: none;
}
.rating .star.star-4:after {
  content: "";
}
[class~="pagination"] ul li span {
  font-size: 0.9375pc;
}
[class~="page-preloader"] [class~="loader"] div {
  border-radius: 50%;
}
ul[class~="slick-dots"] li {
  -moz-border-radius: 50%;
}
select {
  -o-appearance: none;
}
[class~="hot"] {
  text-transform: capitalize;
}
[class~="quantity"] [class~="minus"] {
  cursor: pointer;
}
[class~="hot"] {
  font-weight: 500;
}
select {
  -webkit-appearance: none;
}
textarea,
[class~="quantity"] [class~="minus"],
[class~="pagination"] ul li span {
  border-left-width: 0.75pt;
}
body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.3125in;
}
[class~="hot"] {
  position: relative;
}
body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 300;
}
[class~="quantity"] [class~="minus"] {
  line-height: 9.75pt;
}
[class~="hot"] {
  position: absolute;
}
[class~="pagination"] ul li span,
[class~="quantity"] [class~="minus"],
textarea {
  border-bottom-width: 0.75pt;
}
[class~="hot"] {
  line-height: 18pt;
}
textarea,
[class~="pagination"] ul li span,
[class~="quantity"] [class~="minus"] {
  border-right-width: 0.75pt;
}
[class~="hot"] {
  top: 0.104166667in;
}
ul[class~="slick-dots"] li {
  -ms-border-radius: 50%;
}
[class~="quantity"] [class~="minus"] {
  font-size: 0.1875in;
}
[class~="quantity"] [class~="minus"] {
  color: #868686;
}
[class~="hot"] {
  min-width: 2.9375pc;
}
[class~="btn-default"]:before,
[class~="pagination"] ul li span[class~="current"] {
  background: #000;
}
[class~="hot"] {
  z-index: 1;
}
body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"] {
  margin-bottom: 33.75pt;
}
[class~="onsale"] {
  text-align: center !important;
}
[class~="pagination"] ul li span,
[class~="quantity"] [class~="minus"],
textarea {
  border-top-width: 0.0625pc;
}
[class~="btn-default"]:before {
  top: 0;
}
[class~="page-preloader"] [class~="loader"] div {
  animation: loader-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
[class~="quantity"] [class~="minus"],
textarea,
[class~="pagination"] ul li span {
  border-left-style: solid;
}
body[class~="home"][class~="title-10"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.229166667in;
}
[class~="pagination"] ul li span,
textarea,
[class~="quantity"] [class~="minus"] {
  border-bottom-style: solid;
}
ul[class~="slick-dots"] li {
  -o-border-radius: 50%;
}
[class~="pagination"] ul li a[class~="prev"]:before {
  font-size: 15pt;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(3) {
  animation: k 0.6s infinite;
}
[class~="slick-dots"] li button {
  height: 15pt;
}
[class~="quantity"] [class~="minus"],
textarea {
  border-right-style: solid;
}
[class~="btn-default"]:before {
  left: 0;
}
body i[class~="slick-arrow"][class~="fa-angle-left"] {
  left: -0.15625in;
}
[class~="quantity"] [class~="minus"],
textarea {
  border-top-style: solid;
}
[class~="pagination"] ul li span[class~="current"] {
  border-left-color: #000;
}
.quantity input.qty {
  float: left;
}
[class~="onsale"],
[class~="quantity"] [class~="minus"] {
  text-align: center;
}
[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"] {
  margin-top: 0;
}
[class~="btn-default"]:before {
  width: 100%;
}
[class~="btn-default"]:before {
  height: 0;
}
body[class~="home"][class~="title-10"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  letter-spacing: 0.1875pc;
}
[class~="btn-default"]:before {
  opacity: 0;
}
body[class~="home"][class~="title-10"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: uppercase;
}
[class~="onsale"] {
  color: #fff;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  content: "";
}
[class~="quantity"] [class~="minus"],
textarea {
  border-left-color: #e5e5e5;
}
[class~="btn-default"]:before {
  visibility: hidden;
}
[class~="pagination"] ul li span[class~="current"] {
  border-bottom-color: #000;
}
[class~="quantity"] [class~="minus"],
textarea {
  border-bottom-color: #e5e5e5;
}
body.home .section .block-title {
  margin-bottom: 2.5pc;
}
[class~="onsale"] {
  font-size: 0.875pc;
}
[class~="page-preloader"] [class~="loader"] div:nth-child(2) {
  animation-delay: -1s;
}
.content-category.slick-slider {
  position: static;
}
.slick-sliders .slick-list .slick-slide {
  padding-left: 11.25pt;
}
.sestsellers.slick-slider {
  position: static;
}
.slick-prev {
  left: -35px;
}
.slick-next:before {
  content: "\e649" !important;
  font-family: "themify";
  color: #000;
  font-size: 30px;
}
.slick-prev:before {
  content: "\e64a" !important;
  font-family: "themify";
  color: #000;
  font-size: 30px;
}
[class~="onsale"] {
  padding-left: 0.072916667in;
}
[class~="onsale"],
[class~="quantity"] [class~="plus"],
.slick-sliders .slick-list .slick-slide {
  padding-bottom: 0;
}
[class~="onsale"] {
  padding-right: 0.072916667in;
}
[class~="btn-default"]:before {
  -webkit-transition: all 0.2s ease-in-out;
}
textarea,
[class~="quantity"] [class~="minus"] {
  border-right-color: #e5e5e5;
}
[class~="btn-default"]:before {
  transition: all 0.2s ease-in-out;
}
[class~="btn-default"]:before {
  z-index: -1;
}
.slick-sliders .slick-list .slick-slide,
[class~="onsale"] {
  padding-top: 0;
}
[class~="quantity"] [class~="minus"],
textarea {
  border-top-color: #e5e5e5;
}
[class~="quantity"] [class~="minus"] {
  border-image: none;
}
[class~="onsale"] {
  text-transform: capitalize;
}
body[class~="home"][class~="title-11"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 28.5pt;
}
[class~="pagination"] ul li span[class~="current"] {
  border-right-color: #000;
}
body[class~="home"][class~="title-12"]
  [class~="section"]
  [class~="block-title"][class~="title-big"]
  h2 {
  margin-bottom: 0.625in;
}
.slick-sliders .slick-list .slick-slide {
  padding-right: 11.25pt;
}
[class~="btn-primary"]:before {
  position: absolute;
}
[class~="btn-primary"]:before {
  content: "";
}
[class~="onsale"] {
  font-weight: 500;
}
.quantity input.qty,
[class~="quantity"] [class~="minus"] {
  width: 33.75pt;
}
[class~="quantity"] [class~="minus"],
.quantity input.qty {
  height: 2.375pc;
}
.onsale,
.hot,
[class~="quantity"] [class~="plus"] {
  background: #fff;
}
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"] {
  margin-bottom: 45px;
}
[class~="slick-dots"] li button {
  width: 20px;
}
[class~="quantity"] [class~="plus"] {
  float: left;
}
[class~="product-table"] [class~="tr-price"] del [class~="amount"] {
  font-size: 0.166666667in;
}
[class~="btn-primary"]:before {
  top: 0;
}
[class~="onsale"],
[class~="quantity"] [class~="plus"] {
  display: block;
}
[class~="btn-primary"]:before {
  background: #000;
}
[class~="onsale"] {
  position: relative;
}
[class~="btn-underline"]:before,
[class~="onsale"] {
  position: absolute;
}
[class~="onsale"] {
  line-height: 18pt;
}
[class~="quantity"] [class~="plus"] {
  padding-left: 0;
}
[class~="btn-primary"]:before {
  left: 0;
}
[class~="onsale"] {
  top: 0.104166667in;
}
[class~="onsale"] {
  min-width: 2.9375pc;
}
[class~="onsale"] {
  z-index: 1;
}
.hot {
  right: 7.5pt;
}
[class~="btn-underline"]:before,
[class~="btn-primary"]:before {
  width: 100%;
}
[class~="pagination"] ul li span {
  border-right-style: solid;
}
[class~="pagination"] ul li a[class~="prev"]:before {
  font-family: eleganticons;
}
.hot {
  color: red;
}
body[class~="home"][class~="title-11"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 300;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(4) {
  left: 60px;
}
.onsale {
  left: 0.104166667in;
}
[class~="btn-primary"]:before {
  height: 0;
}
[class~="btn-primary"]:before {
  opacity: 0;
}
.onsale {
  color: #000;
}
[class~="quantity"] [class~="plus"] {
  padding-right: 0;
}
[class~="btn-primary"]:before {
  visibility: hidden;
}
[class~="quantity"] [class~="plus"] {
  padding-top: 0;
}
body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"] {
  margin-bottom: 40px;
}
[class~="page-preloader"] {
  position: fixed;
}
[class~="page-preloader"] {
  top: 0;
}
.product-table .tr-price .amount,
[class~="pagination"] ul li a[class~="next"]:before {
  font-size: 15pt;
}
[class~="page-preloader"] {
  left: 0;
}
textarea {
  border-image: none;
}
[class~="page-preloader"] {
  right: 0;
}
[class~="pagination"] ul li span {
  border-top-style: solid;
}
[class~="pagination"] ul li span {
  border-left-color: #e5e5e5;
}
body[class~="home"][class~="title-13"]
  [class~="section"]
  [class~="block-title"] {
  margin-bottom: 45px;
}
[class~="quantity"] [class~="plus"] {
  margin-left: 0;
}
textarea {
  padding-left: 0.15625in;
}
[class~="btn-primary"]:before {
  -webkit-transition: all 0.2s ease-in-out;
}
[class~="btn-primary"]:before {
  transition: all 0.2s ease-in-out;
}
[class~="quantity"] [class~="plus"] {
  margin-bottom: 0;
}
[class~="quantity"] [class~="plus"] {
  margin-right: 0;
}
[class~="quantity"] [class~="plus"] {
  margin-top: 0;
}
[class~="btn-primary"]:before {
  z-index: -1;
}
[class~="quantity"] [class~="plus"] {
  vertical-align: text-top;
}
[class~="page-preloader"],
[class~="btn-underline"]:before {
  bottom: 0;
}
textarea {
  padding-bottom: 5.25pt;
}
[class~="btn-underline"]:before {
  content: "";
}
textarea {
  padding-right: 0.15625in;
}
[class~="pagination"] ul li span {
  border-bottom-color: #e5e5e5;
}
textarea {
  padding-top: 5.25pt;
}
[class~="page-preloader"] {
  background-color: #fff;
}
[class~="btn-underline"]:before {
  height: 1px;
}
[class~="quantity"] [class~="plus"] {
  overflow: visible;
}
[class~="page-preloader"] {
  z-index: 9999999;
}
[class~="quantity"] [class~="plus"] {
  text-decoration: none;
}
textarea {
  background: transparent;
}
[class~="page-preloader"] {
  align-content: center;
}
.quantity input.qty {
  text-align: center;
}
[class~="page-preloader"] {
  align-items: center;
}
[class~="page-preloader"] {
  justify-content: center;
}
[class~="slick-dots"] li button,
[class~="quantity"] [class~="plus"] {
  cursor: pointer;
}
[class~="pagination"] ul li span {
  border-right-color: #e5e5e5;
}
[class~="page-preloader"] {
  display: -webkit-box;
}
[class~="product-table"] [class~="tr-price"] del [class~="amount"],
[class~="quantity"] [class~="plus"] {
  color: #868686;
}
[class~="page-preloader"] {
  display: -ms-flexbox;
}
[class~="page-preloader"] {
  display: flex;
}
[class~="page-preloader"],
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  height: 100%;
}
[class~="pagination"] ul li span {
  border-top-color: #e5e5e5;
}
textarea {
  border-radius: 0;
}
.slick-slider,
[class~="star-rating"] {
  overflow: hidden;
}
textarea {
  -webkit-border-radius: 0;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after,
[class~="slick-wrap"],
[class~="star-rating"],
[class~="scroll-image"] {
  position: relative;
}
textarea {
  -moz-border-radius: 0;
}
textarea {
  -ms-border-radius: 0;
}
[class~="pagination"] ul li span {
  border-image: none;
}
[class~="star-rating"] span:before {
  content: "";
}
[class~="slick-track"],
[class~="slick-slide"] {
  -webkit-transform: translateZ(0);
}
[class~="pagination"] ul li a[class~="next"]:before {
  font-family: eleganticons;
}
[class~="pagination"] ul li span {
  -webkit-transition: none;
}
[class~="btn-underline"]:before,
[class~="loading"]:before {
  left: 0;
}
[class~="btn-underline"]:before {
  -webkit-transition: all 0.3s ease;
}
textarea {
  -o-border-radius: 0;
}
.quantity input.qty {
  padding-left: 0;
}
[class~="btn-underline"]:before {
  transition: all 0.3s ease;
}
[class~="slick-track"],
[class~="slick-slide"] {
  transform: translateZ(0);
}
[class~="quantity"] [class~="plus"] {
  line-height: 9.75pt;
}
[class~="quantity"] [class~="plus"] {
  font-size: 0.1875in;
}
[class~="star-rating"] span:before,
[class~="loading"]:before {
  top: 0;
}
[class~="btn-underline"]:before {
  background: #000;
}
[class~="quantity"] [class~="plus"] {
  border-left-width: 0.010416667in;
}
[class~="loading"]:before,
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  width: 100%;
}
[class~="dropdown-toggle"]:after {
  display: none;
}
[class~="quantity"] [class~="plus"] {
  border-bottom-width: 0.010416667in;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  margin-top: 0.104166667in;
}
[class~="star-rating"] span:before {
  position: absolute;
}
[class~="quantity"] [class~="plus"] {
  border-right-width: 0.010416667in;
}
[class~="quantity"] [class~="plus"] {
  border-top-width: 0.010416667in;
}
textarea {
  box-shadow: none;
}
[class~="quantity"] [class~="plus"] {
  border-left-style: solid;
}
textarea {
  -moz-appearance: none;
}
[class~="quantity"] [class~="plus"] {
  border-bottom-style: solid;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  font-family: FontAwesome;
}
[class~="price"] ins {
  text-decoration: none;
}
[class~="pagination"] ul li span {
  transition: none;
}
[class~="pagination"] ul li span {
  text-transform: uppercase;
}
[class~="quantity"] [class~="plus"] {
  border-right-style: solid;
}
[class~="price"] ins {
  color: red;
}
textarea {
  -o-appearance: none;
}
.woosc_table .tr-price .amount,
.product-table .tr-price .amount {
  color: #000;
}
[class~="loading"]:before {
  content: "";
}
body[class~="home"][class~="title-14"]
  [class~="section"]
  [class~="block-title"]
  h2,
body[class~="home"][class~="title-13"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 1.75pc;
}
[class~="quantity"] [class~="plus"] {
  border-top-style: solid;
}
[class~="loading"]:before {
  position: fixed;
}
[class~="quantity"] [class~="plus"] {
  border-left-color: #e5e5e5;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  top: -0.0625pc;
}
[class~="quantity"] [class~="plus"] {
  border-bottom-color: #e5e5e5;
}
[class~="quantity"] [class~="plus"] {
  border-right-color: #e5e5e5;
}
.quantity input.qty {
  padding-bottom: 0;
}
[class~="pagination"] ul li a[class~="prev"]:before {
  content: "4";
}
[class~="star-rating"] {
  width: 0.885416667in;
}
[class~="star-rating"] {
  height: 1.2em;
}
[class~="star-rating"] {
  line-height: 1.2em;
}
[class~="quantity"] [class~="plus"] {
  border-top-color: #e5e5e5;
}
[class~="quantity"] [class~="plus"] {
  border-image: none;
}
[class~="star-rating"],
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  display: inline-block;
}
.quantity input.qty {
  padding-right: 0;
}
textarea {
  -webkit-appearance: none;
}
[class~="star-rating"] {
  font-family: icomoon;
}
[class~="star-rating"] span:before {
  left: 0;
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
[class~="star-rating"] {
  font-size: 0.75pc;
}
[class~="quantity"] [class~="plus"] {
  text-align: center;
}
[class~="loading"]:before {
  height: 100%;
}
[class~="loading"]:before {
  z-index: 9999;
}
[class~="pagination"] ul li span[class~="current"] {
  border-top-color: #000;
}
[class~="quantity"] [class~="plus"] {
  width: 33.75pt;
}
[class~="loading"]:before {
  background-color: rgba(0, 0, 0, 0.9);
}
/* .quantity {
  width: 200px;
} */
.quantity {
  position: relative;
}
body[class~="home"][class~="title-14"]
  [class~="section"]
  [class~="block-title"]
  h2,
body[class~="home"][class~="title-13"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 300;
}
[class~="product-table"] [class~="tr-price"] ins [class~="amount"] {
  color: #ff4545;
}
[class~="pagination"] ul li a,
.quantity input.qty {
  padding-top: 0;
}
.quantity input.qty {
  -moz-appearance: textfield;
}
.woosc_table .tr-price .amount {
  font-size: 1.25pc;
}
.quantity {
  margin-left: auto;
}
.quantity {
  margin-bottom: 0;
}
[class~="quantity"] [class~="plus"] {
  height: 2.375pc;
}
[class~="products-loadmore"] [class~="loadmore"] {
  min-width: 105pt;
}
[class~="products-loadmore"] [class~="loadmore"] {
  letter-spacing: normal;
}
.quantity {
  margin-right: auto;
}
.quantity {
  margin-top: 0;
}
.quantity {
  overflow: hidden;
}
input::-webkit-outer-spin-button {
  margin-left: 0;
}
[class~="slick-slide"] img {
  -webkit-transform: translateZ(0);
}
[class~="pagination"] ul li a[class~="prev"],
[class~="product-table"] [class~="tr-price"] del {
  font-size: 0;
}
[class~="slick-slide"] img {
  transform: translateZ(0);
}
[class~="products-loadmore"] [class~="loadmore"] {
  height: 2.5pc;
}
input::-webkit-outer-spin-button {
  margin-bottom: 0;
}
input::-webkit-outer-spin-button {
  margin-right: 0;
}
[class~="slick-dots"] li,
[class~="back-top"]:hover {
  cursor: pointer;
}
.cart-product-added,
[class~="back-top"]:hover {
  background: #000;
}
ul[class~="slick-dots"] {
  bottom: 1.875pc;
}
ul[class~="slick-dots"] {
  list-style-type: none;
}
[class~="pagination"] ul li a {
  display: table-cell;
}
ul[class~="slick-dots"] {
  position: unset;
}
.quantity {
  zoom: 1;
}
[class~="pagination"] ul li a[class~="next"]:before {
  content: "5";
}
input::-webkit-outer-spin-button {
  margin-top: 0;
}
[class~="back-top"]:hover,
.cart-product-added {
  color: #fff;
}
[class~="color-white"]
  ul[class~="slick-dots"]
  li[class~="slick-active"]
  button {
  background: #fff;
}
.cart-product-added {
  line-height: 1.5;
}
[class~="star-rating"] span:before {
  color: #fcad02;
}
.cart-product-added {
  box-shadow: unset;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  position: absolute;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
[class~="back-top"]:hover {
  border-left-color: #000;
}
[class~="pagination"] ul li a {
  padding-left: 0;
}
[class~="slick-dots"] li {
  height: 15pt;
}
[class~="back-top"]:hover {
  border-bottom-color: #000;
}
[class~="back-top"]:hover {
  border-right-color: #000;
}
[class~="back-top"]:hover {
  border-top-color: #000;
}
[class~="pagination"] ul li a {
  padding-bottom: 0;
}
[class~="pagination"] ul li a {
  padding-right: 0;
}
[class~="star-rating"] span:before {
  letter-spacing: 0.375pc;
}
input::-webkit-inner-spin-button {
  margin-left: 0;
}
input::-webkit-inner-spin-button {
  margin-bottom: 0;
}
[class~="back-top"][class~="button-show"],
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  opacity: 1;
}
.cart-product-added {
  top: 0.208333333in;
}
[class~="pagination"] ul li a {
  float: none;
}
.cart-product-added {
  left: auto;
}
.cart-product-added {
  right: 20px;
}
[class~="pagination"] ul li a,
.back-top {
  width: 0.416666667in;
}
[class~="slick-dots"] li {
  width: 20px;
}
.cart-product-added {
  padding-left: 1.5625pc;
}
.cart-product-added {
  padding-bottom: 0.15625in;
}
[class~="products-loadmore"] [class~="loadmore"][class~="loading"]:before {
  content: none;
}
.cart-product-added {
  padding-right: 1.5625pc;
}
input::-webkit-inner-spin-button {
  margin-right: 0;
}
[class~="back-top"][class~="button-show"] {
  -webkit-transform: translateY(0);
}
[class~="back-top"][class~="button-show"] {
  transform: translateY(0);
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  top: calc(50% - 5px);
}
[class~="sidebar"] [class~="block"]:last-child {
  margin-bottom: 0;
}
.cart-product-added {
  padding-top: 0.15625in;
}
.cart-product-added {
  font-weight: 400;
}
.back-top,
[class~="pagination"] ul li a {
  height: 2.5pc;
}
[class~="pagination"] ul li a {
  line-height: normal;
}
input::-webkit-inner-spin-button {
  margin-top: 0;
}
.back-top,
.cart-product-added {
  position: fixed;
}
[class~="back-top"][class~="button-show"] {
  visibility: visible;
}
.cart-product-added,
.back-top {
  z-index: 999999;
}
[class~="cart-product-added"] > div:before,
[class~="cart-product-added"] > div,
label,
[class~="products-loadmore"] [class~="loadmore"] span {
  display: inline-block;
}
[class~="pagination"] ul li a,
.back-top,
[class~="products-loadmore"] {
  text-align: center;
}
[class~="sidebar"] [class~="block"] {
  margin-bottom: 0.520833333in;
}
[class~="star-rating"]:before {
  content: "";
}
[class~="pagination"] ul li a {
  text-indent: 0.010416667in;
}
[class~="star-rating"]:before {
  color: #e1e1e1;
}
[class~="star-rating"] span,
[class~="star-rating"]:before {
  float: left;
}
[class~="pagination"] {
  margin-top: 15pt;
}
[class~="pagination"] ul li a {
  vertical-align: middle;
}
[class~="star-rating"]:before,
[class~="star-rating"] span {
  top: 0;
}
[class~="star-rating"] span,
[class~="star-rating"]:before {
  left: 0;
}
.back-top {
  line-height: 0.510416667in;
}
.back-top {
  bottom: 0.3125in;
}
[class~="cart-product-added"] > div:before {
  content: "";
}
[class~="star-rating"] span,
[class~="star-rating"]:before {
  position: absolute;
}
[class~="product-table"] [class~="tr-price"] ins {
  text-decoration: none;
}
[class~="products-loadmore"] [class~="loadmore"] span {
  animation: none;
}
.back-top {
  right: 0.3125in;
}
[class~="products-loadmore"] [class~="loadmore"] span {
  font-size: 0.875pc;
}
.back-top {
  color: #000;
}
[class~="products-loadmore"] [class~="loadmore"] span {
  text-transform: inherit;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  width: 0.625pc;
}
[class~="pagination"] ul li a[class~="prev"],
[class~="pagination"] ul li a {
  color: #868686;
}
[class~="products-loadmore"] [class~="loadmore"][class~="loading"] span,
.back-top {
  opacity: 0;
}
.back-top {
  border-left-width: 1.5pt;
}
.back-top {
  border-bottom-width: 1.5pt;
}
[class~="cart-product-added"] > div:before {
  font-family: wpbingofont;
}
label {
  margin-bottom: 0.5rem;
}
.back-top {
  border-right-width: 1.5pt;
}
.back-top {
  border-top-width: 1.5pt;
}
[class~="star-rating"]:before {
  letter-spacing: 6px;
}
.back-top {
  border-left-style: solid;
}
[class~="products-loadmore"] [class~="loadmore"] span {
  position: relative;
}
.back-top {
  border-bottom-style: solid;
}
[class~="star-rating"] span {
  overflow: hidden;
}
.back-top {
  border-right-style: solid;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  height: 7.5pt;
}
.back-top {
  border-top-style: solid;
}
.back-top {
  border-left-color: #000;
}
[class~="products-loadmore"] [class~="loadmore"] span {
  top: -0.125pc;
}
.back-top {
  border-bottom-color: #000;
}
[class~="pagination"] ul,
table {
  width: 100%;
}
body[class~="home"][class~="title-15"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 18pt;
}
table {
  max-width: 100%;
}
[class~="pagination"] ul li a {
  font-size: 0.9375pc;
}
.back-top {
  border-right-color: #000;
}
.back-top {
  border-top-color: #000;
}
[class~="cart-product-added"] > div:before {
  margin-right: 7.5pt;
}
.back-top {
  border-image: none;
}
.back-top,
[class~="products-loadmore"] [class~="loadmore"][class~="loading"] span {
  visibility: hidden;
}
[class~="products-loadmore"] [class~="loadmore"] i {
  font-size: 16px;
}
table {
  margin-bottom: 1rem;
}
[class~="star-rating"] span {
  padding-top: 1.5em;
}
.back-top {
  background: #fff;
}
.products-loadmore .loadmore.loading i {
  animation: 2s linear 0s normal none infinite running o;
}
[class~="pagination"] ul {
  padding-left: 0;
}
[class~="pagination"] ul {
  list-style: none;
}
[class~="pagination"] ul {
  text-align: center;
}
[class~="back-top"] i {
  font-size: 0.270833333in;
}
[class~="products-loadmore"] [class~="loadmore"] i {
  margin-left: 5px;
}
.back-top {
  filter: alpha(opacity=0);
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  color: #000;
}
[class~="back-top"] i {
  display: block;
}
.back-top {
  -webkit-transform: translateY(20px);
}
[class~="back-top"] i {
  line-height: 26.25pt;
}
[class~="pagination"] ul li a {
  border-left-width: 0.0625pc;
}
[class~="pagination"] ul li a {
  border-bottom-width: 0.0625pc;
}
.pagination ul li a.next {
  font-size: 0;
}
[class~="pagination"] ul li a {
  border-right-width: 0.0625pc;
}
.products-loadmore .loadmore.loading i {
  opacity: 1;
}
.products-loadmore .loadmore.loading i {
  visibility: visible;
}
[class~="pagination"] ul li a {
  border-top-width: 0.0625pc;
}
.back-top {
  transform: translateY(20px);
}
.back-top {
  -webkit-transition: all 0.3s ease;
}
body[class~="home"][class~="title-15"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  letter-spacing: 0.03125in;
}
.back-top {
  transition: all 0.3s ease;
}
[class~="left-sidebar"] {
  padding-right: 0.3125in;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  border-radius: 50%;
}
table {
  background-color: transparent;
}
table {
  border-collapse: collapse;
}
button {
  border-left-width: 0;
}
button {
  border-bottom-width: 0;
}
[class~="pagination"] ul li a {
  border-left-style: solid;
}
[class~="pagination"] ul li {
  display: inline-block;
}
button {
  border-right-width: 0;
}
button {
  border-top-width: 0;
}
[class~="pagination"] ul li a {
  border-bottom-style: solid;
}
[class~="right-sidebar"] {
  padding-left: 0.3125in;
}
[class~="pagination"] ul li {
  margin-left: 0.1875pc;
}
[class~="pagination"] ul li a {
  border-right-style: solid;
}
[class~="pagination"] ul li {
  margin-bottom: 0;
}
[class~="pagination"] ul li a {
  border-top-style: solid;
}
[class~="pagination"] ul li a {
  border-left-color: #e5e5e5;
}
[class~="pagination"] ul li a {
  border-bottom-color: #e5e5e5;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2,
[class~="pagination"] ul li a,
body[class~="home"][class~="title-15"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: uppercase;
}
button {
  border-left-style: none;
}
[class~="pagination"] ul li a {
  border-right-color: #e5e5e5;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  font-weight: 500;
}
.pagination ul li a.next:hover,
[class~="pagination"] ul li a[class~="prev"]:hover {
  color: #fff;
}
[class~="pagination"] ul li a {
  border-top-color: #e5e5e5;
}
[class~="pagination"] ul li a {
  border-image: none;
}
[class~="pagination"] ul li a {
  -webkit-transition: none;
}
button {
  border-bottom-style: none;
}
button {
  border-right-style: none;
}
.pagination ul li a.next {
  color: #868686;
}
[class~="pagination"] ul li a {
  transition: none;
}
[class~="pagination"] ul li {
  margin-right: 0.1875pc;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2,
[class~="pagination"] ul li {
  margin-top: 0;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  background: #000;
}
button {
  border-top-style: none;
}
button {
  border-left-color: currentColor;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
button {
  border-bottom-color: currentColor;
}
.products-loadmore .loadmore:hover .lds-ellipsis div {
  background: #fff;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  font-size: 1.125pc;
}
button {
  border-right-color: currentColor;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  line-height: 0.208333333in;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(4) {
  animation: j 0.6s infinite;
}
button {
  border-top-color: currentColor;
}
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"] {
  visibility: visible;
}
button {
  border-image: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 62.5pc white inset !important;
}
del {
  font-size: 0.145833333in;
}
del {
  color: #ababab;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-left: 0;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-bottom: 15pt;
}
[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-right: 0;
}
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a:hover {
  background: rgba(0, 0, 0, 0.75);
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  font-size: 7.5pt;
}
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  margin-left: 7.5pt;
}
body i[class~="slick-arrow"][class~="fa-angle-right"] {
  right: -11.25pt;
}

/*===================================================================
2. Animate
====================================================================*/
@keyframes e {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes i {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes j {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes k {
  0% {
    transform: translate(0);
  }
  to {
    transform: translate(24px);
  }
}
@keyframes l {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes n {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    -webkit-transform: scale(2);
    opacity: 0;
  }
}
@keyframes o {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes remove-spinner {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes remove-spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes animation-horizontal {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes loader-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*========================================================================
3. Header
=========================================================================*/
.currency,
.language {
  padding-left: 10px;
}
.currency select,
.language select {
  -webkit-appearance: menulist;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}
.currency select option,
.language select option {
  background-color: #ddd8d4;
}

[class~="site-header"][class~="absolute"] {
  position: absolute;
}
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a {
  color: #fff;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-width: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-width: 0;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"][class~="icon"]
  > a {
  padding-right: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:before {
  -webkit-transform: rotate(135deg);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:before {
  transform: rotate(135deg);
}
[class~="site-header"] [class~="site-logo"] img {
  max-width: 6.25pc;
}
[class~="site-navigation"] ul > li[class~="level-0"]:first-child {
  padding-left: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:after {
  -webkit-transform: rotate(-45deg);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:after {
  transform: rotate(-45deg);
}
[class~="site-header"][class~="absolute"] {
  width: 100%;
}
#header-topbar a:hover,
#header-topbar a,
.site-header .header-page-link .mini-cart .cart-icon .icons-cart:hover,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a {
  color: #000;
}
.site-header {
  margin-bottom: 0;
}
[class~="site-header"][class~="absolute"] {
  border-bottom-width: 0.75pt;
}
[class~="form-login-register"]
  [class~="box-content"]
  > div[class~="active"]
  [class~="login"] {
  display: block !important;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  position: static;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input,
.form-login-register .box-form-login .content .email input,
.form-login-register .box-form-login .content .username input {
  padding-left: 0.208333333in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input,
.form-login-register .box-form-login .content .email input {
  padding-bottom: 0;
}
.site-header,
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  position: relative;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"]:hover,
.site-header .header-search-form .search-from #search-submit:hover,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-login"]
  input[type="submit"]:hover {
  background: rgba(0, 0, 0, 0.75);
}
[class~="site-header"][class~="absolute"] {
  border-bottom-style: solid;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  content: "";
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  display: table;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 15px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input,
.form-login-register .box-form-login .content .email input {
  padding-right: 0.208333333in;
}
[class~="site-header"][class~="absolute"] {
  border-bottom-color: #e5e5e5;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-width: 0;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="section-container"]
  > [class~="row"],
.site-header .header-wrapper .section-container > .row {
  align-items: center;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-width: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-style: none;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-style: none;
}
[class~="site-header"][class~="absolute"] {
  border-image: none;
}
.form-login-register .box-form-login .content .email input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input {
  padding-top: 0;
}
.site-navigation ul > li.level-0.mega-menu.mega-menu-fullwidth > .sub-menu {
  /* width: 562pt; */
  width: 562pt;
}
.site-navigation ul > li.level-0.mega-menu.level-menu-fullwidth > .sub-menu {
  width: auto;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-style: none;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="header-desktop"] {
  padding-left: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-style: none;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="header-desktop"] {
  padding-bottom: 14.5pt;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-color: currentColor;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  opacity: 1;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="header-desktop"] {
  padding-right: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  float: left;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="header-desktop"] {
  padding-top: 14.5pt;
}
.header-desktop.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  background: #fff;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover[class~="menu-item-has-children"]
  > a:after {
  -webkit-transform: rotateX(-180deg);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a,
[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a,
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  color: #fff;
}
[class~="site-header"][class~="bottom-border"] {
  border-bottom-width: 0.75pt;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-color: currentColor;
}
[class~="site-header"][class~="bottom-border"] {
  border-bottom-style: solid;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  content: "";
}
#header-topbar #topbar_menu {
  list-style: none;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  padding-left: 0.1875in;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-color: currentColor;
}
[class~="site-header"][class~="bottom-border"] {
  border-bottom-color: #e5e5e5;
}
[class~="site-header"][class~="bottom-border"] {
  border-image: none;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="align-center"]
  [class~="sub-menu"] {
  -webkit-transform: translateX(-50%);
}
#header-topbar .address i {
  margin-right: 0.104166667in;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"] {
  display: inline-block;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="align-center"]
  [class~="sub-menu"] {
  transform: translateX(-50%);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"]:hover {
  top: -0.75pt;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: capitalize;
}
.form-login-register .box-form-login .content .username input,
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"],
[class~="site-navigation"] ul > li[class~="level-0"] {
  padding-bottom: 0;
}
[class~="site-header"][class~="header-v3"]
  [class~="header-desktop"]
  [class~="site-navigation"] {
  margin-left: 0.625in;
}
[class~="site-header"][class~="bg-black"] {
  background: #000;
}
#header-topbar,
.mini-cart .cart-popup .cart-list li.empty .go-shop {
  font-size: 10.5pt;
}
.form-login-register .box-form-login .content .username input {
  padding-right: 0.208333333in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  font-size: 0;
}
.site-header .header-right,
[class~="site-header"] [class~="header-wrapper"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  position: relative;
}
.site-navigation ul > li.level-0:last-child {
  padding-right: 0;
}
[class~="site-header"] [class~="header-mobile"] {
  padding-left: 0.078125in;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-color: currentColor;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  visibility: visible;
}
.form-login-register .box-form-login .content .username input,
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  padding-top: 0;
}
[class~="site-header"] [class~="header-mobile"] {
  padding-bottom: 1.25pc;
}
[class~="site-header"] [class~="site-logo"] img,
[class~="site-header"] [class~="site-logo"] img {
  width: 100%;
}
[class~="site-header"] [class~="site-logo"] img {
  max-width: 100px;
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  width: 5.208333333in;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-image: none;
}
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  opacity: 1 !important;
}
.form-login-register .box-form-login .rememberme-lost .rememberme input {
  display: none;
}
[class~="site-header"] [class~="header-mobile"] {
  padding-right: 0.078125in;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  font-family: hey;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  font-size: 20px;
}
.site-navigation ul > li.level-0 .sub-menu,
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  display: block;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop,
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: uppercase;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before,
#header-topbar #topbar-menu li:before,
[class~="menu-full"] [class~="close-menu-full"]:after,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  position: absolute;
}
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  color: #000;
}
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  visibility: visible !important;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  width: 0.166666667in;
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  background: transparent;
}
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  -webkit-transform: translate(0);
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  padding-left: 0;
}
[class~="site-header"][class~="header-v2"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0.260416667in;
}
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  transform: translate(0);
}
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  padding-right: 0;
}
[class~="site-header"] [class~="header-mobile"] {
  padding-top: 1.25pc;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]:hover
  i {
  top: -0.1875pc;
}
[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  text-align: center;
}
[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-left: 0;
}
.site-navigation ul > li.level-0 .sub-menu {
  min-width: 14.0625pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  height: 16px;
}
[class~="menu-full"] [class~="close-menu-full"]:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  content: "";
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-family: Barlow Semi Condensed;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"]
  input:checked
  + label:after {
  -webkit-transform: scale(1);
}
[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-bottom: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-right: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-top: 0;
}
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  box-shadow: 0.03125in 3px 45px 0 rgba(0, 0, 0, 0.05);
}
[class~="site-header"][class~="header-v4"] [class~="header-center"] {
  padding-top: 10px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  display: inline-block;
}
.menu-full .menu-lines .line-general.line-1 {
  top: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}
.menu-full .menu-lines .line-general.line-1 {
  width: 20px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -webkit-border-radius: 50%;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop {
  line-height: 1.75pc;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-right"],
.site-header .header-right {
  display: flex;
}
[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-2"] {
  top: 5px;
}
.site-header .header-right {
  color: #8d8d8d;
}
[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-2"] {
  width: 0.104166667in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -moz-border-radius: 50%;
}
#header-topbar[class~="topbar-v1"] {
  border-bottom-width: 0.75pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -ms-border-radius: 50%;
}
.site-header .header-right,
[class~="site-header"] [class~="header-mobile"] [class~="header-right"] {
  align-items: center;
}
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  background: #fff;
}
.site-header .header-right,
[class~="site-header"] [class~="header-mobile"] [class~="header-right"] {
  justify-content: flex-end;
}
[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-3"] {
  top: 7.5pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -o-border-radius: 50%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
#header-topbar[class~="topbar-v1"] {
  border-bottom-style: solid;
}
.site-navigation ul > li.level-0 > a > span:before {
  bottom: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-width: 0.020833333in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-width: 0.020833333in;
}
#header-topbar .address i {
  font-size: 0.15625in;
}
.site-navigation ul > li.level-0 .sub-menu {
  position: absolute;
}
[class~="site-header"][class~="header-v3"] [class~="header-desktop"],
[class~="site-header"][class~="header-v2"] [class~="header-desktop"],
[class~="site-header"][class~="header-v1"] [class~="header-desktop"],
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  padding-left: 0;
}
[class~="site-header"] [class~="header-left"] > * {
  float: left;
}
.site-navigation ul > li.level-0 .sub-menu {
  top: 100%;
}
.site-navigation ul > li.level-0 .sub-menu {
  margin-top: 10px;
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  padding-left: 30pt;
}
[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover
  > a,
.mini-cart .cart-popup .mini-cart-item a.remove:hover,
.color-white .site-navigation ul > li.level-0.current_page_item > a,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  color: #fff;
}
[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-3"] {
  width: 20px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-width: 0.020833333in;
}
[class~="site-header"] [class~="text-center"],
.site-header .header-menu {
  position: unset;
}
[class~="site-header"][class~="header-v1"] [class~="header-desktop"],
[class~="site-header"][class~="header-v3"] [class~="header-desktop"],
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  padding-bottom: 0.3125in;
}
.site-header,
.site-navigation ul > li.level-0 .sub-menu {
  z-index: 999999;
}
[class~="site-header"][class~="header-v2"] [class~="header-desktop"],
[class~="site-header"][class~="header-v1"] [class~="header-desktop"],
[class~="site-header"][class~="header-v4"] [class~="header-top"],
[class~="site-header"][class~="header-v3"] [class~="header-desktop"] {
  padding-right: 0;
}
.mini-cart .cart-popup .cart-list li.empty .go-shop {
  margin-top: 5px;
}
.site-header .header-page-link .login-header .icon-user,
.mini-cart .cart-popup .cart-list li.empty .go-shop,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"] {
  position: relative;
}
[class~="site-header"][class~="header-v3"] [class~="header-desktop"],
[class~="site-header"][class~="header-v1"] [class~="header-desktop"] {
  padding-top: 0.3125in;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-width: medium;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-width: 0.020833333in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-style: solid;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  bottom: -3.125pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-style: solid;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-style: solid;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  i,
.site-header .header-page-link .login-header .icon-user {
  font-size: 0.208333333in;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  width: 100%;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  > div {
  flex: 1;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a,
.site-navigation ul > li.level-0:hover > a {
  color: #000;
}
.site-header .header-page-link .login-header .icon-user {
  -webkit-transition: all 0.3s ease;
}
.mini-cart .cart-popup .mini-cart-item a.remove:hover,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-color: #000;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-width: medium;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-width: medium;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover[class~="menu-item-has-children"]
  > a:after {
  transform: rotateX(-180deg);
}
[class~="site-header"][class~="header-v2"] [class~="header-desktop"] {
  padding-bottom: 0.208333333in;
}
.site-header {
  background: transparent;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  height: 1.5pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
.mini-cart .cart-popup .mini-cart-item a.remove:hover {
  border-bottom-color: #000;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  padding-right: 0.1875in;
}
.site-header .header-page-link .login-header .icon-user {
  transition: all 0.3s ease;
}
[class~="site-header"][class~="header-v2"] [class~="header-desktop"] {
  padding-top: 0.208333333in;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-width: medium;
}
.mini-cart .cart-popup .mini-cart-item a.remove:hover {
  background: #000;
}
.site-header .header-page-link .login-header .icon-user {
  top: 3px;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-style: none;
}
.mini-cart .cart-popup .mini-cart-item a.remove:hover,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-color: #000;
}
[class~="site-header"][class~="header-v4"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  > div {
  text-align: center;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover {
  background: #e5e5e5;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  padding-top: 0;
}
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  padding-bottom: 35px;
}
[class~="site-header"][class~="absolute"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 22.5pt;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  content: "";
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-style: none;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"] {
  margin-bottom: 0.15625in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
.mini-cart .cart-popup .mini-cart-item a.remove:hover {
  border-top-color: #000;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-style: none;
}
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  padding-top: 35px;
}
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  border-bottom-width: 0.75pt;
}
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  border-bottom-style: solid;
}
[class~="menu-full"] [class~="close-menu-full"]:hover:before {
  -webkit-transform: rotate(135deg);
}
[class~="site-navigation"],
#header-topbar .address {
  display: inline-block;
}
[class~="menu-full"] [class~="close-menu-full"]:hover:before {
  transform: rotate(135deg);
}
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  border-bottom-color: #202020;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="site-header"][class~="header-v4"] [class~="header-top"] {
  border-image: none;
}
#header-topbar[class~="topbar-v1"] {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  width: 100%;
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  padding-right: 30pt;
}
.site-navigation ul > li.level-0 .sub-menu {
  background: #fff;
}
#header-topbar .address i,
.site-navigation ul > li.level-0.menu-item-has-children > a,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"] {
  position: relative;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  line-height: 0.520833333in;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-style: none;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  height: 0.520833333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit
  > [class~="icon-search"] {
  -webkit-transform: rotateY(180deg);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  margin-right: 0.3125pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"] {
  margin-right: 10px;
}
.menu-full .close-menu-full:hover:after {
  -webkit-transform: rotate(-45deg);
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  line-height: 1.5pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"] {
  color: #000;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:hover:before,
.form-login-register .box-form-login .button-next-reregister:hover,
.site-header .header-wrapper.bg-black,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"]:hover {
  background: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"],
.form-login-register .box-form-login .rememberme-lost .lost_password {
  font-weight: 500;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"] {
  cursor: pointer;
}
[class~="site-navigation"] ul,
.site-header.header-v4 .header-wrapper {
  padding-left: 0;
}
.site-navigation ul > li.level-0 > a > span:before,
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"],
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  left: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] {
  z-index: 999999;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit
  > [class~="icon-search"] {
  transform: rotateY(180deg);
}
.site-header.header-v4 .header-wrapper {
  padding-bottom: 11.25pt;
}
.site-navigation ul div.sub-menu {
  padding-left: 0.3125in;
}
#header-topbar[class~="topbar-v1"] {
  border-image: none;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  width: 0.145833333in;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  top: 2.25pt;
}
[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before {
  opacity: 1;
}
.site-header.header-v4 .header-wrapper {
  padding-right: 0;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-color: currentColor;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-color: currentColor;
}
.menu-full .close-menu-full:hover:after {
  transform: rotate(-45deg);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  z-index: 2;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  width: 0.166666667in;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-image: none;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  height: 12pt;
}
.site-navigation ul > li.level-0 .sub-menu {
  opacity: 0;
}
[class~="site-menu-wrapper"] [class~="navbar-default"] {
  background: transparent;
}
.site-header.header-v4 .header-wrapper {
  padding-top: 11.25pt;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  content: "";
}
[class~="site-navigation"] {
  vertical-align: top;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"] {
  margin-right: 15px;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a,
[class~="menu-full"] [class~="menu-lines"],
.site-header .header-mobile .mini-cart .cart-icon {
  position: relative;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  padding-left: 2.5pc;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  top: -0.4375pc;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  display: inline-block;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-radius: 50%;
}
[class~="site-navigation"] {
  float: none;
}
[class~="site-header"][class~="header-v2"]
  [class~="header-desktop"]
  [class~="mini-cart"]
  [class~="cart-popup"] {
  top: 50px !important;
}
[class~="menu-full"] [class~="menu-lines"] {
  display: block;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  position: fixed;
}
[class~="site-header"][class~="header-v3"][class~="relative"]
  [class~="mini-cart"]
  [class~="cart-popup"] {
  top: 4.125pc !important;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -webkit-border-radius: 50%;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -moz-border-radius: 50%;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -ms-border-radius: 50%;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  top: 50%;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  bottom: 0;
}
[class~="form-login-register"] {
  display: none;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  width: 100%;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  background: #fff;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-navigation"] ul {
  padding-bottom: 0;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  display: flex;
}
.site-header.header-v4 .header-top .mini-cart .cart-popup {
  top: 4.0625pc !important;
}
[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before {
  visibility: visible;
}
[class~="menu-full"] [class~="menu-lines"] {
  width: 1.25pc;
}
[class~="menu-full"] [class~="menu-lines"] {
  height: 9pt;
}
.site-navigation ul > li.level-0 .sub-menu {
  visibility: hidden;
}
[class~="menu-full"] [class~="menu-lines"] {
  cursor: pointer;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  left: calc(50% - 7px);
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-left: 15px;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-bottom: 0.625pc;
}
[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  -webkit-transform: translateX(0);
}
[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  transform: translateX(0);
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-right: 15px;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -o-border-radius: 50%;
}
#header-topbar .address i,
.form-login-register .box-form-login .rememberme-lost .inline:after {
  top: 2.25pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"],
.form-login-register .box-form-login .rememberme-lost .inline:after,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  position: absolute;
}
[class~="ruper-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  opacity: 1;
}
#header-topbar .address i {
  color: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:hover:before {
  width: 50%;
}
.site-navigation ul div.sub-menu {
  padding-bottom: 0.229166667in;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  margin-top: -0.010416667in;
}
.site-navigation ul div.sub-menu {
  padding-right: 0.3125in;
}
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"],
[class~="menu-full"] [class~="site-navigation"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before,
[class~="form-login-register"] {
  width: 100%;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-top: 0.9375pc;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  background-color: #000;
}
[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  align-items: center;
}
.site-navigation ul > li.level-0 .sub-menu {
  -webkit-transition: opacity 0.1s linear, transform 0.1s linear;
}
#header-topbar #topbar-menu li:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
.site-navigation ul > li.level-0 > a > span:before {
  content: "";
}
[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a {
  color: #fff;
}
[class~="menu-full"] [class~="site-navigation"] {
  left: 0;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-left-width: 3pt;
}
#header-topbar [class~="email"] i {
  margin-right: 0.104166667in;
}
.site-navigation ul > li.level-0 .sub-menu {
  transition: opacity 0.1s linear, transform 0.1s linear;
}
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"] {
  height: 1.5pt;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  top: 0;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] {
  padding-left: 0;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-bottom-width: 3pt;
}
#header-topbar [class~="email"] i {
  font-size: 0.15625in;
}
#header-topbar #topbar-menu li,
#header-topbar [class~="phone"],
#header-topbar [class~="email"],
#header-topbar [class~="ship"] {
  display: inline-block;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-right-width: 3pt;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  text-transform: uppercase;
}
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"] {
  background: #000;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  -webkit-transition: all 0.3s ease;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  padding-right: 2.5pc;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  right: -0.177083333in;
}
.site-navigation ul > li.level-0 .sub-menu {
  -webkit-transform: translateY(20px);
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-top-width: 3pt;
}
[class~="menu-full"] [class~="site-navigation"] {
  background: #fff;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-left-style: solid;
}
[class~="menu-full"] [class~="site-navigation"] {
  padding-bottom: 4.0625pc;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li:first-child {
  padding-top: 0;
}
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"] {
  -webkit-transition: width 0.3s ease, top 0.3s ease;
}
[class~="menu-full"] [class~="site-navigation"] {
  padding-right: 0;
}
.site-header .header-mobile .mini-cart .cart-icon {
  top: -1.5pt;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-bottom-style: solid;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-right-style: solid;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-weight: 500;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"]
  input:checked
  + label:after {
  transform: scale(1);
}
[class~="menu-full"] [class~="menu-lines"] [class~="line-general"] {
  transition: width 0.3s ease, top 0.3s ease;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"],
#header-topbar [class~="email"] i,
#header-topbar .email {
  position: relative;
}
[class~="menu-full"] [class~="site-navigation"] {
  padding-top: 200px;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  font-family: feather;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-top-style: solid;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  content: "";
}
.site-navigation ul div.sub-menu {
  padding-top: 0.229166667in;
}
[class~="menu-full"] [class~="site-navigation"][class~="active"] {
  -webkit-transform: translateX(0);
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-left-color: #fff;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  top: -5.25pt;
}
.site-navigation ul > li.level-0 .sub-menu {
  transform: translateY(20px);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a
  > span:before {
  opacity: 1;
}
[class~="form-login-register"] {
  position: fixed;
}
[class~="menu-full"] [class~="site-navigation"][class~="active"] {
  transform: translateX(0);
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  width: 13.5pt;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] {
  font-size: 14px;
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  padding-top: 1.875pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before,
.site-navigation ul > li.level-0 > a > span:before {
  position: absolute;
}
[class~="ruper-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a
  > span:before {
  visibility: visible;
}
[class~="menu-full"] [class~="site-navigation"] {
  height: 100vh;
}
[class~="site-header"] [class~="header-page-link"] > :first-child {
  margin-left: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  text-align: right;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  z-index: 9;
}
#header-topbar [class~="email"] i {
  top: 2.25pt;
}
[class~="form-login-register"] {
  height: 100%;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-bottom-color: #fff;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="menu-section"] {
  margin-bottom: 22.5pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  right: 1pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  right: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  top: 11.25pt;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-right-color: #fff;
}
.site-navigation ul > li.level-0 ul.sub-menu li:last-child {
  padding-bottom: 0;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  height: 0.1875in;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="menu-full"] [class~="close-menu-full"]:after {
  -webkit-transition: all 0.3s ease;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="menu-full"] [class~="close-menu-full"]:after {
  transition: all 0.3s ease;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] {
  padding-bottom: 15pt;
}
[class~="menu-full"] [class~="site-navigation"] {
  overflow: auto;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  font-family: icomoon;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  cursor: pointer;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  width: 1.75pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  height: 21pt;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  line-height: 1.125pc;
}
[class~="form-login-register"] {
  top: 0;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  display: inline-block;
}
.form-login-register .box-form-login .rememberme-lost .inline:after,
[class~="form-login-register"] {
  left: 0;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  color: #fff;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  display: block;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-top-color: #fff;
}
[class~="menu-full"] [class~="site-navigation"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  text-align: center;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  border-image: none;
}
.form-login-register .box-form-login .rememberme-lost .inline:after,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  background: #000;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  -webkit-border-radius: 50%;
}
[class~="menu-full"] [class~="site-navigation"] {
  -webkit-transform: translateX(100%);
}
[class~="menu-full"] [class~="site-navigation"] {
  transform: translateX(100%);
}
[class~="site-navigation"] ul,
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] {
  padding-right: 0;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  content: "";
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  -moz-border-radius: 50%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  -ms-border-radius: 50%;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a
  > span:before {
  width: 100%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  -o-border-radius: 50%;
}
.site-navigation ul div.sub-menu {
  box-shadow: 0 0 0.020833333in rgba(0, 0, 0, 0.1);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-navigation"] ul {
  padding-top: 0;
}
/* [class~="site-navigation"] ul > li[class~="level-0"][class~="menu-item-has-children"] > a:after {
  content: "\e64b";
} */
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li:first-child {
  padding-top: 0;
}
[class~="form-login-register"] {
  background: rgba(0, 0, 0, 0.5);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-family: themify;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-width: 0.010416667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  top: -14px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit
  i {
  font-size: 0.1875in;
}
#header-topbar #topbar-menu li:before {
  right: 0;
}
[class~="menu-full"] [class~="site-navigation"] {
  z-index: 10;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -webkit-transition: all 0.3s ease;
}
[class~="ruper-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  -webkit-transform: translateY(0) !important;
}
.form-login-register .box-form-login .rememberme-lost .inline:after,
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  transition: all 0.3s ease;
}
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  font-size: 0.75pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-width: 0.010416667in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a,
.menu-full .close-menu-wrap,
.site-header .header-page-link .mini-cart .cart-icon,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  position: relative;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-width: 0.010416667in;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  z-index: 1;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-width: 0.010416667in;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  -webkit-transform: scale(0);
}
[class~="menu-full"] [class~="close-menu-full"]:before,
.menu-full .close-menu-full {
  position: absolute;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-style: solid;
}
.form-login-register .box-form-login .rememberme-lost .inline:after {
  transform: scale(0);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit
  i {
  line-height: 46px;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-size: 11px;
}
.menu-full .close-menu-full {
  z-index: 9;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-style: solid;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-style: solid;
}
#header-topbar #topbar-menu li:before {
  background: rgba(0, 0, 0, 0.15);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  font-size: 15pt;
}
[class~="form-login-register"] {
  z-index: 999999;
}
.menu-full .close-menu-full {
  top: -6.25pc;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  text-align: center;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-style: solid;
}
.site-navigation ul > li.level-0.mega-menu > .sub-menu {
  min-width: auto;
}
[class~="site-header"] [class~="header-page-link"] > *,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a {
  display: inline-block;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-color: #e5e5e5;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-color: transparent;
}
.menu-full .close-menu-full {
  left: 50%;
}
[class~="search-box"],
.menu-full .close-menu-full {
  cursor: pointer;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-color: #e5e5e5;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] {
  padding-top: 15pt;
}
#header-topbar .email {
  margin-left: 11.25pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-color: #e5e5e5;
}
.menu-full .close-menu-full {
  width: 1.75pc;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="menu-section"]:last-child {
  margin-bottom: 0;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  padding-right: 40px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-image: none;
}
[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  height: 1.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  display: inline-flex;
}
.menu-full .close-menu-full {
  height: 28px;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  top: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  background: #fff;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  align-items: center;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  box-shadow: none;
}
[class~="ruper-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  transform: translateY(0) !important;
}
.menu-full .close-menu-full,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  -webkit-transform: rotate(45deg);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
.menu-full .close-menu-full {
  transform: rotate(45deg);
}
#header-topbar [class~="email"] i {
  color: #000;
}
[class~="form-login-register"][class~="active"],
[class~="ruper-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="remove-cart-shadow"] {
  display: block;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  justify-content: center;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  width: 0.145833333in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  display: flex;
}
[class~="site-header"] [class~="header-page-link"] {
  text-align: right;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  justify-content: space-between;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  flex-wrap: wrap;
}
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  letter-spacing: 0.125pc;
}
.form-login-register .box-form-login .active-login:after,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  position: absolute;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  margin-top: 12.75pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  width: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  margin-bottom: 10px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-login"],
#header-topbar [class~="phone"] i {
  position: relative;
}
[class~="site-header"] [class~="header-page-link"] > * {
  margin-left: 0.197916667in;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  background: #b9b9b9;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  border-radius: 3.75pt;
}
.form-login-register .box-form-login .button-login input[type="submit"] {
  background: #000;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  -webkit-transition: all 0.3s ease;
}
.form-login-register .box-form-login .active-login:after {
  content: "";
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-left-width: 0;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  min-width: 225pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-bottom-width: 0;
}
#header-topbar [class~="phone"] i {
  margin-right: 0.104166667in;
}
[class~="site-header"] [class~="header-page-link"] > * {
  vertical-align: middle;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-right-width: 0;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-top-width: 0;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  transition: all 0.3s ease;
}
.mini-cart .cart-popup .total-cart .total-price {
  font-size: 1.25pc;
}
[class~="form-login-register"] [class~="box-form-login"] {
  width: 100%;
}
[class~="site-header"][class~="header-v1"][class~="relative"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 22.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  -webkit-border-radius: 0.3125pc;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  -webkit-border-radius: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-left-style: none;
}
[class~="form-login-register"] [class~="box-form-login"] {
  max-width: 382.5pt;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-bottom-style: none;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  font-size: 1.125pc;
}
[class~="form-login-register"] [class~="box-form-login"] {
  background: #fff;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-size: 0.875pc;
}
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-right-style: none;
}
.form-login-register .box-form-login .active-login:after {
  height: 1.5pt;
}
[class~="search-box"] {
  text-align: center;
}
[class~="form-login-register"] [class~="box-form-login"] {
  margin-left: auto;
}
#header-topbar [class~="phone"] i {
  font-size: 0.15625in;
}
[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="form-login-register"] [class~="box-form-login"] {
  top: 50%;
}
.search-overlay {
  position: fixed;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  -moz-border-radius: 5px;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  left: calc(50% - 7px);
}
[class~="form-login-register"] [class~="box-form-login"] {
  margin-bottom: auto;
}
[class~="site-navigation"] ul {
  list-style: none;
}
.form-login-register .box-form-login .active-login:after {
  width: 10.5pt;
}
#header-topbar .topbar-right > * a:hover i,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  color: #000;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  -ms-border-radius: 0.052083333in;
}
[class~="form-login-register"] [class~="box-form-login"] {
  margin-right: auto;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  -o-border-radius: 5px;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-top-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  font-size: 0.3125in;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li:last-child {
  padding-bottom: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-left-color: currentColor;
}
#header-topbar .email {
  padding-left: 0.208333333in;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-bottom-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-right-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-top-color: currentColor;
}
.site-navigation ul > li.level-0 > a > span:before {
  width: 0;
}
[class~="form-login-register"] [class~="box-form-login"] {
  margin-top: auto;
}
#header-topbar [class~="phone"] i {
  top: 2.25pt;
}
[class~="form-login-register"] [class~="box-form-login"] {
  position: absolute;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after,
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:last-child:after,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"][class~="icon"]
  > a:after {
  content: none;
}
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-image: none;
}
[class~="form-login-register"] [class~="box-form-login"] {
  left: calc(50% - 255px);
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"],
.search-overlay {
  width: 100%;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  -moz-border-radius: 0;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  font-size: 0.135416667in;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a[class~="black"]:after {
  background: #000;
}
.search-overlay {
  height: 100%;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  position: relative;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  color: #fff;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-right-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-left: 0.020833333in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  background: #e9e9e9;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  bottom: -0.010416667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  height: 3.75pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-left: 0;
}
.search-overlay {
  top: 0;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  margin-bottom: 1.125pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-bottom: 0.3125in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-bottom: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"]:hover > a > span:before {
  opacity: 1;
}
.site-navigation ul ul.sub-menu,
[class~="form-login-register"] [class~="box-form-login"] {
  padding-left: 0.3125in;
}
.form-login-register .box-form-login .active-login:after {
  top: 50%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  bottom: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  text-transform: uppercase;
}
[class~="form-login-register"] [class~="box-form-login"] {
  padding-bottom: 0.3125in;
}
#header-topbar {
  height: 33.75pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  left: 50%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-image: none;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  cursor: pointer;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  text-transform: capitalize;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-right: 0.020833333in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-right: 0;
}
[class~="form-login-register"] [class~="box-form-login"] {
  padding-right: 0.3125in;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  vertical-align: middle;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  display: inline-block;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  font-weight: 500;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-top: 0.625pc;
}
.search-overlay {
  color: #000;
}
[class~="form-login-register"] [class~="box-form-login"] {
  padding-top: 0.3125in;
}
.site-navigation ul > li.level-0 > a > span:before {
  height: 0.0625pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  position: absolute;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  height: 3.4375pc;
}
.form-login-register .box-form-login .active-login:after,
[class~="menu-full"] [class~="close-menu-full"]:before {
  margin-top: -0.010416667in;
}
.form-login-register .box-form-login .active-login:after {
  left: calc(50% - 7px);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  margin-left: 0.3125pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  width: calc(50% - 4px);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  line-height: 0.572916667in;
}
#header-topbar #topbar-menu li:before {
  width: 0.75pt;
}
[class~="site-navigation"] ul > li[class~="level-0"]:hover > a > span:before {
  visibility: visible;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-left: 3.75pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  width: 100%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  text-align: center;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  background-color: #000;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  width: 12.75pt;
}
[class~="form-login-register"] [class~="box-form-login"] {
  -webkit-transform: translateY(-50%);
}
[class~="form-login-register"] [class~="box-form-login"] {
  transform: translateY(-50%);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  -webkit-transform: translateX(-50%);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  transform: translateX(-50%);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  margin-top: 11.25pt;
}
.form-login-register .box-form-login .active-login:after {
  background-color: rgba(0, 0, 0, 0.5);
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-bottom: 0;
}
[class~="form-login-register"] [class~="box-form-login"] {
  overflow: hidden;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  height: 100%;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  content: "";
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-left: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
.form-login-register .box-form-login .active-login:after {
  -webkit-transition: all 0.3s ease;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"],
[class~="form-login-register"] [class~="box-content"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  position: relative;
}
.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  letter-spacing: 0.1em;
}
[class~="site-navigation"] ul li {
  text-align: left;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  background: #b9b9b9;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-left-width: 0;
}
.form-login-register .box-form-login .active-login:after {
  transition: all 0.3s ease;
}
#header-topbar #topbar-menu {
  list-style: none;
}
[class~="form-login-register"] [class~="box-content"] {
  border-left-width: 0.010416667in;
}
[class~="form-login-register"] [class~="box-content"] {
  border-bottom-width: 0.010416667in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-bottom-width: 0;
}
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  padding-bottom: 5px !important;
}
[class~="form-login-register"] [class~="box-content"] {
  border-right-width: 0.010416667in;
}
[class~="form-login-register"] [class~="box-content"] {
  border-top-width: 0.010416667in;
}
[class~="form-login-register"] [class~="box-content"] {
  border-left-style: solid;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  background: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-width: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-right: 3.75pt;
}
.search-overlay {
  right: 0;
}
[class~="form-login-register"] [class~="box-content"] {
  border-bottom-style: solid;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  height: 0.177083333in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  left: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-top: 0;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  width: 2.5pc;
}
[class~="form-login-register"] [class~="box-content"] {
  border-right-style: solid;
}
#header-topbar [class~="topbar-right"] > :last-child {
  padding-right: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  position: absolute;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  display: block;
}
[class~="form-login-register"] [class~="box-content"] {
  border-top-style: solid;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  background-color: transparent;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-width: 0;
}
[class~="menu-full"] [class~="site-navigation"] #main-navigation,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  display: inline-block;
}
[class~="form-login-register"] [class~="box-content"] {
  border-left-color: #e5e5e5;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-style: none;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-style: none;
}
[class~="site-navigation"] ul > li[class~="level-0"]:hover > a > span:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  width: 100%;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  padding-left: 0.9375pc;
}
[class~="form-login-register"] [class~="box-content"] {
  border-bottom-color: #e5e5e5;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-style: none;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  top: -0.010416667in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  -ms-border-radius: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  padding-bottom: 7.5pt;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  height: 0.75pt;
}
[class~="site-navigation"] ul {
  float: none;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  padding-right: 0.9375pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transition: all 0.3s ease;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-left: 30pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-left-color: currentColor;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  padding-top: 7.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-top: 0;
}
[class~="form-login-register"] [class~="box-content"] {
  border-right-color: #e5e5e5;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-color: currentColor;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  font-weight: 500;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  height: 1.5pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-top-color: currentColor;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  width: 10.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  background: #fff;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-image: none;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  font-size: 0.135416667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  letter-spacing: 0.1em;
}
[class~="menu-full"] [class~="close-menu-full"]:before,
.site-navigation ul > li.level-0 > a > span:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  -webkit-transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  color: #fff;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  text-transform: uppercase;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  -webkit-transform: translateX(-50%);
}
[class~="form-login-register"] [class~="box-content"] {
  border-top-color: #e5e5e5;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  background: rgba(0, 0, 0, 0.2);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-weight: 400;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  overflow: hidden;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  cursor: pointer;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  display: inline-block;
}
[class~="form-login-register"] [class~="box-content"] {
  border-image: none;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  -o-border-radius: 0;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-bottom: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  height: 3.4375pc;
}
.search-overlay {
  z-index: 999999;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  line-height: 0.572916667in;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  position: relative;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transform: translateX(-50%);
}
[class~="form-login-register"] [class~="box-content"] {
  overflow-x: hidden;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-right: 30pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  position: absolute;
}
[class~="form-login-register"] [class~="box-content"] {
  height: 100%;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  background: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  top: 50%;
}
[class~="form-login-register"] [class~="box-content"] {
  overflow-y: auto;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  left: calc(50% - 7px);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  margin-top: -0.75pt;
}
[class~="mini-cart"] [class~="remove-cart-shadow"],
[class~="form-login-register"] [class~="box-content"] [class~="form-register"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  width: 100%;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  line-height: 1;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  text-align: center;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  top: 0;
}
.search-overlay {
  opacity: 0;
}
#header-topbar .topbar-right {
  text-align: right;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  margin-top: 11.25pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  background: #a0a0a0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  font-weight: 500;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  height: 0.75pt;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  position: fixed;
}
#header-topbar #topbar-menu li:last-child {
  margin-right: 0;
}
[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span:before {
  background: #fff;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  letter-spacing: 0.1em;
}
#header-topbar #topbar-menu {
  margin-bottom: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  -webkit-transition: unset;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  transition: unset;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  -webkit-transition: all 0.5s ease;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  bottom: 0;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before,
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  content: "";
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  top: 6px;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"]:before {
  background: #6d6d6d;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-top: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  cursor: pointer;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  left: 50%;
}
#header-topbar [class~="phone"] i {
  color: #000;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  line-height: 0.177083333in;
}
[class~="site-header"][class~="header-v3"][class~="relative"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0.427083333in;
}
#header-topbar [class~="ship"] i {
  margin-right: 0.104166667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-radius: 0;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  -webkit-transition: all 0.2s ease;
}
.site-navigation ul ul.sub-menu {
  padding-bottom: 0.229166667in;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before,
[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  height: 100%;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  line-height: 18pt;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  background-color: rgba(0, 0, 0, 0.5);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  display: inline-block;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  position: absolute;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  -webkit-border-radius: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  -moz-border-radius: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  -ms-border-radius: 0;
}
[class~="mini-cart"] [class~="remove-cart-shadow"],
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  left: 0;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  ul[class~="sub-menu"] {
  top: -1.625pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  transition: all 0.5s ease;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  -o-border-radius: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-width: 0;
}
.site-header .header-mobile .mini-cart .cart-icon i,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  font-size: 0.208333333in;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  top: 0;
}
.search-overlay,
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  visibility: hidden;
}
.mini-cart .cart-popup .cart-list li.empty,
.site-header .header-mobile .mini-cart .cart-icon i,
#header-topbar [class~="ship"] i,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  position: relative;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-bottom-width: 0;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  z-index: 9999;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  color: #fff;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-right-width: 0;
}
.site-header .header-mobile .mini-cart .cart-icon i {
  top: 0.041666667in;
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  z-index: 2;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  -webkit-transition: all 0.3s ease;
}
#header-topbar [class~="ship"] i {
  font-size: 0.15625in;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-width: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li {
  padding-left: 1.875pc;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  background: rgba(0, 0, 0, 0.4);
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transform-origin: 50% 50%;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li,
.site-navigation ul ul.sub-menu {
  padding-right: 0.3125in;
}
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  display: none;
}
[class~="mini-cart"] [class~="cart-popup"] {
  text-align: left;
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  background: #fff;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  top: 0;
}
.site-header .header-page-link .search-box .search-toggle,
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  color: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  -webkit-transform: rotate(95deg);
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  opacity: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-left-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-bottom-style: none;
}
#header-topbar [class~="topbar-right"] > * {
  display: inline-block;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-right-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  background: #000;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-top-style: none;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li {
  padding-bottom: 0.041666667in;
}
#header-topbar [class~="topbar-right"] > * {
  padding-left: 0;
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  -webkit-transition: all 0.5s ease;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-left-color: currentColor;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-left-color: #000;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transform: rotate(95deg);
}
.search-overlay {
  -webkit-transition: all 0.3s ease-in-out;
}
.site-navigation ul > li.level-0 > a > span:before,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="cart-popup"] {
  width: 285pt;
}
.mini-cart .cart-popup .cart-list li.empty,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  text-align: center;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  background: #cbcbcb;
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transition: all 0.5s ease;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  left: 0;
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  -webkit-transform: translateX(100%);
}
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transform: translateX(100%);
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"],
#header-topbar #topbar-menu li:before {
  height: 13.5pt;
}
[class~="site-header"] [class~="header-mobile"] [class~="ruper-topcart"] {
  margin-left: 0.104166667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-bottom-color: currentColor;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-right-color: currentColor;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"],
[class~="site-header"] [class~="header-mobile"] [class~="ruper-topcart"] {
  margin-bottom: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-top-color: currentColor;
}
.site-navigation ul ul.sub-menu {
  padding-top: 0.229166667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-bottom-color: #000;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-image: none;
}
[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  opacity: 1;
}
[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  visibility: visible;
}
[class~="site-header"] [class~="header-mobile"] [class~="ruper-topcart"] {
  margin-right: 0.104166667in;
}
.mini-cart .cart-popup .cart-list li.empty {
  padding-top: 55px;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"],
[class~="site-header"] [class~="header-mobile"] [class~="ruper-topcart"] {
  margin-top: 0;
}
.mini-cart .cart-popup .cart-list li.empty,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  font-size: 0.1875in;
}
#header-topbar [class~="ship"] i,
.mini-cart .cart-popup .cart-list li.empty {
  color: #000;
}
[class~="mini-cart"] [class~="cart-popup"] {
  padding-left: 0.208333333in;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  width: 13.5pt;
}
.search-overlay {
  transition: all 0.3s ease-in-out;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  font-size: 15pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  background: transparent;
}
[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  -webkit-transform: translateX(0);
}
[class~="mini-cart"] [class~="cart-popup"] {
  padding-bottom: 24px;
}
[class~="site-header"] [class~="header-mobile"] [class~="mini-cart"] {
  text-align: right;
}
[class~="site-header"] [class~="header-page-link"] > [class~="ruper-topcart"] {
  margin-left: 15pt;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li,
[class~="ruper-topcart"][class~="dropdown"] [class~="mini-cart"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"],
[class~="menu-full"] [class~="site-navigation"] #main-navigation {
  position: relative;
}
#header-topbar [class~="ship"] i {
  top: 2.25pt;
}
[class~="ruper-topcart"][class~="dropdown"] [class~="top-total-cart"],
[class~="ruper-topcart"][class~="dropdown"] [class~="remove-cart"] {
  display: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  content: "";
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  display: table;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}
[class~="mini-cart"] [class~="cart-popup"] {
  padding-right: 0.208333333in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  -webkit-border-radius: 50%;
}
[class~="mini-cart"] [class~="cart-popup"] {
  padding-top: 24px;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  -moz-border-radius: 50%;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  white-space: nowrap;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-style: none;
}
.site-navigation ul ul.sub-menu {
  box-shadow: 0 0 0.020833333in rgba(0, 0, 0, 0.1);
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  list-style: none;
}
#header-topbar [class~="topbar-right"] > * {
  padding-bottom: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  margin-left: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  -ms-border-radius: 50%;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  margin-right: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  -o-border-radius: 50%;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-width: 0.010416667in;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  top: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li {
  padding-top: 0.041666667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-bottom-width: 0.010416667in;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-right-width: 0.010416667in;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-left: 0;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  height: 0.75pt;
}
[class~="form-login-register"] [class~="box-content"] > div > form {
  padding-left: 22.5pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  font-family: wpbingofont;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-bottom: 10px;
}
[class~="form-login-register"] [class~="box-content"] > div > form {
  padding-bottom: 22.5pt;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-width: 0.010416667in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:hover:before {
  width: 100%;
}
#header-topbar [class~="email"]:before,
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  position: absolute;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-right: 0;
}
[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  transform: translateX(0);
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-top: 0;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-style: solid;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  max-height: 335px;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  -webkit-transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-bottom-style: solid;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-right-style: solid;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="cart-popup"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-style: solid;
}
[class~="mini-cart"] [class~="cart-popup"] {
  border-left-color: #f3f3f3;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"] {
  text-align: left;
}
.site-navigation ul > li.level-0 > a > span:before {
  background: #000;
}
[class~="mini-cart"] [class~="cart-popup"] {
  border-bottom-color: #f3f3f3;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-bottom: 0.25pc;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  transition: all 0.2s ease;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  transform-origin: 50% 50%;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  padding-top: 0.3125pc;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  overflow-y: auto;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  -webkit-transform: rotate(95deg);
}
[class~="mini-cart"] [class~="cart-popup"] {
  border-right-color: #f3f3f3;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  right: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="mini-cart"] [class~="cart-popup"] {
  color: #000;
}
[class~="menu-full"] [class~="close-menu-full"]:before {
  transform: rotate(95deg);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-style: none;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  top: 50%;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"],
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  position: relative;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] {
  margin-bottom: 20px;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] {
  overflow: hidden;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  -webkit-transform: translateY(-50%);
}
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  display: inline-block;
}
[class~="mini-cart"] [class~="cart-popup"] {
  border-top-color: #f3f3f3;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  color: #868686;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"] {
  flex: 1;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-color: currentColor;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  width: 58.5pt;
}
[class~="mini-cart"] [class~="cart-popup"] {
  border-image: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-color: #000;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-bottom-color: #000;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  list-style: none;
}
#header-topbar #topbar-menu li:before {
  top: calc(50% - 9px);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-left: 0;
}
[class~="mini-cart"] [class~="cart-popup"] {
  background: #fff;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  float: left;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-bottom: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="remove"],
[class~="mini-cart"] [class~="cart-popup"] {
  position: absolute;
}
[class~="form-login-register"] [class~="box-content"] > div > form {
  padding-right: 22.5pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  text-transform: capitalize;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  margin-right: 0.75pc;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  padding-bottom: 3.75pt;
}
.site-navigation ul > li.level-0 > a > span:before,
[class~="mini-cart"] [class~="cart-popup"] {
  opacity: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  font-weight: 500;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"]:after,
#header-topbar [class~="email"]:before {
  content: "";
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-color: currentColor;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  transform: translateY(-50%);
}
[class~="mini-cart"] [class~="cart-popup"] {
  visibility: hidden;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-right-color: #000;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  display: block;
}
[class~="form-login-register"] [class~="box-content"] > div > form {
  padding-top: 15pt;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -webkit-transition: all 0.3s ease;
}
[class~="mini-cart"] [class~="cart-popup"] {
  transition: all 0.3s ease;
}
#header-topbar [class~="topbar-right"] > * {
  padding-right: 0.9375pc;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -webkit-transform: translateY(20px) !important;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-color: #000;
}
[class~="mini-cart"] [class~="cart-popup"] {
  transform: translateY(20px) !important;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-left: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-bottom: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  input {
  width: 100%;
}
[class~="mini-cart"] [class~="cart-popup"] {
  z-index: 10001;
}
[class~="site-navigation"] ul {
  -webkit-transition: all 0.35s;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-right: 0;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  input {
  height: 3.4375pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  margin-left: 0.052083333in;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-right: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"],
#header-topbar [class~="topbar-right"] > * {
  padding-top: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before,
[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-image: none;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  background: #000;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -webkit-border-radius: 0;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -moz-border-radius: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  margin-bottom: 0;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -ms-border-radius: 0;
}
[class~="mini-cart"] [class~="cart-popup"] {
  -o-border-radius: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="remove"] {
  right: 0.052083333in;
}
[class~="menu-full"] [class~="close-menu-full"]:after {
  transform-origin: 50% 50%;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  list-style: none;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  text-align: center;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-left: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  line-height: 17px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-bottom: 0.15625in;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="price"] {
  font-size: 0.875pc;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="remove"] {
  top: 0.125pc;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  input {
  line-height: 0.572916667in;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  font-size: 16px;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"],
[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li a,
[class~="form-login-register"] [class~="box-content"] > div > form,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i,
[class~="form-login-register"] [class~="box-content"] > div,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  position: relative;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  position: absolute;
}
[class~="mini-cart"] [class~="cart-popup"] {
  left: auto !important;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  background: #e5e5e5;
}
[class~="mini-cart"] [class~="cart-popup"] {
  right: 0 !important;
}
[class~="mini-cart"] [class~="cart-popup"] {
  top: 45pt !important;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  right: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  top: 0;
}
[class~="mini-cart"] [class~="cart-popup"] {
  will-change: unset !important;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-top: 0.15625in;
}
[class~="mini-cart"] [class~="cart-popup"] {
  box-shadow: 0 5px 3.75pt 0 rgba(50, 50, 50, 0.2);
}
[class~="site-header"] [class~="header-search-form"] {
  flex: 1;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  font-weight: 500;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-color: currentColor;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  font-weight: 700;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-color: currentColor;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"],
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  color: #000;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  display: -webkit-box;
}
#header-topbar #topbar-menu li {
  padding-right: 18px;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  text-indent: -0.010416667in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  display: table;
}
.search-overlay > .close-search,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  width: 100%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  font-size: 1.375pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  top: 3px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-width: 0.75pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-style: solid;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-color: #f5f5f5;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-image: none;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  top: -5px;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] a {
  color: #868686;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  line-height: 19.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  -webkit-border-radius: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  display: flex;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  -moz-border-radius: 0;
}
[class~="site-header"] [class~="header-search-form"] {
  margin-right: 37.5pt;
}
#header-topbar #topbar-menu li:last-child {
  padding-right: 0;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  width: 0.0625pc;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  letter-spacing: 0.125pc;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  font-size: 37.5pt;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  justify-content: space-between;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  border-top-width: 1px;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  border-top-style: solid;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-left: 0.3125in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  -ms-border-radius: 0;
}
[class~="search-overlay"][class~="search-visible"] {
  opacity: 1;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-top-color: #000;
}
[class~="search-overlay"][class~="search-visible"] {
  visibility: visible;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  border-image: none;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="total-cart"] {
  padding-top: 11.25pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-bottom: 0.3125in;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  line-height: 1;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-right: 0.3125in;
}
.search-overlay > .close-search {
  height: 100%;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-width: 0.75pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  -o-border-radius: 0;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  bottom: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-style: solid;
}
[class~="site-navigation"] ul {
  transition: all 0.35s;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  height: 54px;
}
#header-topbar #topbar-menu li {
  margin-right: 0.135416667in;
}
.search-overlay > .close-search {
  background: rgba(0, 0, 0, 0.25);
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  top: auto;
}
.search-overlay > .close-search {
  cursor: unset !important;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  font-size: 15pt;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  -webkit-transform: rotate(45deg);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  line-height: 37.5pt;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  max-width: 420pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"],
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  width: 100%;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-left: 1.5625pc;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  margin-left: auto;
}
[class~="form-login-register"] [class~="box-content"] > div {
  z-index: 3;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:first-child {
  margin-left: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-color: #000;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  -webkit-transform: translateX(100%);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  -webkit-transition: all 0.3s ease;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  -webkit-transition: all 0.2s ease;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  transition: all 0.3s ease;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-image: none;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  transition: all 0.2s ease;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  transform: translateX(100%);
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  margin-top: 15px;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  left: calc(50% - 25px);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-bottom: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-top: 0.3125in;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-right: 0;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  -webkit-transition: transform 0.3s ease-in-out;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  position: absolute;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  display: flex;
}
[class~="form-login-register"] [class~="box-content"] > div {
  background: #fff;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  padding-top: 15pt;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  font-size: 1pc;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  left: 50%;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  top: calc(50% - 17px);
}
[class~="site-navigation"] ul {
  margin-left: 0;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  transition: transform 0.3s ease-in-out;
}
#header-topbar {
  line-height: 0.46875in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  display: inline-block;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] {
  margin-bottom: 0.104166667in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before,
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  height: auto;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  color: #868686;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  justify-content: space-between;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  content: "";
}
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] {
  margin-top: 13px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  width: auto;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  flex-wrap: wrap;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  padding-left: 37.5pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  font-weight: 700;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-right: 1.5625pc;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  padding-bottom: 0.416666667in;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  display: block;
}
[class~="search-overlay"] [class~="wrapper-search"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  right: 0;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  width: 100%;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  height: 37.5pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"],
[class~="search-overlay"] [class~="wrapper-search"] {
  top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  font-family: FontAwesome;
}
[class~="form-login-register"] [class~="box-content"] > div {
  opacity: 0;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  color: #000 !important;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  padding-right: 37.5pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  background-color: transparent !important;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-size: 0.9375pc;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"]
  a {
  font-weight: 500;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  z-index: 10;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  position: relative;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="search-top"] {
  overflow: hidden;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-width: 0.010416667in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  font-size: 18px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"]
  a {
  text-transform: capitalize;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-bottom-width: 0.010416667in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  padding-left: 7.5pt;
}
.site-navigation ul > li.level-0 > a > span:before {
  visibility: hidden;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  padding-top: 0.416666667in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  padding-bottom: 10px;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-right-width: 0.010416667in;
}
[class~="search-overlay"] [class~="wrapper-search"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  background: #fff;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  background: none;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-top-width: 0.010416667in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  font-size: 15pt;
}
[class~="search-overlay"] [class~="wrapper-search"] {
  position: fixed;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: -webkit-box;
}
.search-overlay.search-visible .search-from .search-box input[type="text"] {
  max-width: 100%;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  -webkit-animation: 2s linear 0s normal none infinite running o;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  text-align: center;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  padding-right: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  padding-top: 7.5pt;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:last-child {
  margin-right: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul
  li
  a:hover {
  text-decoration: underline;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  display: -ms-flexbox;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before,
[class~="search-overlay"][class~="search-visible"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]::placeholder {
  color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  -webkit-transition: transform 0.5s ease;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  transition: transform 0.5s ease;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  -webkit-transform: translateX(0);
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-style: solid;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  padding-right: 0.208333333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-width: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-width: 1.5pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-right-width: 0;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-style: solid;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-right-style: solid;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-width: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus,
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-style: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-style: none;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  transform: rotate(45deg);
}
[class~="search-overlay"] [class~="wrapper-search"] {
  height: 100%;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-top-style: solid;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"],
[class~="search-overlay"] [class~="wrapper-search"] {
  width: 100%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  transform: translateX(0);
}
[class~="search-overlay"] [class~="search-from"] {
  margin-left: auto;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-width: 0;
}
[class~="search-overlay"] [class~="search-from"] {
  margin-bottom: auto;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  width: calc(100% - 20px);
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus,
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-bottom-color: #000;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"],
#header-topbar [class~="email"]:before {
  left: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="close-search"] {
  cursor: pointer;
}
[class~="search-overlay"] [class~="search-from"] {
  margin-right: auto;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-style: none;
}
[class~="search-overlay"] [class~="search-from"] {
  margin-top: auto;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-color: currentColor;
}
[class~="search-overlay"] [class~="search-from"] {
  display: flex;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-right-color: #000;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-top-color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-color: #e5e5e5;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-image: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-color: currentColor;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  max-width: 6.875in;
}
#header-topbar #topbar-menu li:last-child:before {
  content: none;
}
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  margin-left: auto;
}
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  span,
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  display: block;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-color: currentColor;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  position: absolute;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-image: none;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  background: #fff;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]:hover,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  color: #000;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-size: 30px;
}
[class~="search-overlay"] [class~="search-from"] {
  position: relative;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  -webkit-transition: all 0.2s ease 0s;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: -ms-flexbox;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  line-height: 1.3;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]:hover {
  top: -0.03125in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-left: 30px;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  margin-bottom: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-bottom: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  transition: all 0.2s ease 0s;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  top: -0.052083333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-right: 0.729166667in;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-top: 0.25pc;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  right: -17px;
}
#header-topbar [class~="email"]:before {
  background: rgba(0, 0, 0, 0.15);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  width: 1.0625pc;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  border-radius: 3pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-top-width: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  -webkit-border-radius: 0.041666667in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  -moz-border-radius: 4px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  -ms-border-radius: 3pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  -o-border-radius: 3pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  line-height: 30pt;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  box-shadow: 0 1px 0.125in 0.125pc hsla(0, 0%, 56%, 0.3);
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  display: none;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products-content"] {
  z-index: 10;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  -webkit-border-radius: 50%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  width: 100%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-size: 0.166666667in;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  height: 12.75pt;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: flex;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  margin-bottom: 3.125pc;
}
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]:hover,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  color: #fff;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-weight: 500;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  max-height: 3.125in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  -webkit-transition: max-width 1s ease;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  transition: max-width 1s ease;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  transition-delay: 0.3s;
}
[class~="form-login-register"] [class~="box-content"] > div {
  visibility: hidden;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: none;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  -webkit-box-align: center;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  line-height: 0.177083333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  max-width: 0;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  display: inline-block;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown) {
  border-left-color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-left-style: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown) {
  border-bottom-color: #000;
}
.search-overlay .search-from .search-box,
[class~="site-navigation"] ul > li[class~="level-0"] > a > span,
.site-header .header-page-link .wishlist-box,
#header-topbar #topbar-menu li {
  position: relative;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"]:hover {
  color: #000;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  overflow-y: auto;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown) {
  border-right-color: #000;
}
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a,
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  font-weight: 400;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  -moz-border-radius: 50%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown),
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-top-color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  color: #acacaa;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-left: 0.208333333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  font-size: 1.125pc;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-left: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-style: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-right-style: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-bottom: 15px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-bottom: 0.208333333in;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  -ms-border-radius: 50%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-top-style: none;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-right: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-right: 0.208333333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-top: 0.208333333in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  overflow-x: hidden;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  text-align: center;
}
[class~="search-overlay"][class~="search-visible"] [class~="wrapper-search"] {
  -webkit-transform: translateX(0);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  background: #000;
}
[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  font-size: 0.9375pc;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  -o-border-radius: 50%;
}
[class~="search-overlay"][class~="search-visible"] [class~="wrapper-search"] {
  transform: translateX(0);
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}
.search-overlay .search-from .search-box {
  text-align: left;
}
.search-overlay .search-from .search-box {
  flex: 1;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"]:hover {
  top: -2.25pt;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  text-transform: uppercase;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  letter-spacing: 1.125pt;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  -webkit-border-radius: 50%;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-left-color: currentColor;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul {
  padding-left: 0;
}
[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] strong {
  text-decoration: underline;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  font-size: 0.114583333in;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul {
  padding-bottom: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul {
  padding-right: 0;
}
.search-overlay
  .search-from
  .search-box
  input:placeholder-shown
  + .search-top
  .close-search {
  -webkit-transform: translateX(100%);
}
#header-topbar [class~="email"]:before {
  width: 0.0625pc;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-color: currentColor;
}
.search-overlay
  .search-from
  .search-box
  input:placeholder-shown
  + .search-top
  .close-search {
  transform: translateX(100%);
}
.search-overlay .search-from .search-box {
  cursor: unset;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  -webkit-box-align: center;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  padding-top: 0;
}
.site-header .header-page-link .wishlist-box {
  font-size: 20px;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-right-color: currentColor;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-top-color: currentColor;
}
.site-header .header-page-link .wishlist-box {
  top: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul {
  list-style: none;
}
[class~="form-login-register"] [class~="box-content"] > div {
  -webkit-transition: all 0.5s ease;
}
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  > a,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"]:hover {
  color: #fff;
}
.site-header .header-page-link .wishlist-box {
  -webkit-transition: all 0.3s ease;
}
.site-header .header-page-link .wishlist-box {
  transition: all 0.3s ease;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:first-child {
  padding-top: 0;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-image: none;
}
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  font-size: 12px;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  -ms-flex-align: center;
}
[class~="site-header"][class~="header-v2"][class~="large-height"]
  [class~="header-desktop"]
  [class~="mini-cart"]
  [class~="cart-popup"] {
  top: 0.625in !important;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  padding-bottom: 0;
}
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a,
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  align-items: center;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-bottom-width: 0;
}
[class~="site-navigation"] ul > li[class~="level-0"] > a > span {
  display: inline-block;
}
#header-topbar [class~="email"]:before {
  height: 0.1875in;
}
[class~="form-login-register"] [class~="box-content"] > div {
  transition: all 0.5s ease;
}
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  font-size: inherit;
}
[class~="site-navigation"] ul {
  margin-bottom: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-bottom-style: none;
}
[class~="form-login-register"] [class~="box-content"] > div {
  -webkit-transform: translateX(-100%);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  ul[class~="sub-menu"] {
  left: 100%;
}
[class~="form-login-register"] [class~="box-content"] > div {
  transform: translateX(-100%);
}
#header-topbar [class~="email"]:before {
  top: calc(50% - 9px);
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  position: absolute;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  text-align: center;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span {
  display: inline;
}
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span {
  padding-bottom: 0.104166667in;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-image: none;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-image"] {
  display: table-cell;
}
#header-topbar [class~="topbar-right"] > * a,
#header-topbar,
.site-navigation ul div.sub-menu ul.menu-list li a:hover,
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a:hover,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"],
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  color: #000;
}
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  left: 0;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  position: relative;
}
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"],
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  background: #fff;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  font-size: 22px;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-left: 0;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-bottom: 20px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-image"] {
  width: 70px;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"] {
  vertical-align: top;
}
[class~="site-navigation"] ul,
[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-right: 0;
}
[class~="site-navigation"] ul,
[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-top: 0;
}
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"] {
  padding-left: 15pt;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  text-transform: uppercase;
}
[class~="form-login-register"] [class~="box-form-login"] h2 {
  font-weight: 600;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  right: 0;
}
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li:first-child
  ul[class~="sub-menu"] {
  top: -0.3125in;
}

/*========================================================================
4. Breadcrumbs
=========================================================================*/
[class~="site-main"] [class~="page-title"] [class~="content-title-heading"] {
  display: inline-flex;
}
[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  text-transform: capitalize;
}
[class~="site-main"] [class~="page-title"] {
  background-image: url("../images/site-header.jpg");
}
[class~="site-main"] [class~="page-title"] {
  padding-left: 0.9375pc;
}
[class~="site-main"] [class~="page-title"] {
  padding-bottom: 0.520833333in;
}
[class~="site-main"] [class~="page-title"] {
  padding-right: 0.15625in;
}
.site-main .page-title .breadcrumbs .delimiter:before,
[class~="site-main"] [class~="page-title"],
[class~="site-main"] [class~="page-title"] [class~="content-title-heading"],
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] a {
  position: relative;
}
[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  margin-top: 0;
}
[class~="site-main"] [class~="page-title"] [class~="content-title-heading"] {
  align-items: center;
}
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  font-size: 10.5pt;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  content: "";
}
[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  font-size: 3.125pc;
}
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  color: #000;
}
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  font-weight: 500;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  display: inline-block;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  height: 12px;
}
[class~="site-main"] [class~="page-title"],
[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  text-align: center;
}
[class~="site-main"] [class~="page-title"] {
  padding-top: 50px;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  width: 0.010416667in;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  top: 2px;
}
[class~="site-main"] [class~="page-title"] {
  margin-left: 0;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  background: #868686;
}
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-left: 0.104166667in;
}
[class~="site-main"] [class~="page-title"] {
  margin-bottom: 0.729166667in;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  -webkit-transform: rotate(25deg);
}
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-bottom: 0;
}
[class~="site-main"] [class~="page-title"] {
  margin-right: 0;
}
.site-main .page-title .breadcrumbs .delimiter:before {
  transform: rotate(25deg);
}
[class~="site-main"] [class~="page-title"],
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-top: 0;
}
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-right: 0.104166667in;
}
[class~="site-main"] [class~="page-title"] {
  font-size: 0.208333333in;
}
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] a,
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  color: #868686;
}
[class~="site-main"] [class~="page-title"] {
  background-position: top;
}

/*========================================================================
5. Block
=========================================================================*/
[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-left: 0;
}
[class~="block-sliders"] [class~="slick-dots"] li,
[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-bottom: 0;
}
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"]
  [class~="banners"]
  [class~="banner-image"]
  img,
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img,
.block-sliders .item-content .item-info {
  width: 100%;
}
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-bottom: 0;
}
.block-sliders.color-white .slick-dots li button {
  background: #fff;
}
.block-sliders.color-white .item-content .item-info .description-slider,
[class~="block-sliders"][class~="color-white"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
.block-sliders.color-white .item-content .item-info .subtitle-slider {
  color: #fff;
}
.block-sliders.auto-height .item-content .content-image img {
  height: auto;
}
[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-right: 0;
}
[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-top: 0;
}
[class~="block-product-cats"] ul li,
[class~="block-banners"]
  [class~="block-widget-banner"]
  [class~="banners"]
  [class~="banner-image"]
  a {
  display: block;
}
.block-sliders.auto-height .item-content .content-image img {
  min-height: auto;
}
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  height: 75pt;
}
[class~="block-sliders"] [class~="slick-dots"] li {
  margin-left: 0.9375pc;
}
[class~="block-sliders"] [class~="item-content"],
.block-banners.banners-effect .banners > div a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  position: relative;
}
[class~="block-banners"][class~="banners-effect"]
  [class~="banner-image"]:hover
  img {
  filter: alpha(opacity=80);
}
.block-sliders .slick-dots li button:after {
  content: "";
}
[class~="block-sliders"] [class~="slider-bg-image"] [class~="image-center"],
[class~="block-sliders"] [class~="slider-bg-image"],
.block-sliders .item-content .item-info,
.block-sliders .slick-dots li button:after {
  position: absolute;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="content"],
[class~="block-sliders"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="item-info"] {
  opacity: 1;
}
[class~="block-banners"][class~="layout-4"]
  [class~="row"]
  > div:nth-child(2)
  [class~="block-widget-banner"] {
  margin-top: 2.1875in;
}
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-right: 0;
}
[class~="block-banners"][class~="banners-effect"]
  [class~="banner-image"]:hover
  img {
  transform: scale3d(1.1, 1.1, 1);
}
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-top: 0;
}
.block-sliders .slick-dots li button:after {
  width: 16.5pt;
}
.block-sliders .item-content .item-info {
  opacity: 0;
}
.block-sliders .item-content .item-info {
  visibility: hidden;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 60pt;
}
.block-banners.banners-effect .banners > div a {
  display: inline-block;
}
.block-sliders .item-content .item-info {
  -webkit-transition: all 0.3s ease;
}
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  min-height: 100vh;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  margin-left: auto;
}
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  object-fit: cover;
}
.block-sliders .slick-dots li button:after {
  height: 16.5pt;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="content"],
[class~="block-sliders"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="item-info"] {
  visibility: visible;
}
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  -webkit-transform: translateZ(0);
}
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  transform: translateZ(0);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  content: "";
}
[class~="block-banners"][class~="layout-5"]
  [class~="row"]
  > div:nth-child(2)
  [class~="block-widget-banner"] {
  margin-top: 138.75pt;
}
[class~="block-sliders"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="item-info"] {
  margin-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  font-family: icomoon;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  font-size: 20px;
}
.block-sliders .item-content .item-info {
  transition: all 0.3s ease;
}
[class~="block-sliders"] [class~="slider-bg-image"] {
  top: 17%;
}
.block-sliders .slick-dots li button:after {
  top: calc(50% - 11px);
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li,
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  line-height: 60pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"]
  span {
  font-weight: 500;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 40px;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-top-color: #000;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-bottom: 11.25pt;
}
.block-sliders .slick-dots li button:after {
  left: calc(50% - 11px);
}
.block-sliders .item-content .item-info {
  margin-top: -6.25pc;
}
.block-sliders .item-content .item-info {
  padding-left: 0.078125in;
}
.block-sliders .item-content .item-info {
  padding-bottom: 0;
}
.block-sliders .item-content .item-info {
  padding-right: 0.078125in;
}
.block-banners.banners-effect .banners > div a {
  overflow: hidden;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="vertical-middle"],
[class~="block-sliders"] [class~="slider-bg-image"] [class~="image-center"] {
  top: 50%;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li,
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0;
}
[class~="block-sliders"] [class~="slider-bg-image"] [class~="image-center"],
[class~="block-sliders"] [class~="slider-bg-image"] {
  left: 50%;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="vertical-middle"] {
  -webkit-transform: translateY(-50%);
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"] [class~="slick-dots"] li {
  margin-top: 0;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="vertical-middle"] {
  transform: translateY(-50%);
}
.block-sliders .item-content .item-info {
  padding-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  right: 0;
}
.block-sliders .item-content .item-info {
  transition-delay: 0.6s;
}
.block-sliders .item-content .item-info.vertical-bottom {
  bottom: 0;
}
.block-sliders .item-content .item-info.horizontal-start {
  justify-content: flex-start;
}
.block-sliders .item-content .item-info {
  display: flex;
}
.block-sliders .slick-dots li button:after {
  border-left-width: 1.5pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  margin-bottom: auto;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"]
  span {
  border-bottom-width: 0.010416667in;
}
.block-sliders.layout-2 .slick-current .item-content .content {
  text-align: center;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"]
  span {
  border-bottom-style: solid;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="horizontal-center"] {
  justify-content: center;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  top: 0.03125in;
}
[class~="block-sliders"] [class~="slick-dots"] li {
  margin-right: 0.9375pc;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="horizontal-end"] {
  justify-content: flex-end;
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 60px;
}
.block-sliders .item-content .item-info .title-slider,
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
.block-sliders .item-content .item-info .title-slider {
  font-size: 0.833333333in;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-top: 11.25pt;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  background: #ddd;
}
[class~="block-sliders"] [class~="slider-bg-image"] {
  transform: translateX(-50%);
}
[class~="block-sliders"] [class~="slider-bg-image"] [class~="image-center"] {
  transform: translate(-50%, -50%);
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  line-height: 4.375pc;
}
[class~="block-sliders"] [class~="slick-dots"] {
  padding-left: 0pt;
}
[class~="block-sliders"] [class~="slider-bg-image"] [class~="image-center"] {
  z-index: 1;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]
  [class~="button"]:after {
  padding-left: 7.5pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"]
  span {
  border-bottom-color: #000;
}
.block-sliders .item-content .item-info .title-slider,
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: -5px;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  padding-left: 0;
}
[class~="block-sliders"] [class~="slick-dots"] {
  padding-bottom: 45pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  margin-right: auto;
}
[class~="block-product-cats"] ul li a {
  color: #868686;
}
[class~="block-products"] [class~="btn-all"] a {
  padding-left: 30pt;
}
.block-sliders .slick-dots li button:after {
  border-bottom-width: 1.5pt;
}
[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-content"]
  [class~="product-title"] {
  font-size: 16px;
}
[class~="block-products"][class~="slider"]
  [class~="content-product-list"]
  i[class~="slick-arrow"] {
  font-size: 40px;
}
[class~="block-products"][class~="slider"]
  [class~="content-product-list"]
  i[class~="slick-arrow"] {
  top: 40%;
}
.block-products .nav-tabs .nav-item:last-child:before,
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]:before {
  content: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"]
  span {
  border-image: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-sliders"] [class~="slick-dots"],
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  position: absolute;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"][class~="animation-top"]
  [class~="content"] {
  -webkit-transition: all 0.3s ease;
}
[class~="block-banners"][class~="layout-4"] [class~="row"] > div {
  padding-left: 1.875pc;
}
[class~="block-banners"][class~="layout-4"] [class~="row"] > div,
[class~="block-products"] [class~="btn-all"] a,
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  padding-bottom: 0;
}
[class~="block-products"] [class~="btn-all"] a {
  padding-right: 30pt;
}
.block-banners.banners-effect .banners > div a {
  vertical-align: top;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  padding-right: 0;
}
[class~="block-products"] [class~="btn-all"] a,
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  padding-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  content: "";
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after {
  background: #000;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  top: calc(50% - 65px);
}
.block-sliders .item-content .item-info .title-slider {
  margin-bottom: 0.104166667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after {
  width: 46.5pt;
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 11.25pt;
}
[class~="block-sliders"] [class~="slick-dots"] {
  padding-right: 90px;
}
[class~="block-sliders"] [class~="slick-dots"] {
  padding-top: 0pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after {
  height: 0.0625pc;
}
.block-sliders .slick-dots li button:after {
  border-right-width: 1.5pt;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  flex-flow: column;
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
.block-sliders .item-content .item-info .title-slider,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0px;
}
[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  backface-visibility: hidden;
}
[class~="block-sliders"] [class~="slick-dots"] {
  text-align: right;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  margin-top: auto;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  display: inline-flex;
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
.block-sliders .item-content .item-info .title-slider {
  margin-top: 0pt;
}
.block-sliders .item-content .item-info .subtitle-slider {
  color: #000;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  width: 0.072916667in;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after,
[class~="block-sliders"] [class~="slick-dots"] {
  bottom: 0;
}
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="description-slider"] {
  margin-bottom: 2.1875pc;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 0.416666667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"]:after {
  left: 0;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  right: 5.3125pc;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
[class~="block-sliders"] [class~="slick-dots"] {
  z-index: 99;
}
[class~="block-banners"] [class~="block-widget-banner"] {
  position: relative;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  height: 5.25pt;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  height: 0.03125in;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  left: auto;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"][class~="animation-top"]
  [class~="content"],
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transition: all 0.3s ease;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  left: 50%;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  border-radius: 50%;
}
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  width: auto;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: -2px;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .button:before,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="button"]:before {
  background: #fff;
}
[class~="block-banners"][class~="background-img"] {
  /* background-image: url("../images/banner/banner-bg.jpg"); */
}
.block-sliders .item-content .item-info .subtitle-slider {
  font-size: 1.125pc;
}
[class~="block-banners"][class~="layout-4"] [class~="row"] > div {
  padding-right: 1.875pc;
}
[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  filter: alpha(opacity=100);
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  width: 100% !important;
}
.block-sliders .item-content .item-info .subtitle-slider {
  font-weight: 500;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  -webkit-transform: translateX(-50%);
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  content: "";
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  width: 0;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .button:before {
  height: 0.020833333in;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"][class~="animation-top"]
  [class~="content"] {
  transition-delay: 0.6s;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 7px;
}
[class~="block-sliders"][class~="nav-center"] [class~="slick-dots"] {
  padding-left: 0pc;
}
[class~="block-sliders"][class~="nav-center"] [class~="slick-dots"] {
  padding-bottom: 3.75pc;
}
[class~="block-banners"][class~="layout-4"] [class~="row"] > div,
[class~="block-banners"][class~="layout-5"] [class~="row"] > div {
  padding-top: 0;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transform: translateX(-50%);
}
.block-sliders .item-content .item-info .subtitle-slider {
  letter-spacing: 0.1875pc;
}
.block-sliders .item-content .item-info .subtitle-slider {
  margin-bottom: 0.052083333in;
}
[class~="block-sliders"][class~="nav-center"] [class~="slick-dots"] {
  padding-right: 0in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"]
  svg {
  width: 1em;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  height: 0.75pt;
}
[class~="block-banners"][class~="layout-5"] [class~="row"] > div,
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-left: 30pt;
}
[class~="block-sliders"][class~="nav-center"] [class~="slick-dots"] {
  padding-top: 0pt;
}
[class~="block-banners"][class~="layout-5"] [class~="row"] > div {
  padding-bottom: 0;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
.block-banners.slider .banner-image img,
[class~="block-products"] [class~="btn-all"] a {
  -webkit-transition: all 0.3s ease;
}
[class~="block-banners"][class~="layout-5"] [class~="row"] > div {
  padding-right: 30pt;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"][class~="animation-top"]
  [class~="content"] {
  -webkit-transform: translateY(-100px);
}
.block-banners.slider .banner-image img {
  width: 100%;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  -webkit-border-radius: 50%;
}
.block-sliders.layout-5 .item:nth-child(3n + 1) .item-info .content {
  background: #f2ede0;
}
[class~="block-product-cats"] ul li a {
  font-weight: 400;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"]
  svg {
  height: 1em;
}
[class~="block-products"] [class~="btn-all"],
[class~="block-sliders"][class~="nav-center"] [class~="slick-dots"] {
  text-align: center;
}
[class~="block-banners"][class~="background-img"] {
  background-position: center left;
}
.block-sliders .slick-dots li button:after {
  border-top-width: 1.5pt;
}
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-left: 7.8125pc;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"]:hover,
.block-sliders .item-content .item-info .description-slider {
  color: #000;
}
.block-sliders .item-content .item-info .description-slider {
  font-size: 15pt;
}
[class~="block-banners"][class~="background-img"] {
  background-repeat: no-repeat;
}
.block-sliders .item-content .item-info .description-slider {
  margin-bottom: 22.5pt;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0pt;
}
[class~="block-banners"][class~="background-img"] {
  background-size: cover;
}
.block-sliders .slick-dots li button:after {
  border-left-style: solid;
}
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-bottom: 0.729166667in;
}
[class~="block-products"] [class~="btn-all"] a,
.block-banners.slider .banner-image img {
  transition: all 0.3s ease;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="content"] {
  opacity: 0;
}
.block-products .nav-tabs .nav-item:before {
  position: absolute;
}
.block-sliders .slick-dots li button:after {
  border-bottom-style: solid;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  -moz-border-radius: 50%;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  background: #000;
}
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-radius: 50% 50% 50% 50%;
}
.block-products .nav-tabs .nav-item:before {
  content: "";
}
.block-products ul.products-list,
.block-product-cats ul {
  padding-left: 0;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"][class~="animation-top"]
  [class~="content"] {
  transform: translateY(-100px);
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"]
  svg,
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  a,
[class~="block-sliders"] {
  position: relative;
}
[class~="block-products"] [class~="nav-tabs"] {
  margin-bottom: 2.5pc;
}
[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-width: medium;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  -ms-border-radius: 50%;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-bottom: 0.052083333in;
}
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-right: 0px;
}
[class~="block-products"] [class~="nav-tabs"],
[class~="block-post-cats"] ul li,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"]
  svg,
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  display: block;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="content"] {
  visibility: hidden;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  font-size: 11.25pt;
}
[class~="block-product-cats"] ul li {
  margin-bottom: 7.5pt;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-left: 0;
}
.block-products ul.products-list,
.block-product-cats ul {
  list-style-type: none;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  overflow: hidden;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-bottom: 3px;
}
.block-products .nav-tabs .nav-item:before {
  right: 0;
}
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"],
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-top: 0px;
}
.block-products .nav-tabs .nav-item:before {
  top: 0;
}
[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-style: none;
}
.block-products .nav-tabs .nav-item:before {
  background: #e1e1e1;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  -o-border-radius: 50%;
}
[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-color: currentColor;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-bottom: 0pc;
}
[class~="block-products"] [class~="nav-tabs"] {
  border-image: none;
}
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-right: 0.416666667in;
}
.block-products .nav-tabs .nav-item:before {
  width: 0.0625pc;
}
.block-sliders .slick-dots li button:after {
  border-right-style: solid;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-right: 0;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  vertical-align: top;
}
.block-products .nav-tabs .nav-item:before {
  height: 100%;
}
[class~="block-product-cats"] ul li a {
  display: flex;
}
.block-products .nav-tabs .nav-item:before {
  -webkit-transform: rotate(45deg);
}
.block-products .nav-tabs .nav-item:before {
  transform: rotate(45deg);
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 14.5%;
}
[class~="block-map"] iframe {
  height: 165pt;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"]:hover,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  background-color: transparent;
}
[class~="block-product-cats"] ul li a {
  justify-content: space-between;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-left-width: 0.0625pc;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 0%;
}
.block-sliders.layout-5 .item:nth-child(odd) .item-info .content {
  background: #e8e4e4;
}
[class~="block-map"] iframe {
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  background: #000;
}
.block-sliders .slick-dots li button:after {
  border-top-style: solid;
}
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  text-align: left;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-top: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  top: 50%;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0%;
}
.block-sliders .slick-dots li button:after {
  border-left-color: transparent;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-bottom-width: 0.0625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"]
  svg {
  fill: #fff;
}
[class~="block-products"] [class~="nav-tabs"],
[class~="block-product-cats"] [class~="item-product-cat-content"] {
  text-align: center;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-right-width: 0.0625pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  bottom: 0;
}
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-top: 0%;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 3.4375pc;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 37.5pt;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  img,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]:hover
  [class~="nav-link"]:before,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]:hover
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"][class~="active"]:before {
  width: 100%;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  backface-visibility: hidden;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 3.4375pc;
}
.block-sliders.layout-4 .slick-dots,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-top: 0pc;
}
[class~="block-banners"][class~="slider"]
  [class~="item"]:hover
  [class~="banner-image"]
  img {
  filter: brightness(0.8);
}
.block-sliders.layout-4 .slick-dots {
  padding-left: 1.25pc;
}
.block-sliders.layout-4 .slick-dots {
  padding-bottom: 1.875pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-top-width: 0.0625pc;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  filter: alpha(opacity=100);
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  width: 50%;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  img,
[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-feature .box {
  padding-left: 0in;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  font-weight: 400;
}
.block-sliders.layout-4 .slick-dots {
  padding-right: 0.208333333in;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 1.8;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-left-style: solid;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"] [class~="slick-dots"] li button {
  padding-left: 0;
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  height: 0.125pc;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"] [class~="slick-dots"] li button {
  padding-bottom: 0;
}
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  letter-spacing: normal;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-bottom-style: solid;
}
[class~="block-posts"] ul[class~="posts-list"],
.block-products ul.products-list {
  padding-top: 0.3125pc;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-image"]
  img,
[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  font-size: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"],
.block-sliders.layout-4 .slick-dots {
  position: absolute;
}
.block-sliders .slick-dots li button:after {
  border-bottom-color: transparent;
}
[class~="block-posts"] ul[class~="posts-list"] {
  padding-left: 0;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-right-style: solid;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0.3125pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-top-style: solid;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"] {
  background: #e5e8e4;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  -webkit-transform: translateY(-50%);
}
.block-feature .box {
  padding-bottom: 26.25pt;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-left-color: #e6e6e6;
}
.block-sliders .slick-dots li button:after {
  border-right-color: transparent;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-title"]
  h3 {
  font-size: 18px;
}
[class~="block-sliders"] [class~="slick-dots"] li button {
  transition: all 0.2s ease;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="row"]
  > div:nth-child(even)
  [class~="cat-item"] {
  margin-top: 7.5pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button,
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-top: 0;
}
[class~="block-posts"] ul[class~="posts-list"] {
  list-style-type: none;
}
[class~="block-post-cats"] ul li {
  margin-bottom: 10px;
}
[class~="block-map"] iframe {
  border-left-width: medium;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  display: block;
}
[class~="block-map"] iframe {
  border-bottom-width: medium;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  position: relative;
}
.block-products ul.products-list li:last-child {
  padding-bottom: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"] {
  height: 100%;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  opacity: 1;
}
.block-lookbook .item-lookbook:after {
  width: 2.5pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-bottom-color: #e6e6e6;
}
.block-feature .box {
  padding-right: 0in;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  overflow: hidden;
}
[class~="block-map"] iframe {
  border-right-width: medium;
}
.block-sliders.layout-4 .slick-dots {
  bottom: 0;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img,
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  filter: alpha(opacity=80);
}
.block-products ul.products-list li:last-child {
  margin-bottom: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  visibility: visible;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img,
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  transform: scale3d(1.1, 1.1, 1);
}
.block-feature .box {
  padding-top: 0.3125in;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 0.625in;
}
[class~="block-sliders"] [class~="slick-dots"] li button,
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="content-image"] {
  padding-right: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  -webkit-transition: none;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-image"]
  a {
  vertical-align: top;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
  width: 100%;
}
.block-lookbook .item-lookbook:after {
  height: 0.416666667in;
}
.block-sliders.layout-4 .slick-dots {
  z-index: 10;
}
.block-sliders .slick-dots li button:after {
  border-top-color: transparent;
}
[class~="block-sliders"][class~="layout-9"] [class~="item-content"],
[class~="block-sliders"][class~="layout-5"] [class~="item-content"],
[class~="block-products"] ul[class~="products-list"] li {
  display: flex;
}
.block-sliders .slick-dots li button:after {
  border-image: none;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  transition: none;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-product-cats"][class~="layout-2"][class~="items-equal"]
  [class~="row"]
  > div:nth-child(even)
  [class~="cat-item"] {
  margin-top: 0;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
  backface-visibility: hidden;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  line-height: 70px;
}
.block-feature .box {
  border-left-width: 0.0625pc;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 2.1875pc;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  transition-delay: initial;
}
.block-lookbook .item-lookbook:after {
  position: absolute;
}
.block-lookbook .item-lookbook:after {
  content: "";
}
.block-feature .box {
  border-bottom-width: 0.0625pc;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
  filter: alpha(opacity=100);
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="content-image"] {
  width: 50%;
}
.block-banners.layout-4 .row {
  margin-left: -0.3125in;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-right-color: #e6e6e6;
}
[class~="block-banners"][class~="layout-5"] [class~="row"],
.block-banners.layout-4 .row {
  margin-bottom: 0;
}
[class~="block-lookbook"],
[class~="block-sliders"] [class~="slick-dots"] li button {
  position: relative;
}
.block-banners.layout-4 .row {
  margin-right: -0.3125in;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="content-image"] {
  padding-left: 0.3125pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-top-color: #e6e6e6;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  border-image: none;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="content-image"],
[class~="block-product-cats"][class~="layout-2"] [class~="row"] > div {
  padding-bottom: 0;
}
.block-products ul.products-list li:last-child {
  border-bottom-width: 0;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  left: 50%;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0in;
}
.block-sliders .slick-dots li button:after {
  border-radius: 50%;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
  -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
}
.block-sliders .slick-dots li button:after {
  -webkit-border-radius: 50%;
}
[class~="block-banners"][class~="layout-5"] [class~="row"],
.block-banners.layout-4 .row {
  margin-top: 0;
}
[class~="block-banners"][class~="layout-5"] [class~="row"] {
  margin-left: -2.5pc;
}
.block-product-cats.layout-3 .cat-item .cat-image img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  color: #fff;
}
.block-lookbook .item-lookbook:after {
  left: calc(50% - 17.5px);
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="content-image"],
[class~="block-product-cats"][class~="layout-2"] [class~="row"] > div {
  padding-top: 0;
}
.block-feature .box {
  border-right-width: 0.0625pc;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0pt;
}
.block-product-cats.layout-3 .cat-item .cat-title h3 {
  font-size: 18pt;
}
.block-product-cats.layout-3 .cat-item .cat-title h3 {
  margin-bottom: 0in;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 34.5pt;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  display: block;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]
  img {
  width: 100%;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 0.15625in;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  font-size: 10.5pt;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  -webkit-transform: translateX(-50%);
}
.block-product-cats.layout-3 .cat-item .cat-title h3 {
  margin-top: 0;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 8.4375pc;
}
.block-feature .box {
  border-top-width: 0.0625pc;
}
[class~="block-banners"][class~="layout-5"] [class~="row"] {
  margin-right: -2.5pc;
}
[class~="block-product-cats"] ul li a {
  align-items: center;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 4.375pc;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0.15625in;
}
[class~="block-products"] ul[class~="products-list"] li {
  padding-bottom: 1.125pc;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-right-color: #000;
}
.block-sliders .slick-dots li button:after {
  -moz-border-radius: 50%;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-top: 0in;
}
.block-sliders.layout-7 .item-content .item-info {
  padding-left: 0%;
}
.block-products ul.products-list li:last-child {
  border-bottom-style: none;
}
.block-feature .box {
  border-left-style: solid;
}
.block-banners.slider .slick-sliders {
  overflow: hidden;
}
.block-sliders.layout-7 .item-content .item-info {
  padding-bottom: 3%;
}
.block-lookbook .item-lookbook:after {
  top: calc(50% - 17.5px);
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  margin-left: -18.75pt;
}
[class~="block-product-cats"][class~="layout-2"] [class~="row"] > div,
[class~="block-product-cats"][class~="layout-3"] [class~="row"] > div {
  padding-left: 0.3125in;
}
.block-sliders.layout-7 .item-content .item-info {
  padding-right: 21.5%;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"],
.block-sliders.layout-7 .item-content .item-info {
  padding-top: 0%;
}
[class~="block-product-cats"][class~="layout-2"] [class~="row"] > div {
  padding-right: 0.3125in;
}
.block-lookbook .item-lookbook:after {
  border-left-width: 0.010416667in;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  line-height: 0.4375in;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  margin-bottom: 0;
}
.block-lookbook .item-lookbook:after {
  border-bottom-width: 0.010416667in;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  font-size: 0.8125pc;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 15%;
}
.block-feature .box {
  border-bottom-style: solid;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 5%;
}
.block-feature .box {
  border-right-style: solid;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0%;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  font-weight: 500;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  margin-right: -18.75pt;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transform: translateX(-50%);
}
.block-feature .box {
  border-top-style: solid;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  transform: translateY(-50%);
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a,
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  letter-spacing: 0.020833333in;
}
[class~="block-map"] iframe {
  border-top-width: medium;
}
.block-lookbook .item-lookbook:after {
  border-right-width: 0.010416667in;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  margin-top: 0;
}
[class~="block-sliders"][class~="layout-5"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  padding-left: 93.75pt;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  padding-left: 21.5% !important;
}
.block-feature .box {
  border-left-color: #e4e4e4;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  padding-bottom: 0 !important;
}
[class~="block-post-cats"] ul li a {
  color: #868686;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 0.260416667in;
}
.block-sliders .slick-dots li button:after {
  -ms-border-radius: 50%;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  width: 50%;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  background-color: #000;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a,
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 0;
}
.block-lookbook .item-lookbook:after {
  border-top-width: 0.010416667in;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="content-image"],
[class~="block-banners"][class~="slider"] [class~="item"],
[class~="block-product-cats"][class~="layout-3"] [class~="row"] > div {
  padding-bottom: 0;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  padding-right: 21.5% !important;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]:hover
  a {
  background-color: transparent;
}
[class~="block-banners"][class~="slider"] [class~="slick-list"] {
  padding-top: 0 !important;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  padding-right: 0;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0px;
}
[class~="block-post-cats"] ul li a {
  font-weight: 400;
}
[class~="block-product-cats"][class~="layout-3"] [class~="row"] > div,
[class~="block-banners"][class~="slider"] [class~="item"],
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  padding-top: 0;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  position: relative;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  display: block;
}
[class~="block-banners"][class~="slider"] [class~="item"] {
  padding-left: 18.75pt;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  fill: #000;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  -webkit-transition: all 0.3s ease;
}
[class~="block-products"] ul[class~="products-list"] li {
  margin-bottom: 0.1875in;
}
.block-lookbook .item-lookbook:after {
  border-left-style: solid;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0pt;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transition: all 0.3s ease;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  color: #000;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-left-width: 0.0625pc;
}
[class~="block-product-cats"][class~="layout-3"] [class~="row"] > div {
  padding-right: 1.875pc;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  opacity: 1;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  visibility: visible;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  -webkit-transition: none;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before,
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  position: absolute;
}
.block-feature .box {
  border-bottom-color: #e4e4e4;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  padding-bottom: 0.5pc;
}
[class~="block-sliders"][class~="layout-6"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  margin-bottom: 0;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  font-size: 4.375pc;
}
[class~="block-map"] iframe {
  border-left-style: none;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  font-weight: 300;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  transition: none;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before,
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  content: "";
}
.block-products ul.products-list li:last-child {
  border-bottom-color: currentColor;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  background: rgba(0, 0, 0, 0.3);
}
[class~="block-banners"][class~="slider"] [class~="item"] {
  padding-right: 18.75pt;
}
[class~="block-products"] ul[class~="products-list"] li,
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-bottom-width: 0.0625pc;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  margin-top: 0;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"][class~="background-1"] {
  background: #efeae7;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  margin-left: -0.052083333in;
}
.block-sliders .slick-dots li button:after {
  -o-border-radius: 50%;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"] {
  transition-delay: initial;
}
[class~="block-map"] iframe {
  border-bottom-style: none;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  font-family: wpbingofont;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="content-image"] {
  width: 50%;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="content-image"] {
  padding-left: 0;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="content-image"] {
  padding-right: 0;
}
.block-feature .box {
  border-right-color: #e4e4e4;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  width: calc(100% - 30px);
}
.block-feature .box {
  border-top-color: #e4e4e4;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a,
[class~="block-testimonial"] {
  position: relative;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a {
  text-transform: uppercase;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"][class~="background-2"] {
  background: #e7efe7;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  width: 34.5pt;
}
[class~="block-map"] iframe {
  border-right-style: none;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  height: 34.5pt;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  height: calc(100% - 30px);
}
[class~="block-map"] iframe {
  border-top-style: none;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="content-image"] {
  padding-top: 0;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  line-height: 34.5pt;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  font-size: 0.9375pc;
}
[class~="block-sliders"][class~="layout-9"][class~="nav-left"]
  [class~="slick-dots"] {
  padding-left: 250px;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-right-width: 0.0625pc;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  margin-bottom: 0.4375pc;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  margin-right: 0pc;
}
[class~="block-products"] ul[class~="products-list"] li,
.block-lookbook .item-lookbook:after {
  border-bottom-style: solid;
}
[class~="block-sliders"][class~="layout-9"][class~="nav-left"]
  [class~="slick-dots"] {
  padding-bottom: 70px;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  top: 15px;
}
[class~="block-sliders"][class~="layout-9"][class~="nav-left"]
  [class~="slick-dots"] {
  padding-right: 0pc;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  left: 15px;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-left-width: medium;
}
[class~="block-intro"],
[class~="block-sliders"][class~="layout-8"]
  [class~="slick-current"]
  [class~="item-content"]
  [class~="content"] {
  text-align: center;
}
.block-sliders.layout-7 .item-content .item-info .title-slider {
  margin-top: 0pc;
}
.block-lookbook .item-lookbook:after {
  border-right-style: solid;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-bottom-width: medium;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  bottom: 0;
}
[class~="block-sliders"][class~="layout-9"][class~="nav-left"]
  [class~="slick-dots"] {
  padding-top: 0in;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-left-width: 0;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-bottom-width: 0;
}
[class~="block-banners"][class~="layout-1"]
  [class~="section-column"][class~="left"] {
  width: 41.1%;
}
[class~="block-banners"][class~="layout-1"]
  [class~="section-column"][class~="right"],
[class~="block-banners"][class~="layout-2"]
  [class~="section-column"][class~="left"] {
  width: 58.9%;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  opacity: 0;
}
.block-products ul.products-list li:last-child {
  border-image: none;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-bottom-color: #000;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-right-width: 0;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-size: 16px;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  letter-spacing: 0.020833333in;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  visibility: hidden;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  -webkit-transition: all 0.3s ease;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-right-width: medium;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-top-width: 0;
}
[class~="block-post-archives"] ul li,
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  margin-bottom: 5px;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-left-style: none;
}
[class~="block-banners"][class~="layout-2"]
  [class~="section-column"][class~="right"] {
  width: 41.1%;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
[class~="block-map"] iframe {
  border-left-color: currentColor;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-top-width: 0.0625pc;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-bottom-style: none;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  justify-content: center;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  transition: all 0.3s ease;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 70px;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor,
[class~="block-post-cats"] ul li a {
  display: flex;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img,
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-right-style: none;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  top: 50%;
}
[class~="block-map"] iframe,
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  border-bottom-color: currentColor;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-top-width: medium;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
.block-sliders .slick-dots li button:after {
  font-family: slick;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  z-index: 2;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"]:before {
  display: inline-block;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
.block-lookbook .item-lookbook:after {
  border-top-style: solid;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-left-style: solid;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 1.875pc;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-bottom-style: solid;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  transform: translateY(-50%);
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"] a {
  display: block;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-top-style: none;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  bottom: auto;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"] a,
[class~="block-product-cats"][class~="layout-7"] [class~="cat-image"] a {
  position: relative;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]:hover
  a {
  color: #000;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-right-style: solid;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  position: absolute;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0;
}
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"] a,
[class~="block-product-cats"][class~="layout-7"] [class~="cat-image"] a {
  overflow: hidden;
}
.block-lookbook .item-lookbook:after {
  border-left-color: #fcfbfe;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-left-color: #000;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
[class~="block-map"] iframe,
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-right-color: currentColor;
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] > div {
  padding-left: 35.625pt;
}
[class~="block-sliders"][class~="layout-8"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-thumb"]
  img {
  width: 100%;
}
.block-feature .box {
  border-image: none;
}
.block-banners .block-widget-banner.layout-1 .banner-wrapper-infor {
  left: 0;
}
.block-banners .block-widget-banner.layout-1 .button {
  font-size: 20px;
}
[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-image"] {
  flex: 0 0 60pt;
}
.block-banners .block-widget-banner.layout-1 .button {
  line-height: 78.75pt;
}
.block-sliders.layout-9.diff-col .item-content .item-info {
  width: 34.5%;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"],
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  background: #fff;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"] {
  height: 100%;
}
.block-sliders .slick-dots li button:after {
  font-size: 4.5pt;
}
.block-feature .box {
  text-align: center;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 41.25pt;
}
.block-banners .block-widget-banner.layout-1 .button {
  padding-left: 15px;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-left-color: currentColor;
}
[class~="block-map"] iframe,
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-top-color: currentColor;
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] > div,
[class~="block-posts"] [class~="posts-list"] {
  padding-bottom: 0;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-bottom-color: #000;
}
[class~="block-posts"] [class~="posts-list"] {
  padding-left: 0;
}
.block-lookbook .item-lookbook:after {
  border-bottom-color: #fcfbfe;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-bottom-color: currentColor;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"],
[class~="block-map"] iframe {
  border-image: none;
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] > div {
  padding-right: 35.625pt;
}
.block-products ul.products-list li .product-content {
  padding-left: 0.9375pc;
}
[class~="block-posts"] [class~="posts-list"],
[class~="block-product-cats"][class~="layout-7"] [class~="row"] > div {
  padding-top: 0;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  line-height: 45pt;
}
[class~="block-product-cats"][class~="layout-7"] [class~="cat-image"] a {
  display: block;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"]
  [class~="post-categories"]
  a {
  color: #fff;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0;
}
.block-lookbook .item-lookbook:after {
  border-right-color: #fcfbfe;
}
.block-banners .block-widget-banner.layout-1 .button {
  padding-bottom: 0in;
}
.block-banners .block-widget-banner.layout-1 .button {
  padding-right: 15px;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-right-color: #000;
}
.block-lookbook .item-lookbook:after {
  border-top-color: #fcfbfe;
}
.block-banners .block-widget-banner.layout-1 .button {
  padding-top: 0in;
}
.block-banners .block-widget-banner.layout-1 .button {
  opacity: 0;
}
[class~="block-products"] ul[class~="products-list"] li {
  border-bottom-color: #e1e1e1;
}
.block-banners .block-widget-banner.layout-1 .button {
  visibility: hidden;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  border-top-color: #000;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
.block-product-cats.layout-5 .item-product-cat-content .item-image img {
  border-image: none;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child {
  padding-bottom: 0;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  top: 60%;
}
.block-post-tags ul li,
.block-feature .box .box-icon span {
  display: inline-block;
}
.block-sliders .slick-dots li button:after {
  line-height: 0.208333333in;
}
.block-feature .box .box-icon span {
  line-height: 1;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  -webkit-transform: none;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 1.25pc;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0px;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0pc;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="description-slider"] {
  margin-bottom: 22.5pt;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before,
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  left: 0;
}
[class~="block-posts"] [class~="posts-list"] {
  padding-right: 0;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  transform: none;
}
.block-sliders.layout-9 .item-content .item-info .button-slider {
  line-height: 34.5pt;
}
[class~="block-sliders"][class~="layout-9"][class~="diff-col"]
  [class~="item-content"]
  [class~="content-image"] {
  width: 65.5%;
}
.block-post-tags ul li {
  font-size: 0.875pc;
}
.block-banners .block-widget-banner.layout-1 .button {
  -webkit-transition: all 0.3s ease;
}
[class~="block-banners"][class~="slider"] [class~="slick-arrow"] {
  margin-top: 0;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  width: 2.1875pc;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-left: 15px;
}
[class~="block-intro"] {
  max-width: 9.375in;
}
.block-banners .block-widget-banner.layout-1 .button {
  transition: all 0.3s ease;
}
[class~="block-sliders"][class~="layout-9"][class~="diff-col"][class~="nav-left"]
  [class~="slick-dots"] {
  padding-left: 52.5pt;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  height: 26.25pt;
}
[class~="block-posts"] [class~="posts-list"] {
  list-style: none;
}
.block-feature .box .box-icon span {
  -webkit-transition: all 0.3s;
}
.block-banners .block-widget-banner.layout-1 .button {
  min-width: 1.958333333in;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  transition: border-top-color 2s linear, border-right-color 2s linear 1s,
    border-bottom-color 2s linear 3s;
}
.block-sliders .slick-dots li button:after,
.block-banners .block-widget-banner.layout-1 .button,
.block-feature .box .box-icon span {
  text-align: center;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-size: 1.25pc;
}
.block-banners.slider .slick-wrap {
  position: static;
}
.block-banners .block-widget-banner.layout-1 .button {
  font-weight: 400;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-weight: 300;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  border-radius: 50%;
}
[class~="block-products"][class~="slider"] [class~="content-product-list"] {
  position: relative;
}
.block-banners .block-widget-banner.layout-1 .button {
  letter-spacing: normal;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"]
  [class~="post-title"]
  a {
  color: #fff;
}
.block-products .nav-tabs.align-left {
  text-align: left;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  color: #000;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  letter-spacing: 2.25pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}
.block-posts .post-item,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  -webkit-border-radius: 50%;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}
.block-lookbook .item-lookbook:after,
[class~="block-products"] ul[class~="products-list"] li {
  border-image: none;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  margin-bottom: 0.104166667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}
.block-feature .box .box-icon span {
  -o-transition: all 0.3s;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child {
  margin-bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  top: auto;
}
[class~="block-intro"] {
  margin-left: auto;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  text-transform: uppercase;
}
[class~="block-map"] iframe {
  max-width: 100%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  position: absolute;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 52.5pt;
}
.block-feature .box .box-icon span {
  transition: all 0.3s;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"][class~="background-3"] {
  background: #efe7ee;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  -webkit-border-radius: 0.208333333in;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  display: inline-block;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  content: none;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  padding-left: 0.260416667in;
}
.block-feature .box .box-icon span {
  color: #818a91;
}
.block-feature .box .box-icon span {
  font-size: 50px;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-bottom: 0pc;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  width: 100%;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-left: 0in;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-right: 15px;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  background: #000;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 26.25pt;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-right: 0pt;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  top: 50%;
}
[class~="block-sliders"][class~="layout-12"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  padding-left: 0.520833333in;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  -moz-border-radius: 50%;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] svg {
  width: 1em;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  padding-bottom: 0;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child {
  border-bottom-width: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 0.625in;
}
[class~="block-product-cats"] ul li [class~="count"] {
  width: 18.75pt;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] svg {
  height: 1em;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-top-color: #fff;
}
.block-sliders .slick-dots li button:after {
  color: #000;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  padding-right: 0.260416667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"] {
  padding-right: 50px;
}
.block-posts .post-item {
  margin-bottom: 0.208333333in;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child {
  border-bottom-style: none;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  padding-top: 0;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-bottom: 0;
}
[class~="block-product-cats"] ul li [class~="count"],
.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"] {
  margin-left: 0;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 0.677083333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  justify-content: center;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  -ms-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-right: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 300;
}
[class~="block-product-cats"][class~="slider"] [class~="product-cats-list"],
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"],
[class~="block-feature"] [class~="box"] [class~="box-icon"] svg {
  position: relative;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  margin-top: 0;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  -o-border-radius: 50%;
}
.block-lookbook .item-lookbook:after {
  -webkit-border-radius: 50%;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 11.25pt;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child {
  border-bottom-color: currentColor;
}
.block-sliders .slick-dots li button:after {
  opacity: 0.25;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  top: auto;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  background: #fff;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] svg {
  display: block;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  position: absolute;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"],
[class~="block-lookbook"] [class~="lookbook-content"] [class~="item"] > img,
.block-product-cats.layout-6 .cat-item .cat-image img,
[class~="block-map"] iframe {
  width: 100%;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  font-size: 0.6875pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
[class~="block-product-cats"] ul li [class~="count"] {
  height: 25px;
}
.block-sliders .slick-dots li button:after {
  -webkit-font-smoothing: antialiased;
}
[class~="block-feature"][class~="layout-4"] [class~="row"] > div {
  border-right-width: 0.0625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  padding-left: 0.15625in;
}
[class~="block-feature"][class~="layout-4"] [class~="row"] > div {
  border-right-style: solid;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"] {
  margin-top: 0in;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  font-size: 15pt;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  font-size: 9.75pt;
}
[class~="block-lookbook"] [class~="background-overlay"] {
  background-color: #e8dad0;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"] {
  margin-left: 0pc;
}
[class~="block-feature"][class~="layout-4"] [class~="row"] > div {
  border-right-color: #dedede;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"] {
  margin-bottom: 1.0625pc;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"] {
  top: 40%;
}
[class~="block-posts"] ul[class~="posts-list"] li:last-child,
[class~="block-feature"][class~="layout-4"] [class~="row"] > div {
  border-image: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 85px;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"] {
  margin-right: 0px;
}
.block-posts .post-item .post-image img {
  max-width: 90px;
}
.block-lookbook .item-lookbook:after {
  -moz-border-radius: 50%;
}
.block-product-cats.layout-6 .cat-item .cat-image img {
  backface-visibility: hidden;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  font-weight: 500;
}
[class~="block-post-cats"] ul li a {
  justify-content: space-between;
}
.block-sliders .slick-dots li button:after {
  -moz-osx-font-smoothing: grayscale;
}
[class~="block-product-cats"] ul li [class~="count"] {
  font-weight: 400;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  padding-right: 15px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  height: 1px;
}
[class~="block-product-cats"] ul li [class~="count"] {
  -webkit-border-radius: 50%;
}
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-left: 31.875pt;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  margin-bottom: 0.3125pc;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  text-transform: uppercase;
}
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-bottom: 0;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  letter-spacing: 2px;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  content: "";
}
.block-product-cats.layout-6 .cat-item .cat-image img {
  filter: alpha(opacity=100);
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-left: 0pt;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"][class~="fa-angle-left"] {
  left: -18px;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"][class~="fa-angle-right"] {
  right: -18px;
}
[class~="block-posts"][class~="layout-3"] [class~="post-image"] img,
[class~="block-posts"][class~="layout-2"] [class~="post-image"] img {
  -webkit-transition: all 0.5s ease;
}
[class~="block-post-cats"] ul {
  padding-left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
[class~="block-posts"][class~="layout-2"] [class~="post-image"] img,
[class~="block-posts"][class~="layout-3"] [class~="post-image"] img {
  transition: all 0.5s ease;
}
[class~="block-posts"] ul[class~="posts-list"] li [class~="post-image"] {
  flex: 0 0 80px;
}
[class~="block-post-cats"] ul {
  list-style-type: none;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-top: 0;
}
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"]:hover {
  background: #000;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-bottom: 0.052083333in;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  -webkit-transition: all 0.3s ease;
}
[class~="block-posts"] ul[class~="posts-list"] li [class~="post-content"] {
  padding-left: 0.15625in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}
.block-product-cats.layout-6 .cat-item .cat-image img {
  -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  top: auto;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-lookbook"] [class~="background-overlay"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  position: absolute;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:before {
  transition: all 0.3s ease;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-right: 0pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"],
[class~="block-lookbook"] [class~="background-overlay"] {
  width: 100%;
}
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-right: 31.875pt;
}
.block-lookbook .item-lookbook:after {
  -ms-border-radius: 50%;
}
[class~="block-lookbook"] [class~="background-overlay"] {
  height: 100%;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  content: "";
}
[class~="block-lookbook"] [class~="background-overlay"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
.block-lookbook .item-lookbook .number-lookbook:before,
[class~="block-lookbook"] [class~="background-overlay"] {
  top: 0;
}
[class~="block-post-archives"] ul {
  padding-left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]:hover
  [class~="button"],
.block-instagram a.instagram:hover:after,
[class~="block-instagram"] a[class~="instagram"]:hover:before,
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-title"]
  a {
  opacity: 1;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  width: 16.5pt;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  left: 50%;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  height: 16.5pt;
}
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-top: 0;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  -webkit-transform: translateX(-50%);
}
.block-product-cats.layout-6 .cat-item .cat-title a,
[class~="block-product-cats"][class~="layout-5"][class~="slider"]
  i[class~="slick-arrow"]:hover {
  color: #fff;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-1"]:hover
  [class~="button"],
[class~="block-instagram"] a[class~="instagram"]:hover:before,
.block-instagram a.instagram:hover:after,
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-title"]
  a {
  visibility: visible;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  transform: translateX(-50%);
}
.block-product-cats.layout-6 .cat-item .cat-image img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  width: 4.0625pc;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  filter: alpha(opacity=80);
}
[class~="block-post-archives"] ul {
  padding-bottom: 0;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  transform: scale3d(1.1, 1.1, 1);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  padding-left: 2.5pc;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  font-size: 22.5pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  top: calc(50% - 11px);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"],
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-top: 0px;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  font-family: icomoon;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 3.125pc;
}
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  fill: #000;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"] {
  padding-right: 2.5pc;
}
.block-lookbook .item-lookbook .number-lookbook:before,
[class~="block-sliders"][class~="layout-15"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  color: #000;
}
.block-product-cats.layout-6 .cat-item .cat-title a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 300;
}
[class~="block-product-cats"][class~="layout-2"] [class~="row"],
.block-product-cats.layout-3 .row {
  margin-left: -22.5pt;
}
.block-product-cats.layout-3 .row,
[class~="block-product-cats"][class~="layout-2"] [class~="row"] {
  margin-bottom: 0;
}
[class~="block-product-cats"][class~="layout-2"] [class~="row"],
.block-product-cats.layout-3 .row {
  margin-right: -22.5pt;
}
[class~="block-product-cats"][class~="layout-2"] [class~="row"],
.block-product-cats.layout-3 .row {
  margin-top: 0;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  fill: #fff;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  font-size: 15px;
}
.block-banners .block-widget-banner.layout-4 .button {
  font-size: 0.145833333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="button"] {
  font-size: 13px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 0.395833333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="button"] {
  font-weight: 500;
}
.block-lookbook .item-lookbook:after {
  -o-border-radius: 50%;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  position: relative;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="button"] {
  letter-spacing: 0.020833333in;
}
.block-lookbook .item-lookbook .number-lookbook:before {
  font-weight: 700;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  padding-bottom: 3px;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  -webkit-transform: translateY(-50%);
}
[class~="block-product-cats"] ul li [class~="count"] {
  -moz-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
[class~="block-product-cats"] ul li [class~="count"] {
  -ms-border-radius: 50%;
}
[class~="block-lookbook"] [class~="lookbook-content"] {
  vertical-align: top;
}
[class~="block-lookbook"] [class~="lookbook-content"],
.block-newsletter .newsletter-content input[type="email"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  width: 100%;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  opacity: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  left: calc(50% - 11px);
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-image"]:before {
  opacity: 1;
}
.block-instagram a.instagram:hover img {
  -webkit-transform: scale(1.2);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 5px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  -moz-border-radius: 15pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"],
.block-product-cats.layout-6 .cat-item .cat-title a:before,
.block-lookbook .item-lookbook {
  position: absolute;
}
.block-instagram a.instagram:hover img {
  transform: scale(1.2);
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"] {
  align-content: center;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
.block-newsletter .newsletter-content input[type="email"] {
  line-height: 0.5in;
}
[class~="block-post-archives"] ul {
  padding-right: 0;
}
.block-newsletter .newsletter-content input[type="email"] {
  height: 0.5in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 1.5625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  padding-left: 2.1875pc;
}
.block-lookbook .item-lookbook {
  width: 26.25pt !important;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  padding-left: 50px;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"] {
  align-items: center;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="button"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 0pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  padding-right: 2.1875pc;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-left-width: 0;
}
.block-product-cats.layout-6 .cat-item .cat-title a {
  visibility: hidden;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-left-width: 1.5pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  padding-right: 0pc;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"] {
  display: -webkit-box;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]:hover
  [class~="cat-image"]:before {
  visibility: visible;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-bottom-width: 0;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-right-width: 0;
}
[class~="block-post-archives"] ul {
  padding-top: 0;
}
.block-lookbook .item-lookbook:after {
  z-index: -1;
}
.block-lookbook .item-lookbook {
  height: 2.1875pc !important;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"] {
  padding-top: 115px;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a {
  padding-left: 15px;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"] {
  display: -ms-flexbox;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a:hover {
  background: #000;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  opacity: 0.75;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"],
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  transform: translateY(-50%);
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"] {
  height: 100%;
}
[class~="block-product-cats"] ul li [class~="count"] {
  -o-border-radius: 50%;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-top-width: 0;
}
.block-lookbook .item-lookbook:after {
  -webkit-animation-name: n;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-2"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  padding-top: 0px;
}
.block-lookbook .item-lookbook:after {
  animation-name: n;
}
.block-lookbook .item-lookbook {
  border-radius: 50%;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a:hover {
  color: #fff;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-left-style: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"] {
  text-align: left;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-bottom-style: none;
}
.block-product-cats.layout-7 .cat-item,
[class~="block-product-cats"][class~="layout-6"] [class~="cat-image"] {
  position: relative;
}
.block-product-filter ul.filter-items li:last-child {
  margin-right: 0;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  filter: alpha(opacity=80);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  top: 50%;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-right-style: none;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-top-style: none;
}
.block-banners
  .block-widget-banner.layout-3
  .banner-wrapper-infor
  .title-banner {
  font-size: 1.875pc;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a {
  padding-right: 0.9375pc;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]:hover
  [class~="cat-image"]
  img {
  transform: scale3d(1.1, 1.1, 1);
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] {
  margin-left: -0.494791667in;
}
[class~="block-intro"],
[class~="block-product-cats"][class~="layout-7"] [class~="row"] {
  margin-bottom: 0;
}
.block-lookbook .item-lookbook:after {
  -webkit-animation-duration: 2s;
}
.block-lookbook .item-lookbook:after {
  animation-duration: 2s;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover:after {
  border-left-color: #000;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-left-color: currentColor;
}
[class~="block-product-cats"] ul li [class~="count"] {
  background: #f1f1f1;
}
.block-lookbook .item-lookbook {
  -webkit-border-radius: 50%;
}
[class~="block-sliders"][class~="layout-9"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  padding-left: 250px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}
.block-banners
  .block-widget-banner.layout-3
  .banner-wrapper-infor
  .title-banner {
  font-weight: 300;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a,
[class~="block-product-cats"] ul li [class~="count"] {
  text-align: center;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-bottom-color: currentColor;
}
.block-lookbook .item-lookbook:after {
  -webkit-animation-timing-function: ease-in-out;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-right-color: currentColor;
}
.block-banners
  .block-widget-banner.layout-3
  .banner-wrapper-infor
  .title-banner {
  margin-bottom: 0.75pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-bottom-width: 1.5pt;
}
.block-banners
  .block-widget-banner.layout-3
  .banner-wrapper-infor
  .title-banner {
  margin-top: 0;
}
.block-lookbook .item-lookbook {
  -moz-border-radius: 50%;
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] {
  margin-right: -0.494791667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-3"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 0;
}
[class~="block-post-archives"] ul {
  list-style: none;
}
[class~="block-product-cats"][class~="layout-7"] [class~="row"] {
  margin-top: 0;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-right-width: 1.5pt;
}
.block-post-tags ul li {
  border-left-width: 0.75pt;
}
[class~="block-post-tags"] ul {
  padding-left: 0;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover:after {
  border-bottom-color: #000;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"],
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  width: 100%;
}
.block-lookbook .item-lookbook {
  -ms-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a {
  min-width: 101.25pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"][class~="text-center"] {
  justify-content: center;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-top-color: currentColor;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after,
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-top-width: 1.5pt;
}
[class~="block-video"] [class~="section-column"][class~="left"] {
  width: 41.3%;
}
.block-newsletter .newsletter-content input[type="email"] {
  border-image: none;
}
[class~="block-product-cats"] ul li [class~="count"] {
  line-height: 1.5625pc;
}
[class~="block-video"] [class~="section-column"][class~="right"] {
  width: 58.7%;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"]
  a {
  background: #fff;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover:after {
  border-right-color: #000;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover:after {
  border-top-color: #000;
}
.block-lookbook .item-lookbook:after {
  animation-timing-function: ease-in-out;
}
[class~="block-video"] [class~="left"] [class~="block-widget-wrap"] {
  margin-left: 0in;
}
[class~="block-video"] [class~="left"] [class~="block-widget-wrap"] {
  margin-bottom: -6.25pc;
}
[class~="block-video"] [class~="left"] [class~="block-widget-wrap"] {
  margin-right: 0in;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  backface-visibility: hidden;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-left: 112.5pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-left-style: solid;
}
.block-video .block-widget-video .video-wrap .video:hover:before {
  border-left-color: #000;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 3.125pc;
}
[class~="block-video"] [class~="left"] [class~="block-widget-wrap"] {
  margin-top: 1.041666667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-right: 0.9375pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"]
  [class~="link-title"],
[class~="block-product-cats"] ul li [class~="count"] {
  display: block;
}
.block-post-tags ul li {
  border-bottom-width: 0.75pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0pc;
}
[class~="block-video"] [class~="left"] [class~="block-widget-video"] {
  position: relative;
}
.block-lookbook .item-lookbook {
  -o-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
.block-banners
  .right
  .block-widget-banner.layout-8
  .banner-wrapper-infor
  .title-banner {
  font-size: 30px;
}
[class~="block-product-cats"] ul li [class~="count"] {
  font-size: 0.875pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-bottom-style: solid;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banner-wrapper-infor"][class~="text-center"],
[class~="block-video"] [class~="left"] [class~="block-widget-video"],
.block-lookbook .item-lookbook {
  text-align: center;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor,
[class~="block-video"] [class~="block-widget-video"] [class~="video-wrap"] {
  position: absolute;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 400;
}
[class~="block-video"] [class~="block-widget-video"] [class~="video-wrap"] {
  top: calc(50% - 55px);
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  justify-content: flex-start;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 3px;
}
[class~="block-post-cats"] ul li a,
[class~="block-video"] [class~="right"] [class~="block-widget-wrap"] {
  align-items: center;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor,
[class~="block-posts"] ul[class~="posts-list"] li {
  display: flex;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor,
[class~="block-video"] [class~="block-widget-video"] [class~="video-wrap"] {
  width: 100%;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  bottom: 0;
}
.block-newsletter .newsletter-content input[type="email"] {
  font-size: 0.15625in;
}
[class~="block-video"] [class~="right"] [class~="block-widget-wrap"] {
  display: -webkit-box;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  filter: alpha(opacity=100);
}
.block-newsletter .newsletter-content input[type="email"] {
  padding-left: 15pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-4"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banners"]
  [class~="banner-image"] {
  margin-bottom: 1.5625pc;
}
[class~="block-post-tags"] ul,
[class~="block-feature"][class~="no-border"] [class~="box"],
.block-video .block-widget-video .video-text,
.block-newsletter .newsletter-content input[type="email"] {
  padding-bottom: 0;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  transform: translateY(0);
}
[class~="block-video"] [class~="right"] [class~="block-widget-wrap"] {
  display: -ms-flexbox;
}
[class~="block-video"] [class~="right"] [class~="block-widget-wrap"] {
  display: flex;
}
.block-newsletter .newsletter-content input[type="email"] {
  padding-right: 15pt;
}
[class~="block-video"] [class~="right"] [class~="block-widget-wrap"] {
  align-content: center;
}
.block-video .block-widget-video .video-wrap .video:hover:before {
  border-bottom-color: #000;
}
.block-video .block-widget-video .video-text {
  padding-left: 18.75pt;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  top: auto;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after,
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  content: "";
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-right-style: solid;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-posts"] ul[class~="posts-list"] li {
  padding-bottom: 13.5pt;
}
.block-lookbook .item-lookbook:after {
  -webkit-animation-iteration-count: infinite;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-image"]
  img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="link"]
  a:hover:before {
  width: 50%;
}
.block-video .block-widget-video .video-text {
  padding-right: 18.75pt;
}
.block-newsletter .newsletter-content input[type="email"],
.block-video .block-widget-video .video-text,
[class~="block-feature"][class~="no-border"] [class~="box"] {
  padding-top: 0;
}
.block-video .block-widget-video .video-wrap .video:hover:before {
  border-right-color: #000;
}
.block-video .content-video .modal-dialog {
  max-width: 8.333333333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 30px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0.052083333in;
}
[class~="block-feature"][class~="no-border"] [class~="box"] {
  padding-left: 0;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  left: 0;
}
[class~="block-posts"] ul[class~="posts-list"] li {
  margin-bottom: 13.5pt;
}
[class~="block-intro"] {
  margin-right: auto;
}
[class~="block-posts"] ul[class~="posts-list"] li {
  border-bottom-width: 0.010416667in;
}
[class~="block-post-tags"] ul,
[class~="block-feature"][class~="no-border"] [class~="box"] {
  padding-right: 0;
}
.block-video .block-widget-video .video-wrap .video:hover:before {
  border-top-color: #000;
}
.block-newsletter .newsletter-content input[type="email"] {
  background: #fff;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  padding-left: 37.5pt;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  padding-bottom: 5.625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-5"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 15px;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  padding-right: 0.520833333in;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
  justify-content: flex-end;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-left-width: 0;
}
.block-lookbook .item-lookbook {
  line-height: 35px;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-bottom-width: 0;
}
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-left-width: medium;
}
.block-banners .block-widget-banner.layout-10 .banner-wrapper-infor {
  padding-top: 0pt;
}
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-bottom-width: medium;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after,
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-top-style: solid;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  content: "|";
}
[class~="block-posts"] ul[class~="posts-list"] li {
  border-bottom-style: solid;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-right-width: 0;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-top-width: 0;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
  padding-left: 0px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]:hover
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  -webkit-transform: translateY(0);
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
  padding-bottom: 0in;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  justify-content: center;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-left-color: transparent;
}
.block-lookbook .item-lookbook:after {
  animation-iteration-count: infinite;
}
[class~="block-posts"] ul[class~="posts-list"] li {
  border-bottom-color: #e1e1e1;
}
.block-lookbook .item-lookbook,
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  display: inline-block;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor,
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  display: flex;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-bottom-color: transparent;
}
.block-product-filter ul.filter-items li:hover {
  color: #000;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-right-color: transparent;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  -webkit-transform: translateY(-50%);
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after,
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  position: absolute;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
  padding-right: 78.75pt;
}
.block-banners .block-widget-banner.layout-6 .banner-wrapper-infor.right {
  padding-top: 1.145833333in;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-left-style: none;
}
[class~="block-feature"][class~="no-border"] [class~="box"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-bottom-style: none;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  top: calc(50% - 35px);
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  left: calc(50% - 35px);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 0.333333333in;
}
[class~="block-feature"][class~="no-border"] [class~="box"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-right-style: none;
}
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-right-width: medium;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-top-style: none;
}
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-top-width: medium;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  transform: translateY(-50%);
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  top: 50%;
}
.block-lookbook .item-lookbook {
  -webkit-transition: width 0.2s ease, height 0.2s ease;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-left-color: currentColor;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-7"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 300;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  width: 4.375pc;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]:hover
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  transform: translateY(0);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 3.75pt;
}
.block-lookbook .item-lookbook {
  transition: width 0.2s ease, height 0.2s ease;
}
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  bottom: auto;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  width: 2.5pc;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor,
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor {
  width: 100%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-6"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-7"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
.block-product-cats.layout-5
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a:before,
.block-banners .block-widget-banner.layout-11 .banner-wrapper-infor,
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  left: 0;
}
.block-banners .block-widget-banner.layout-7 .banners .banner-image {
  margin-bottom: 30px;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  height: 4.375pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-7"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 38px;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-bottom-color: currentColor;
}
.block-post-tags ul li {
  border-right-width: 0.75pt;
}
[class~="block-posts"] ul[class~="posts-list"] li {
  border-image: none;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  justify-content: flex-start;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-left: 3.75pt;
}
.block-banners
  .block-widget-banner.layout-16
  .banner-wrapper-infor
  .banner-icon:hover {
  animation-name: animation-horizontal;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before,
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  bottom: 0;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-7"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0.15625in;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-top-color: #000;
}
.block-post-archives ul li:before {
  content: "5";
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-right: 6pt;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-feature"][class~="no-border"] [class~="box"] {
  border-right-color: currentColor;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-top-color: transparent;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  top: auto;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  position: absolute;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  width: 3pc;
}
.block-lookbook .item-lookbook {
  z-index: 5;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  height: 0.416666667in;
}
[class~="block-feature"][class~="no-border"] [class~="box"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-top-color: currentColor;
}
.block-banners
  .block-widget-banner.layout-16
  .banner-wrapper-infor
  .banner-icon:hover {
  animation-duration: 1s;
}
.block-banners
  .block-widget-banner.layout-7
  .banner-wrapper-infor
  .banner-image-description {
  margin-bottom: 22.5pt;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  height: 0.5in;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-left-width: 0.020833333in;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after,
[class~="block-feature"][class~="no-border"] [class~="box"],
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-image: none;
}
.block-post-archives ul li:before {
  display: inline-block;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] {
  margin-bottom: 9pt;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  padding-left: 2.8125pc;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  content: "";
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  left: calc(50% - 17.5px);
}
.block-feature .box .box-title {
  font-size: 15pt;
}
[class~="block-feature"] [class~="box"] [class~="box-description"] {
  margin-bottom: 0;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  padding-bottom: 33.75pt;
}
[class~="block-banners"]
  [class~="right"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-left: 52.5pt;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  padding-left: 18.75pt;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-top: 0;
}
[class~="block-banners"]
  [class~="right"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 0.520833333in;
}
[class~="block-feature"][class~="layout-2"] [class~="box"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  padding-bottom: 0;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  border-radius: 50%;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-bottom-width: 0.020833333in;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-right-width: 0.020833333in;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  padding-left: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  top: calc(50% - 17.5px);
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  -webkit-border-radius: 50%;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  padding-right: 18.75pt;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor {
  padding-right: 0.9375pc;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  line-height: 2.875pc;
}
.block-post-tags ul li {
  border-top-width: 0.75pt;
}
.block-banners .block-widget-banner.layout-12 .banner-wrapper-infor,
[class~="block-banners"]
  [class~="right"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0in;
}
[class~="block-post-tags"] ul,
[class~="block-feature"][class~="layout-2"] [class~="box"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  padding-top: 0;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  justify-content: flex-start;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  display: flex;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-top-width: 0.020833333in;
}
[class~="block-banners"]
  [class~="right"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"] {
  padding-right: 4.375pc;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-left-width: 0.010416667in;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  top: 50%;
}
.block-post-tags ul li,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-left-style: solid;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  padding-right: 0;
}
.block-banners
  .block-widget-banner.layout-16
  .banner-wrapper-infor
  .banner-icon:hover {
  animation-timing-function: ease-in-out;
}
.block-product-cats.layout-5
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a:before {
  -webkit-transform: none;
}
.block-post-tags ul li,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-bottom-style: solid;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  transform: translateY(-50%);
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  bottom: auto;
}
.block-lookbook .item-lookbook {
  text-indent: -1px;
}
[class~="block-instagram"] a[class~="instagram"]:after,
.block-posts.layout-3 .post:first-child .post-inner:before,
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  position: absolute;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-right-style: solid;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-bottom-width: 0.010416667in;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  width: 100%;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-left-width: 2px;
}
[class~="block-feature"][class~="layout-2"] [class~="box"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-width: medium;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  background: #000;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-top-style: solid;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-right-width: 0.010416667in;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  color: #e1e1e1;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  left: 0;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  letter-spacing: 0.125pc;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-left-color: #fff;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-bottom-width: medium;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  content: "";
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  background: #fff;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  overflow: hidden;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  position: relative;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-bottom-color: #fff;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  padding-left: 90px;
}
[class~="block-post-tags"] ul {
  list-style: none;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -moz-border-radius: 50%;
}
.block-lookbook .item-lookbook {
  cursor: pointer;
}
.block-post-archives ul li:before {
  font-family: eleganticons;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-right-color: #fff;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-top-color: #fff;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-right-width: 1.5pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  padding-bottom: 3.125pc;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-right-width: medium;
}
.block-testimonial .slick-sliders {
  max-width: 667.5pt;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  padding-bottom: 0px;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  padding-right: 11.25pt;
}
.block-banners .block-widget-banner.layout-13 .banner-wrapper-infor {
  padding-top: 0px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  -webkit-transform: translateY(50px);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  transform: translateY(50px);
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-image: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  -webkit-transition: all 0.3s ease;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-top-width: medium;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-top-width: 0.010416667in;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  border-radius: 50%;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-left-style: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"] {
  background: #303235;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -webkit-border-radius: 50%;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-bottom-style: none;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -ms-border-radius: 50%;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  transition: all 0.3s ease;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-right-style: none;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-top-style: none;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-left-color: currentColor;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  height: 50%;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-left-style: solid;
}
[class~="block-feature"][class~="layout-2"] [class~="box"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"] {
  display: -webkit-box;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-bottom-color: currentColor;
}
.block-product-cats.layout-5
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a:before {
  transform: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"],
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  display: -ms-flexbox;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-right-color: currentColor;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"],
[class~="block-feature"][class~="layout-2"] [class~="box"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}
.block-testimonial .slick-sliders {
  margin-left: auto;
}
.block-posts.layout-3 .post:first-child .post-inner:before,
[class~="block-instagram"] a[class~="instagram"]:after {
  left: 0;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after,
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  -o-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"] {
  align-content: center;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-top-color: currentColor;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  line-height: 48px;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -webkit-animation-name: e;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  transform: rotate(270deg);
}
.block-testimonial .slick-sliders {
  margin-bottom: auto;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  border-image: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-infor"] {
  align-items: center;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  font-family: slick;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  height: 0.5in;
}
.block-banners
  .block-widget-banner.layout-16
  .banner-wrapper-infor
  .banner-icon:hover {
  animation-iteration-count: 1;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-subtitle"] {
  color: #000;
}
.block-banners .block-widget-banner.layout-14 .banner-wrapper-infor {
  padding-left: 135px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"],
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  justify-content: center;
}
.block-testimonial .slick-sliders {
  margin-right: auto;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"]
  a:hover:before {
  width: 50%;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  animation-name: e;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-subtitle"] {
  font-size: 9.75pt;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-subtitle"] {
  font-weight: 500;
}
.block-post-archives ul li:before {
  font-size: 15px;
}
.block-posts.layout-3 .post:first-child .post-inner:before,
[class~="block-instagram"] a[class~="instagram"]:after,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"],
[class~="block-contact-map"] iframe {
  width: 100%;
}
.block-testimonial .slick-sliders {
  margin-top: auto;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  font-size: 4.5pt;
}
[class~="block-contact-map"] iframe {
  max-width: 100%;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  top: 50%;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  cursor: pointer;
}
.block-product-cats.layout-7 .cat-item .cat-title a:hover h3,
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  color: #fff;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  line-height: 0.208333333in;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  font-size: 0.125in;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-bottom-style: solid;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -webkit-animation-duration: 2s;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  text-transform: uppercase;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  text-align: left;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  animation-duration: 2s;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-right-color: #fff;
}
[class~="block-feature"][class~="layout-3"] [class~="box"] {
  max-width: 350px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-subtitle"] {
  letter-spacing: 1.5pt;
}
.block-post-tags ul li,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-right-style: solid;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(-50%);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}
.block-post-tags ul li,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-top-style: solid;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  position: absolute;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-subtitle"] {
  margin-bottom: 0.104166667in;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-left-color: #fcfbfe;
}
[class~="block-sliders"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  transition: transform 5s linear 0s, border-left-width 0s linear 5s,
    -webkit-transform 5s linear 0s;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-testimonial"] [class~="testimonial-content"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  text-align: center;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 30pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-intro"] [class~="intro-text"] {
  font-weight: 300;
}
[class~="block-contact-map"] iframe {
  margin-left: 0;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"],
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"] {
  -webkit-transition: all 0.3s ease;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}
[class~="block-feature"][class~="layout-3"] [class~="box"] {
  margin-left: auto;
}
[class~="block-testimonial"] [class~="slick-arrow"] {
  font-size: 0.3125in;
}
[class~="block-feature"][class~="layout-3"] [class~="box"] {
  margin-bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  display: -webkit-box;
}
[class~="block-feature"][class~="layout-3"] [class~="box"] {
  margin-right: auto;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  display: -ms-flexbox;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  display: flex;
}
[class~="block-feature"][class~="layout-3"] [class~="box"] {
  margin-top: 0;
}
[class~="block-intro"] [class~="intro-text"] {
  font-size: 0.416666667in;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  padding-left: 0;
}
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"],
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  transition: all 0.3s ease;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"],
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  padding-bottom: 0;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:before {
  border-bottom-color: #fff;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  padding-right: 0;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  padding-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  align-content: center;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  border-radius: 0 !important;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-left-width: medium;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  align-items: center;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  -webkit-border-radius: 0 !important;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  -moz-border-radius: 0 !important;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-bottom-color: #fcfbfe;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  -ms-border-radius: 0 !important;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -webkit-animation-timing-function: ease-in-out;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-bottom-width: medium;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  padding-left: 0.833333333in;
}
[class~="block-intro"] [class~="intro-text"] {
  line-height: 60px;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  animation-timing-function: ease-in-out;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  padding-right: 0.3125in;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before,
[class~="block-intro"] [class~="intro-text"] {
  color: #000;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  z-index: 1;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"] {
  padding-top: 0in;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  -webkit-animation-iteration-count: infinite;
}
[class~="block-instagram"] a[class~="instagram"]:after,
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"] {
  opacity: 0;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  animation-iteration-count: infinite;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-right-width: medium;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-8"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-left: -0.125pc;
}
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"] {
  visibility: hidden;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.3), transparent);
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-top-width: medium;
}
.block-video .block-widget-video .video-wrap .video:before {
  content: "";
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"]:hover {
  border-left-color: #000;
}
[class~="block-intro"] [class~="intro-text"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-9"]
  [class~="banners"]
  [class~="banner-image"] {
  margin-bottom: 0.260416667in;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-left-style: none;
}
.block-video .block-widget-video .video-wrap .video:before {
  position: absolute;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-9"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 0.291666667in;
}
.block-video .block-widget-video .video-wrap .video:before {
  top: calc(50% - 35px);
}
.block-video .block-widget-video .video-wrap .video:before {
  left: calc(50% - 35px);
}
.block-video .block-widget-video .video-wrap .video:before {
  width: 4.375pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"]:hover {
  border-bottom-color: #000;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-bottom-style: none;
}
[class~="block-newsletter"] [class~="newsletter-content"] input[type="submit"] {
  -o-border-radius: 0 !important;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-left-width: 0.0625pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  opacity: 0.25;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  -webkit-font-smoothing: antialiased;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-right-style: none;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-top-style: none;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-left-color: currentColor;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-bottom-color: currentColor;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-right-color: #fcfbfe;
}
.block-video .block-widget-video .video-wrap .video:before {
  height: 4.375pc;
}
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"] {
  text-align: center;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-bottom-width: 0.0625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"]:hover {
  border-right-color: #000;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-9"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 300;
}
[class~="block-banners"][class~="slider"]
  [class~="item"]
  [class~="banner-wrapper-infor"] {
  margin-top: 1.875pc;
}
[class~="block-banners"][class~="slider"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 20.5% !important;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-right-color: currentColor;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-width: 0.0625pc;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-left-width: 0.020833333in;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-width: medium;
}
[class~="block-banners"][class~="slider"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  margin-left: -0.3125pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button:before {
  -moz-osx-font-smoothing: grayscale;
}
[class~="block-banners"][class~="slider"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 20.5% !important;
}
[class~="block-banners"][class~="slider"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  margin-right: -0.052083333in;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-top-width: 0.0625pc;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"],
.block-video .block-widget-video .video-wrap .video:before {
  border-left-style: solid;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-bottom-width: 0.020833333in;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-left-width: 0;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-right-width: 0.020833333in;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-top-color: currentColor;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  border-image: none;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-top-color: #fcfbfe;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-9"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-10"]
  [class~="banner-wrapper-infor"]
  [class~="subtitle-banner"] {
  margin-bottom: 7.5pt;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  display: -webkit-box;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  width: 100%;
}
.block-posts.layout-3 .post:first-child .post-inner:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0, transparent);
}
.block-banners.slider .slick-arrow:hover {
  background: #000;
}
[class~="block-contact-map"] iframe {
  margin-bottom: 0;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-top-width: 0.020833333in;
}
.block-banners.slider .slick-arrow:hover {
  color: #fff;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-9"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"],
.block-video .block-widget-video .video-wrap .video:before {
  border-bottom-style: solid;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:first-child {
  padding-left: 0;
}
.block-post-tags ul li {
  border-left-color: #e5e5e5;
}
.block-video .block-widget-video .video-wrap .video:before,
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-style: solid;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  color: #000;
}
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:last-child {
  padding-right: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"],
.block-video .block-widget-video .video-wrap .video:before {
  border-top-style: solid;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  font-size: 12pt;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-size: 22px;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  height: 0.010416667in;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  display: -ms-flexbox;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  display: flex;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-left-color: #fff;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  left: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-10"]
  [class~="banner-wrapper-infor"]
  [class~="subtitle-banner"] {
  font-size: 20px;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  color: #868686;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  justify-content: center;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"],
.block-posts .post-item .post-image {
  display: inline-block;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"],
.block-post-archives ul li:before {
  position: relative;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-left: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-10"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 30pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-bottom-width: 0;
}
.block-post-archives ul li:before {
  margin-right: 2.25pt;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  align-items: center;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-10"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 300;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  -ms-border-radius: 15pt;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] {
  text-align: left;
}
[class~="block-posts"] [class~="post-item"]:last-child {
  margin-bottom: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-10"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 25px;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-bottom: 0.3125pc;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-right: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-left-color: #e8e8e8;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-top: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-image: none;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-bottom-color: #fff;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-right-color: #fff;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  line-height: 1.5625pc;
}
.block-posts .post-item .post-image {
  max-width: 5.625pc;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-left-width: medium;
}
.block-posts .post-item .post-image {
  flex: 0 0 0.9375in;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-bottom-width: medium;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-left: auto;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-right-width: 0;
}
.block-banners
  .block-widget-banner.layout-11
  .banner-wrapper-infor
  .title-banner {
  font-size: 1.5pc;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  -webkit-transform: translateX(-50%);
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-right-width: medium;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-top-width: medium;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-left-style: none;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-bottom-style: none;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-top-color: #fff;
}
[class~="block-posts"] [class~="post-item"] [class~="post-content"] {
  padding-left: 11.25pt;
}
.block-banners
  .block-widget-banner.layout-11
  .banner-wrapper-infor
  .title-banner {
  text-transform: uppercase;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-right-style: none;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-bottom-color: #e8e8e8;
}
.block-post-tags ul li {
  border-bottom-color: #e5e5e5;
}
.block-newsletter .sub-title {
  margin-bottom: 0.5pc;
}
.block-banners
  .block-widget-banner.layout-11
  .banner-wrapper-infor
  .title-banner {
  letter-spacing: 0.1875pc;
}
[class~="block-posts"] [class~="post-item"] [class~="post-content"] {
  margin-top: -0.020833333in;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  visibility: hidden;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-12"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  color: #fff;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-top-style: none;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-left-color: currentColor;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-bottom: auto;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-color: #e8e8e8;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-bottom-color: currentColor;
}
.block-posts.layout-2 .post-image {
  margin-bottom: 18px;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-12"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 30pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-right: auto;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"]:hover {
  border-top-color: #000;
}
.block-video .block-widget-video .video-wrap .video:before {
  border-image: none;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-right-color: currentColor;
}
[class~="block-contact-map"] iframe {
  margin-right: 0;
}
.block-posts.layout-2 .post-image,
[class~="block-lookbook"] [class~="lookbook-content"] [class~="item"],
[class~="block-posts"][class~="layout-3"] [class~="post-image"] {
  position: relative;
}
[class~="block-newsletter"] [class~="title"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-13"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 1.875pc;
}
.block-posts.layout-3 .post,
[class~="block-posts"][class~="layout-3"] [class~="post-image"],
.block-posts.layout-2 .post-image {
  overflow: hidden;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-top-color: #e8e8e8;
}
[class~="block-posts"][class~="layout-2"] [class~="post-content"] {
  text-align: center;
}
.block-post-tags ul li {
  border-right-color: #e5e5e5;
}
.block-video .block-widget-video .video-wrap .video:before,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  border-radius: 50%;
}
.block-posts.layout-3 .post {
  margin-bottom: 0.3125in;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
.block-video .block-widget-video .video-wrap .video:before {
  -webkit-border-radius: 50%;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-top: auto;
}
.block-post-tags ul li {
  border-top-color: #e5e5e5;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-image: none;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  -webkit-transition: opacity 0.3s ease;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"] {
  border-top-color: currentColor;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  width: 33.33%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-13"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-12"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 300;
}
.block-video .block-widget-video .video-wrap .video:before,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  -moz-border-radius: 50%;
}
[class~="block-posts"][class~="layout-3"] [class~="post-image"] {
  flex: 0 0 2.447916667in;
}
[class~="block-posts"][class~="layout-3"] [class~="post-content"] {
  padding-left: 3.125pc;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  line-height: 48px;
}
[class~="block-newsletter"] [class~="title"] {
  line-height: 1;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-12"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0in;
}
[class~="block-newsletter"] [class~="title"] {
  margin-bottom: 1.5625pc;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"],
.block-post-tags ul li {
  border-image: none;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-12"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-13"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
[class~="block-lookbook"][class~="layout-2"] [class~="lookbook-wrap"] {
  max-width: 6.5625in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-product-filter ul.filter-items li:hover span {
  border-left-width: 1px;
}
[class~="block-newsletter"] [class~="title"] {
  color: #000;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover {
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  height: 48px;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  width: 82.5pt;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-left: 0.208333333in;
}
[class~="block-lookbook"] [class~="background-overlay"][class~="background-2"] {
  background-color: #85786b;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  transition: opacity 0.3s ease;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  font-size: 0;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-width: medium;
}
.block-instagram a.instagram:before {
  position: absolute;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-13"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 20px;
}
.block-instagram a.instagram:before,
[class~="block-newsletter"] [class~="newsletter-content"] {
  width: 100%;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover {
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
.block-video .block-widget-video .video-wrap .video:before {
  -ms-border-radius: 50%;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  width: 2.5pc;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover {
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"],
.block-video .block-widget-video .video-wrap .video:before,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  -o-border-radius: 50%;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  padding-left: 3.75pt;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover {
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  max-width: 6.041666667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-13"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 30px;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-width: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  height: 2.5pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  line-height: 2.5pc;
}
.block-product-filter ul.filter-items li:hover span,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-width: 0.010416667in;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-top-width: 0;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-style: none;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-style: solid;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 18px;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  border-radius: 50%;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-style: none;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  transform: translateX(-50%);
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-left: 0in;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-bottom: 0;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-product-filter ul.filter-items li:hover span {
  border-right-width: 0.010416667in;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-right: 0.208333333in;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 0.104166667in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-width: 0.010416667in;
}
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner {
  color: #fff;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-bottom-style: solid;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-bottom-width: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  -webkit-border-radius: 50%;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-right: 0in;
}
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-top: 0pt;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  -moz-border-radius: 50%;
}
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner {
  font-size: 1.875pc;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-style: solid;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-title"],
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-title"] {
  text-align: center;
}
.block-instagram a.instagram:before {
  left: 0;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  input:checked
  ~ [class~="checkmark"]:after {
  display: block;
}
.block-video .block-widget-video .video-wrap .video:before {
  -webkit-animation-name: e;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-style: solid;
}
[class~="block-product-cats"][class~="layout-2"]
  [class~="cat-item"]
  [class~="cat-title"] {
  margin-top: 25px;
}
[class~="block-contact-map"] iframe {
  margin-top: 0;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="cat-item"]
  [class~="cat-image"] {
  margin-bottom: 1.5625pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  -ms-border-radius: 50%;
}
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner,
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="title"] {
  font-weight: 300;
}
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner {
  letter-spacing: 2px;
}
.block-video .block-widget-video .video-wrap .video:before {
  animation-name: e;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-style: none;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="title"] {
  font-size: 30pt;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="title"],
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner,
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="description"] {
  margin-bottom: 20px;
}
.block-post-archives ul li:before {
  top: 0.0625pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  background: #fff;
}
.block-post-archives ul li:before {
  color: #000;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  z-index: -1;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  display: inline-block;
}
[class~="block-contact-map"] iframe {
  line-height: 1;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  -webkit-animation-name: n;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-top: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"],
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  position: absolute;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-width: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  animation-name: n;
}
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  width: 100%;
}
.block-banners
  .block-widget-banner.layout-14
  .banner-wrapper-infor
  .title-banner {
  text-transform: uppercase;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="button"],
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .title-banner,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  color: #fff;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="product-cats-intro-wrap"]
  [class~="title"] {
  line-height: 0.46875in;
}
[class~="block-contact-map"] iframe {
  border-left-width: medium;
}
.block-instagram a.instagram:before {
  opacity: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
.block-video .block-widget-video .video-wrap .video:before {
  -webkit-animation-duration: 2s;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-left-color: transparent;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-bottom-color: transparent;
}
.block-instagram a.instagram:before {
  visibility: hidden;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="item-image"] {
  margin-bottom: 0.125in;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  justify-content: center;
}
.block-video .block-widget-video .video-wrap .video:before,
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  animation-duration: 2s;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  content: "\e75a";
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  left: 0;
}
.block-instagram a.instagram:before {
  -webkit-transition: opacity 0.3s ease;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
.block-video .block-widget-video .video-wrap .video:before {
  -webkit-animation-timing-function: ease-in-out;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-top-width: 0;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-color: #ececec;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  display: flex;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before,
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  -webkit-transition: all 0.3s ease;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  font-family: themify;
}
.block-instagram a.instagram:before {
  transition: opacity 0.3s ease;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-right-color: transparent;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  font-size: 1.25pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="number-lookbook"] {
  transition: all 0.3s ease;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  top: calc(50% - 10px);
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  z-index: 9;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  top: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 0.15625in;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  padding-left: 0.625pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  padding-bottom: 0.625pc;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  transform: translateY(-50%);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="button"],
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  font-size: 9.75pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  left: calc(50% - 10px);
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  padding-right: 0.625pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-14"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  letter-spacing: 1.5pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before,
.block-post-archives ul li:before {
  line-height: 1;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  padding-top: 0.625pc;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  bottom: auto;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="link-title"] {
  display: block;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  text-align: center;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  z-index: 2;
}
[class~="block-image"][class~="slider"] [class~="item-image"] img {
  max-height: 10.3125pc;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  width: 2.34375in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-color: #ececec;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
[class~="block-feature"] [class~="box"] [class~="box-icon"] span:hover,
.block-video .block-widget-video .video-wrap .video:before {
  animation-timing-function: ease-in-out;
}
[class~="block-image"][class~="slider"] [class~="item-image"] img {
  width: auto;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-style: none;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  box-shadow: -0.0625pc 3.75pt 5px 0 rgba(182, 41, 41, 0.1);
}
.block-post-tags ul li {
  overflow: hidden;
}
[class~="block-image"][class~="slider"] [class~="item-image"] img {
  transition-duration: 0.3s;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"],
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  position: absolute;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  -webkit-transition: all 0.2s ease;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-bottom-style: none;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  width: 100%;
}
[class~="block-contact-map"] iframe {
  border-bottom-width: medium;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  left: 0;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  padding-left: 15px;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-style: none;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before,
.block-video .block-widget-video .video-wrap .video:before {
  -webkit-animation-iteration-count: infinite;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .title-banner {
  font-size: 50px;
}
.block-video .block-widget-video .video-wrap .video:before {
  animation-iteration-count: infinite;
}
[class~="block-image"][class~="slider"] [class~="item-image"] img {
  transition-property: transform;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  transition: all 0.2s ease;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:after {
  animation-delay: 1s;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  background: #fff;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .title-banner {
  font-weight: 300;
}
.block-instagram a.instagram:before {
  content: "";
}
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"] a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  color: #fff;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .title-banner {
  margin-bottom: 25px;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] span:hover {
  color: #818a91;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  padding-bottom: 0pt;
}
.block-banners
  .block-widget-banner.layout-15
  .banner-wrapper-infor
  .title-banner {
  margin-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  font-size: 0.8125pc;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  padding-right: 0.9375pc;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  padding-top: 0pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"],
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  font-weight: 500;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] span:hover {
  animation-name: animation-horizontal;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] span:hover {
  animation-duration: 1s;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"],
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  text-transform: uppercase;
}
[class~="block-product-cats"][class~="layout-6"]
  [class~="cat-item"]
  [class~="cat-title"] {
  z-index: 3;
}
.block-instagram a.instagram:before {
  top: 0;
}
[class~="block-lookbook"] [class~="item-lookbook"] [class~="content-lookbook"] {
  display: none;
}
.block-instagram a.instagram:before {
  height: 100%;
}
[class~="block-feature"] [class~="box"] [class~="box-icon"] span:hover {
  animation-iteration-count: 1;
}
[class~="block-feature"][class~="layout-3"]
  [class~="box"]
  [class~="box-icon"]
  span {
  font-size: 0.833333333in;
}
.block-feature.layout-4 .row > div:last-child,
[class~="block-contact-map"] iframe {
  border-right-width: medium;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  justify-content: center;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  display: flex;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  letter-spacing: 0.125pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  fill: #fff;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  line-height: 33.75pt;
}
[class~="block-lookbook"][class~="layout-2"] [class~="lookbook-intro-wrap"] {
  margin-top: 0.625in;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  top: 50%;
}
[class~="block-lookbook"][class~="layout-2"] [class~="lookbook-intro-wrap"] {
  margin-bottom: 85px;
}
.block-lookbook .lookbook-intro-wrap .sub-title {
  font-size: 1.25pc;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  transform: translateY(-50%);
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-top-color: transparent;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  bottom: auto;
}
.block-lookbook .lookbook-intro-wrap .sub-title {
  margin-bottom: 15pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  border-image: none;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  position: absolute;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  width: 100%;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  border-radius: 18.75pt 0.260416667in 1.5625pc 0.260416667in;
}
.block-lookbook .lookbook-intro-wrap .sub-title {
  line-height: 1;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  border-radius: 50%;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  padding-bottom: 0;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a,
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="button"] {
  padding-left: 26.25pt;
}
[class~="block-product-cats"][class~="layout-7"]
  [class~="cat-item"]
  [class~="cat-title"] {
  left: 0;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  display: inline-block;
}
[class~="block-contact-map"] iframe {
  border-top-width: medium;
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="title"] {
  font-size: 60px;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  padding-right: 3.75pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-15"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  padding-bottom: 0.083333333in;
}
.block-feature.layout-4 .row > div:last-child {
  border-right-style: none;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-color: #ececec;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  padding-bottom: 0.15625in;
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="title"] {
  font-weight: 300;
}
[class~="block-lookbook"] [class~="item-lookbook"]:before {
  animation-iteration-count: infinite;
}
[class~="block-intro"] {
  margin-top: 0;
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="title"] {
  margin-bottom: 0.3125in;
}
.block-feature.layout-4 .row > div:last-child,
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-color: currentColor;
}
[class~="block-newsletter"] [class~="newsletter-content"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-style: none;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-color: currentColor;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after,
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  -webkit-border-radius: 50%;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  width: 4.375pc;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  height: 4.375pc;
}
[class~="block-parallax"] {
  /* background-image: url("../images/banner/parallax.jpg"); */
}
.block-instagram a.instagram:before {
  background-color: rgba(37, 37, 37, 0.45);
}
.block-instagram a.instagram:before {
  z-index: 1;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  background: #fff;
}
.block-feature.layout-4 .row > div:last-child {
  border-image: none;
}
[class~="block-lookbook"] [class~="item-lookbook"]:after {
  animation-delay: 1.5s;
}
.block-post-tags ul li {
  background: transparent;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-bottom-color: currentColor;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"][class~="background-1"] {
  background-color: #94856c;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  color: #000;
}
.block-feature.layout-4 .box .box-icon span {
  font-size: 2.1875pc;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-color: #ececec;
}
[class~="block-intro"] [class~="intro-image"] img {
  margin-bottom: 0.416666667in;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a,
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="button"] {
  padding-right: 26.25pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-infor"][class~="background-2"] {
  background-color: #4c5365;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-image: none;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"],
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  -moz-border-radius: 50%;
}
[class~="block-posts"][class~="layout-2"] [class~="post-image"]:hover img {
  -webkit-transform: scale(1.1);
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="title"] {
  line-height: 1.1;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  padding-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"] {
  font-size: 4.0625pc;
}
[class~="block-post-archives"] ul li a,
.block-posts.layout-2 .post-content .post-categories a {
  color: #868686;
}
.block-product-cats.layout-6 .cat-item .cat-title a:before {
  transition: all 0.3s ease;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  -ms-border-radius: 50%;
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="button"] {
  padding-bottom: 0px;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-top-color: currentColor;
}
[class~="block-lookbook"] [class~="lookbook-intro-wrap"] [class~="button"] {
  padding-top: 0px;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  content: "";
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-left: 0.104166667in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-icon"] {
  margin-bottom: 12pt;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  -o-border-radius: 50%;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  padding-top: 11.25pt;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-image: none;
}
[class~="block-instagram"] a[class~="instagram"]:after,
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  color: #fff;
}
[class~="block-instagram"] a[class~="instagram"] {
  display: inline-block;
}
[class~="block-posts"][class~="layout-2"] [class~="post-image"]:hover img {
  transform: scale(1.1);
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  font-size: 13.5pt;
}
[class~="block-lookbook"][class~="layout-3"] [class~="background-overlay"] {
  background: #e5e8e4;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  fill: #fff;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 2.5pc;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  line-height: 1;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-bottom: 0.104166667in;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 7.5pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-right: 0.104166667in;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  max-width: 7.34375in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-top: 0.104166667in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-radius: 50%;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  font-family: FontAwesome;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0.260416667in;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a {
  background-color: #000;
}
.block-product-filter .filter-item .filter-content ul {
  overflow-y: scroll;
}
.block-posts.layout-2 .post-content .post-categories a {
  font-size: 9pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  -webkit-border-radius: 50%;
}
[class~="block-video"]
  [class~="right"]
  [class~="block-widget-wrap"]
  [class~="block-widget-video"],
.block-lookbook.layout-3 .lookbook-intro-wrap {
  margin-left: auto;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  line-height: 52.5pt;
}
.block-posts.layout-2 .post-content .post-categories a {
  text-transform: uppercase;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  font-size: 1.875pc;
}
.block-product-filter .filter-item .filter-content ul {
  max-height: 94.5pt;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  display: -webkit-box;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  text-indent: 3px;
}
[class~="block-instagram"] a[class~="instagram"],
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  position: relative;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  display: -ms-flexbox;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-left: auto;
}
.block-post-tags ul li {
  padding-left: 0.15625in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  font-size: 0.166666667in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  -moz-border-radius: 50%;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  display: flex;
}
.block-product-filter .filter-item .filter-content ul {
  padding-left: 0;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  -ms-border-radius: 50%;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"] {
  cursor: pointer;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li,
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  margin-left: 0;
}
.block-product-cats.layout-7 .cat-item .cat-title a h3 {
  font-size: 1.25pc;
}
.block-product-cats.layout-7 .cat-item .cat-title a h3 {
  margin-bottom: 0;
}
.block-post-tags ul li a,
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-categories"]
  a {
  color: #868686;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  height: 100%;
}
[class~="block-instagram"] a[class~="instagram"] {
  vertical-align: top;
}
.block-product-filter .filter-item .filter-content ul {
  list-style: none;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-categories"]
  a,
.block-posts.layout-2 .post-content .post-categories a {
  letter-spacing: 0.125pc;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-bottom: auto;
}
[class~="block-video"]
  [class~="right"]
  [class~="block-widget-wrap"]
  [class~="block-widget-video"] {
  max-width: 450pt;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-right: auto;
}
.block-lookbook.layout-3 .lookbook-intro-wrap {
  align-items: center;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  width: 7.5pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-left-color: #fff;
}
[class~="block-posts"][class~="layout-3"] [class~="post-image"]:hover img {
  -webkit-transform: scale(1.1);
}
[class~="block-posts"][class~="layout-3"] [class~="post-image"]:hover img {
  transform: scale(1.1);
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-bottom-color: #fff;
}
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  max-width: none;
}
[class~="block-video"]
  [class~="right"]
  [class~="block-widget-wrap"]
  [class~="block-widget-video"] {
  margin-right: auto;
}
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  margin-bottom: 0;
}
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  margin-right: 0;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  -o-border-radius: 50%;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-right-color: #fff;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  background-color: #eaeaea;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  -webkit-border-radius: 5px;
}
.block-video .block-widget-video .video-text .title {
  font-size: 0.520833333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-top-color: #fff;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover:before,
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  color: #fff;
}
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  margin-top: 0;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-left-width: 0.020833333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-bottom-width: 0.020833333in;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-right-width: 0.020833333in;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-top: auto;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  -moz-border-radius: 0.3125pc;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  font-weight: 400;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  border-top-width: 0.020833333in;
}
[class~="block-testimonial"][class~="layout-2"] [class~="testimonial-content"] {
  text-align: left;
}
.block-video .block-widget-video .video-text .title {
  font-weight: 300;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-left-width: 0.010416667in;
}
.block-video .block-widget-video .video-text .title {
  line-height: 3.4375pc;
}
[class~="block-testimonial"] [class~="testimonial-content"] [class~="item"] {
  padding-left: 11.25pt;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-categories"]
  a {
  font-size: 9pt;
}
[class~="block-banners"]
  [class~="block-widget-banner"][class~="layout-16"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  margin-top: 30px;
}
[class~="block-testimonial"] [class~="testimonial-content"] [class~="item"] {
  padding-bottom: 0;
}
[class~="block-testimonial"] [class~="testimonial-content"] [class~="item"] {
  padding-right: 11.25pt;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-categories"]
  a {
  text-transform: uppercase;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"] {
  opacity: 1;
}
[class~="block-instagram"] a[class~="instagram"] {
  overflow: hidden;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}
[class~="block-contact-map"] iframe {
  border-left-style: none;
}
.block-video .block-widget-video .video-text .title {
  margin-bottom: 20px;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-text"]
  [class~="description"] {
  margin-bottom: 0.416666667in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  -ms-border-radius: 3.75pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  -o-border-radius: 0.3125pc;
}
.block-video .content-video .modal-dialog .modal-body {
  position: relative;
}
[class~="block-testimonial"] [class~="testimonial-content"] [class~="item"] {
  padding-top: 0;
}
.block-video .content-video .modal-dialog .modal-body {
  padding-left: 0in;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  -ms-border-radius: 50%;
}
.block-video .content-video .modal-dialog .modal-body {
  padding-bottom: 0in;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"] {
  visibility: visible;
}
.block-video .content-video .modal-dialog .modal-body {
  padding-right: 0in;
}
.block-testimonial.layout-2 .slick-arrow {
  font-size: 20px;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  top: calc(50% - 13px);
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"],
[class~="block-products"]
  [class~="nav-tabs"][class~="small-text"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-size: 0.1875in;
}
.block-video .content-video .modal-dialog .modal-body {
  padding-top: 0in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar {
  border-radius: 5px;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  text-align: center;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 0.260416667in;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-width: 0.010416667in;
}
[class~="block-product-filter"] ul[class~="filter-items"],
[class~="block-newsletter"]
  [class~="newsletter-content"]
  [class~="your-email"] {
  width: 100%;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  position: absolute;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.3125pc;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  margin-bottom: 0.104166667in;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li {
  margin-right: 0;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  line-height: 100%;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  right: -30px;
}
[class~="block-product-filter"] ul[class~="filter-items"] {
  list-style-type: none;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  top: 0;
}
[class~="block-newsletter"] [class~="newsletter-content"] {
  display: flex;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  -moz-border-radius: 0.3125pc;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  z-index: 999;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  font-size: 2rem;
}
[class~="block-product-filter"] ul[class~="filter-items"] {
  padding-left: 0;
}
[class~="block-instagram"] a[class~="instagram"]:after {
  z-index: 2;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  -ms-border-radius: 5px;
}
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-text"] {
  margin-bottom: 0.625pc;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  -o-border-radius: 3.75pt;
}
[class~="block-contact-map"] iframe {
  border-bottom-style: none;
}
[class~="block-instagram"] a[class~="instagram"] img {
  -webkit-transition: transform 0.3s ease;
}
.block-post-tags ul li {
  padding-bottom: 4.5pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  border-radius: 0.052083333in;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  font-weight: normal;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  color: #fff;
}
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-left: auto;
}
[class~="block-video"]
  [class~="content-video"]
  [class~="modal-dialog"]
  [class~="close"] {
  opacity: 1;
}
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-bottom: auto;
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"],
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  text-transform: uppercase;
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-weight: 500;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-right-width: 0.010416667in;
}
[class~="block-product-filter"] ul[class~="filter-items"] {
  float: left;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] [class~="box-icon"] {
  margin-left: 0;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-thumb {
  background-color: #000;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  -o-border-radius: 50%;
}
[class~="block-product-filter"] ul[class~="filter-items"] {
  margin-bottom: 0;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  background-color: #eaeaea;
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  letter-spacing: 1.125pt;
}
[class~="block-instagram"] a[class~="instagram"] img {
  transition: transform 0.3s ease;
}
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-right: 0;
}
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-top: auto;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  -webkit-border-radius: 3.75pt;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] li,
[class~="block-feature"][class~="layout-2"] [class~="box"] [class~="box-icon"] {
  margin-top: 0;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] [class~="box-icon"] {
  margin-bottom: 0;
}
[class~="block-contact-info"] [class~="info-icon"] svg {
  width: 1em;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-top-width: 0.010416667in;
}
[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-bottom: 7.5pt;
}
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  margin-left: 0.052083333in;
}
[class~="block-feature"][class~="layout-2"] [class~="box"] [class~="box-icon"] {
  margin-right: 1.125pc;
}
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]:hover
  [class~="nav-link"],
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"][class~="active"] {
  color: #000;
}
[class~="block-newsletter"]
  [class~="newsletter-content"]
  input[type="submit"]:hover {
  background: rgba(0, 0, 0, 0.75);
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-color: currentColor;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  -moz-border-radius: 0.3125pc;
}
[class~="block-contact-map"] iframe {
  border-right-style: none;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-left-style: solid;
}
[class~="block-newsletter"] [class~="newsletter-text"] {
  margin-bottom: 22px;
}
[class~="block-contact-map"] iframe {
  border-top-style: none;
}
[class~="block-parallax"] {
  background-position: center center;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  input[type="email"][class~="bg-white"] {
  background: #fff;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  -ms-border-radius: 0.3125pc;
}
[class~="block-contact-info"] [class~="info-icon"] svg {
  height: 1em;
}
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  -webkit-transition: all 0.3s ease;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-style: solid;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  -o-border-radius: 3.75pt;
}
[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title"] {
  margin-bottom: 0.010416667in;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="center"]
  [class~="cat-item"]:first-child {
  margin-top: 90px;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-right-style: solid;
}
.block-feature.layout-3 .box .box-title {
  margin-bottom: 7.5pt;
}
[class~="block-product-cats"][class~="layout-3"]
  [class~="center"]
  [class~="cat-item"]:first-child {
  margin-bottom: 0.625in;
}
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover,
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="item-image"]:hover {
  animation-name: none;
}
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  transition: all 0.3s ease;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  color: #868686;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::-webkit-scrollbar-track {
  border-radius: 0.052083333in;
}
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"],
[class~="block-contact-info"] [class~="info-icon"] svg {
  position: relative;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="check"] a {
  font-weight: normal;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  content: "";
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  width: 16.5pt;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] {
  margin-left: -3.75pt;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"],
[class~="block-newsletter"]
  [class~="newsletter-form"]
  input[type="email"][class~="bg-white"] {
  border-left-color: #fff;
}
.block-product-cats.layout-7 .center .cat-item:first-child {
  margin-bottom: 3.4375pc;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover,
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  background: #000;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-top-style: solid;
}
[class~="block-countdown"] [class~="countdown-wrap"] [class~="countdown-title"],
[class~="block-feature"][class~="layout-4"] [class~="box"] [class~="box-icon"] {
  margin-left: 0;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  input[type="email"][class~="bg-white"] {
  border-bottom-color: #fff;
}
[class~="block-newsletter"] [class~="newsletter-title"] {
  font-size: 32px;
}
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  cursor: pointer;
}
.block-image.slider .item-image {
  display: flex;
}
[class~="block-video"]
  [class~="block-widget-video"]
  [class~="video-wrap"]
  [class~="video"]:hover,
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-title"] {
  color: #fff;
}
.block-image.slider .item-image {
  align-items: center;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  height: 16.5pt;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] [class~="box-icon"],
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] {
  margin-bottom: 0;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  position: absolute;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"] {
  margin-right: -3.75pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  input[type="email"][class~="bg-white"] {
  border-right-color: #fff;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] [class~="box-icon"] {
  margin-right: 7.5pt;
}
[class~="block-newsletter"] [class~="newsletter-title"],
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-title"] {
  font-weight: 300;
}
[class~="block-newsletter"] [class~="newsletter-title"] {
  text-transform: none;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  input[type="email"][class~="bg-white"] {
  border-top-color: #fff;
}
[class~="block-newsletter"] [class~="newsletter-title"] {
  letter-spacing: normal;
}
[class~="block-product-filter"] ul[class~="filter-items"][class~="image"],
[class~="block-feature"][class~="layout-4"] [class~="box"] [class~="box-icon"] {
  margin-top: 0;
}
.block-image.slider .item-image {
  justify-content: center;
}
[class~="block-feature"][class~="layout-4"] [class~="box"] [class~="box-icon"] {
  display: inline-flex;
}
[class~="block-feature"][class~="layout-4"]
  [class~="box"]
  [class~="box-title"] {
  margin-top: 0.3125pc;
}
[class~="block-feature"][class~="layout-4"]
  [class~="box"]
  [class~="box-title"] {
  margin-bottom: 0.3125pc;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  -o-border-radius: 20px;
}
[class~="block-contact-map"] iframe,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-color: currentColor;
}
.block-posts .post-item .post-content .post-title,
[class~="block-feature"][class~="layout-4"]
  [class~="box"]
  [class~="box-title"] {
  font-size: 12pt;
}
[class~="block-newsletter"] [class~="newsletter-title"] {
  margin-bottom: 11.25pt;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-left-width: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-bottom-width: 1.5pt;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-right-width: 0;
}
.block-post-search .search-from input[type="text"] {
  line-height: 33.75pt;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-title"] {
  font-size: 37.5pt;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  top: calc(50% - 11px);
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-width: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  width: 100%;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-contact-map"] iframe {
  border-left-color: currentColor;
}
.block-post-search .search-from input[type="text"] {
  height: 2.8125pc;
}
.block-post-search .search-from input[type="text"] {
  border-left-width: 0.0625pc;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-left-style: none;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  line-height: 0.5in;
}
.block-posts.white-bg .post .post-inner .post-content {
  padding-left: 0.104166667in;
}
.block-posts.white-bg .post .post-inner .post-content {
  padding-bottom: 22.5pt;
}
.block-posts .post-item .post-content .post-title,
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"] {
  margin-left: 0;
}
.block-posts.white-bg .post .post-inner .post-content {
  padding-right: 10px;
}
.block-posts.white-bg .post .post-inner .post-content {
  padding-top: 0;
}
[class~="block-sliders"] [class~="slick-dots"] li button:after {
  left: calc(50% - 11px);
}
.block-posts.layout-3 .post:first-child .post-inner {
  position: relative;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-image"] {
  max-height: 18.75pc;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
.block-post-search .search-from input[type="text"] {
  border-bottom-style: solid;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-title"] {
  margin-bottom: 15px;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-image"] {
  flex: 0 0 auto;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  max-width: 6.041666667in;
}
.block-post-search .search-from input[type="text"] {
  border-bottom-width: 0.0625pc;
}
.block-posts .post-item .post-content .post-title {
  margin-bottom: 0.010416667in;
}
[class~="block-countdown"] [class~="countdown-wrap"] [class~="countdown-title"],
.block-posts .post-item .post-content .post-title,
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"] {
  margin-right: 0;
}
[class~="block-product-cats"] ul li a:hover,
.block-product-cats ul li.current a {
  color: #000;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  margin-right: 7.5pt;
}
[class~="block-contact-info"] [class~="info-icon"] svg {
  display: block;
}
.block-posts .post-item .post-content .post-title {
  margin-top: 0;
}
[class~="block-banners"][class~="slider"]
  [class~="item"][class~="slick-center"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  letter-spacing: 3px;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-right-style: none;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"] {
  display: inline-block;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-color: #fff;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"] {
  font-size: 14px;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-title"] {
  margin-top: 75px;
}
.block-post-search .search-from input[type="text"] {
  border-right-width: 0.0625pc;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  z-index: 9;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-style: none;
}
.block-post-search .search-from input[type="text"],
.block-product-filter ul.filter-items li:hover span {
  border-top-width: 0.0625pc;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  text-align: center;
}
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"] {
  color: #868686;
}
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"] {
  margin-bottom: 3.75pt;
}
.block-post-search .search-from input[type="text"] {
  border-left-style: solid;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  content: "*";
}
[class~="block-contact-info"] [class~="info-title"] h2 {
  font-size: 0.364583333in;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-width: 0;
}
.block-post-search .search-from input[type="text"] {
  border-right-style: solid;
}
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-left: 0;
}
[class~="block-posts"][class~="layout-2"]
  [class~="post-content"]
  [class~="post-categories"] {
  margin-bottom: 0.3125pc;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  float: left;
}
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"] {
  margin-top: 0;
}
.block-post-search .search-from input[type="text"] {
  border-top-style: solid;
}
[class~="block-posts"][class~="layout-2"]
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 1.375pc;
}
.block-product-cats.layout-7 .cat-item .cat-title a h3 {
  line-height: 3.75pc;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-color: currentColor;
}
[class~="block-contact-info"] [class~="info-title"] h2,
.block-post-search .search-from input[type="text"] {
  padding-bottom: 0;
}
[class~="block-countdown"] [class~="countdown-img"] [class~="sub-img"],
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  position: absolute;
}
[class~="block-posts"][class~="layout-2"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-bottom: 0.75pc;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  bottom: 0;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  padding-left: 22.5pt;
}
.block-countdown .countdown-wrap .product-price,
[class~="block-countdown"] [class~="countdown-wrap"] [class~="product-title"],
.block-parallax .content .subtitle {
  font-size: 20px;
}
[class~="block-posts"][class~="layout-2"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-top: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-bottom-color: #000;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  padding-bottom: 30pt;
}
[class~="block-posts"][class~="white-bg"]
  [class~="post"]
  [class~="post-inner"] {
  background: #fff;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-right-color: #fff;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-top-color: #fff;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-width: 0;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-width: 0.0625pc;
}
.block-post-search .search-from input[type="text"] {
  border-left-color: #e6e6e6;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-color: currentColor;
}
.block-parallax .content .subtitle,
.block-countdown .countdown-wrap .product-price {
  color: #fff;
}
[class~="block-posts"][class~="white-bg"]
  [class~="post"]
  [class~="post-inner"] {
  margin-bottom: 0.3125in;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-right-width: 0;
}
[class~="block-posts"]
  ul[class~="posts-list"]
  li
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 15px;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  padding-right: 22.5pt;
}
.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"],
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-image: none;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  font-size: 9pt;
}
.block-post-search .search-from input[type="text"] {
  border-bottom-color: #e6e6e6;
}
.block-post-search .search-from input[type="text"] {
  border-right-color: #e6e6e6;
}
.block-post-search .search-from input[type="text"] {
  border-top-color: #e6e6e6;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-post-search .search-from input[type="text"] {
  border-image: none;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]
  [class~="post-inner"] {
  display: flex;
}
[class~="block-countdown"] [class~="countdown-img"] [class~="sub-img"] {
  top: -40px;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]
  [class~="post-inner"] {
  align-items: center;
}
[class~="block-countdown"] [class~="countdown-img"] [class~="sub-img"] {
  right: 0.78125in;
}
.block-post-search .search-from input[type="text"] {
  padding-left: 0.15625in;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-right: 0;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  font-size: 0.145833333in;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 15pt;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-image"]
  [class~="post-thumbnail"] {
  max-width: 100%;
}
.block-post-search .search-from input[type="text"],
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-top: 0;
}
.block-post-search .search-from input[type="text"] {
  padding-right: 52.5pt;
}
[class~="block-contact-info"] [class~="info-title"] h2 {
  margin-left: 0;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"],
.block-post-search .search-from input[type="text"] {
  width: 100%;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-bottom-width: 0.0625pc;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"] {
  left: 0;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-categories"] {
  margin-bottom: 5px;
}
.block-parallax .content .subtitle,
[class~="block-parallax"] [class~="content"] [class~="title"] {
  font-weight: 300;
}
[class~="block-contact-map"] iframe {
  border-right-color: currentColor;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-top-width: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-left: 0;
}
[class~="block-product-filter"] ul[class~="filter-items"] li {
  cursor: pointer;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"],
[class~="block-posts"][class~="layout-3"]
  [class~="post"]:first-child
  [class~="post-content"]
  [class~="post-title"] {
  margin-bottom: 0;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .button:hover,
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] i,
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  color: #000;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-style: none;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] i {
  font-size: 18px;
}
.block-parallax .content .subtitle,
[class~="block-contact-info"] [class~="info-item"] [class~="item-tilte"] h2 {
  text-transform: uppercase;
}
.block-parallax .content .subtitle {
  letter-spacing: 2.25pt;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-style: none;
}
[class~="block-parallax"] [class~="content"] [class~="title"],
.block-parallax .content .subtitle {
  line-height: 1;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"] {
  line-height: 18.75pt;
}
.block-parallax .content .subtitle,
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-bottom: 0.15625in;
}
[class~="block-parallax"] [class~="content"] [class~="title"] {
  color: #fff;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-width: 2px;
}
[class~="block-post-cats"] ul li [class~="count"] {
  width: 1.5625pc;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-style: solid;
}
[class~="block-post-cats"] ul li [class~="count"] {
  height: 0.260416667in;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  padding-bottom: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] i {
  line-height: 33.75pt;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 0.229166667in;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"] {
  text-transform: capitalize;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-color: #000;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="price"],
[class~="block-contact-info"] [class~="info-item"] [class~="item-tilte"] h2 {
  font-weight: 500;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-right-style: none;
}
[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-top-style: none;
}
[class~="block-contact-info"] [class~="info-title"] h2,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-bottom: 0;
}
[class~="block-contact-info"] [class~="info-item"] [class~="item-tilte"] h2 {
  font-size: 0.145833333in;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-image: none;
}
[class~="block-posts"][class~="layout-3"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-top: 0;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .button:hover {
  background-color: transparent;
}
[class~="block-post-cats"] ul li [class~="count"] {
  font-weight: 400;
}
[class~="block-parallax"] [class~="content"] [class~="title"] {
  font-size: 1.25in;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-color: currentColor;
}
[class~="block-parallax"] {
  background-repeat: no-repeat;
}
.block-posts.layout-3 .post:first-child .post-content .btn-read-more {
  display: none;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-left: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  height: 45px;
}
.block-lookbook.layout-2 .lookbook-wrap.right {
  margin-left: auto;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover,
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  background: #000;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  margin-right: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-color: currentColor;
}
[class~="block-contact-info"] [class~="info-title"] h2,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-top: 0;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-left: 0;
}
.block-product-filter ul.filter-items li:hover span {
  border-left-style: solid;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-family: Barlow Semi Condensed;
}
[class~="block-parallax"] [class~="content"] [class~="title"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  margin-bottom: 20px;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-size: 0.166666667in;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover,
[class~="block-parallax"] [class~="content"] [class~="description"],
[class~="block-lookbook"][class~="color-white"]
  [class~="lookbook-intro-wrap"]
  [class~="title"],
[class~="block-lookbook"][class~="color-white"]
  [class~="lookbook-intro-wrap"]
  [class~="sub-title"] {
  color: #fff;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]:hover
  [class~="content-lookbook"] {
  display: block;
}
[class~="block-parallax"] [class~="content"] [class~="description"],
[class~="block-contact-info"] [class~="info-item"] [class~="item-tilte"] h2 {
  letter-spacing: 2.25pt;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"]::placeholder {
  color: #000;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-style: normal;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  content: none;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-right-width: 0.0625pc;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  border-right-color: currentColor;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-bottom: 0;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-top-width: 0.0625pc;
}
[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-contact-map"] iframe {
  border-top-color: currentColor;
}
[class~="block-parallax"] [class~="content"] [class~="description"] {
  font-size: 22.5pt;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-top-width: 0.020833333in;
}
[class~="block-post-cats"] ul li [class~="count"] {
  -webkit-border-radius: 50%;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"],
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="sub-title"],
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  line-height: 1.8;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"] {
  margin-top: 15px;
}
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"] {
  padding-bottom: 0.625pc;
}
[class~="block-parallax"] [class~="content"] [class~="description"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  line-height: 1;
}
[class~="block-parallax"] [class~="content"] [class~="description"] {
  margin-bottom: 0.395833333in;
}
[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-contact-map"] iframe {
  border-image: none;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  color: #444;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  height: 0.5in;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .title {
  font-size: 30pt;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .title {
  font-weight: 300;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  font-size: 12px;
}
.block-lookbook.layout-2 .lookbook-intro-wrap .title,
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="sub-title"] {
  margin-bottom: 0.104166667in;
}
.block-testimonial
  .testimonial-content
  .item
  .testimonial-image.image-position-top {
  flex-wrap: wrap;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-width: 0;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"],
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="sub-title"] {
  font-size: 11.25pt;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"],
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-right: 0;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  text-transform: uppercase;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="sub-title"] {
  color: #868686;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  padding-top: 0;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  margin-bottom: 15pt;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  font-weight: 500;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-left: 0in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-bottom: 15pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-right: 0pt;
}
[class~="block-contact-info"] [class~="info-item"] [class~="item-tilte"] h2 {
  margin-bottom: 0.9375pc;
}
.block-contact-info .info-item .item-content p {
  margin-bottom: 3px;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  height: 3.125pc;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  line-height: 2.8125pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  font-size: 14px;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  height: 45px;
}
[class~="block-contact-map"] iframe {
  height: 330pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-top: 0pc;
}
[class~="block-newsletter"] [class~="newsletter-form"] {
  margin-left: 0;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  width: 100%;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  line-height: 37.5pt;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  padding-bottom: 0.5pc;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  font-weight: 400;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-width: 0;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  letter-spacing: 1.5pt;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  letter-spacing: normal;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-style: solid;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  position: absolute;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  > span:last-child {
  margin-right: 0;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-width: 0.0625pc;
}
[class~="block-post-cats"] ul li [class~="count"] {
  -moz-border-radius: 50%;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"] {
  width: auto;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  font-size: 1.125pc;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-width: 0;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-style: dashed;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-width: 0;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="title"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  margin-bottom: 1.25pc;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  padding-left: 0;
}
[class~="block-post-cats"] ul li [class~="count"] {
  -ms-border-radius: 50%;
}
[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="button"] {
  padding-bottom: 2.25pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-style: none;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-style: none;
}
[class~="block-contact-form"] [class~="block-title"] h2 {
  font-size: 35px;
}
[class~="block-contact-form"] [class~="contact-us-form"] label,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  color: #000;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"],
[class~="block-newsletter"] [class~="newsletter-form"] {
  margin-bottom: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  background: #000;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-bottom: 0px;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  width: 0.8125pc;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-color: #e7e7e7;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-left: 15pt;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  top: -1px;
}
[class~="block-contact-form"] [class~="contact-us-form"] label {
  margin-bottom: 10px;
}
.block-post-tags ul li {
  padding-right: 0.15625in;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  margin-right: 0.166666667in;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro-wrap"][class~="m-l-0"] {
  margin-left: 0;
}
[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-right: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  height: 0.135416667in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-top: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-bottom: 7.5pt;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  top: -3.75pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-right: 15pt;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-image: none;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  font-weight: 700;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-style: none;
}
[class~="block-product-filter"] [class~="filter-item"] [class~="check"] {
  cursor: pointer;
}
[class~="block-countdown"] [class~="countdown-img"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"],
[class~="block-contact-form"] [class~="contact-us-form"] label {
  position: relative;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"],
[class~="block-newsletter"] [class~="newsletter-form"] {
  display: flex;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="title"] {
  font-size: 0.520833333in;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-style: none;
}
[class~="block-contact-form"] [class~="contact-us-form"] label,
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="description"] {
  font-size: 12pt;
}
[class~="block-parallax"] {
  background-size: cover;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"] {
  display: inline-block;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-top: 7.5pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"] {
  vertical-align: middle;
}
[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label"] {
  font-size: 9.75pt;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="title"] {
  font-weight: 300;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-color: currentColor;
}
.block-product-filter .jslider .jslider-label {
  left: -0.041666667in;
}
[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label-to"] {
  left: auto;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="title"] {
  line-height: 0.572916667in;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-product-filter"] ul[class~="filter-items"] li span,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
.block-product-filter ul.filter-items li:hover span {
  border-bottom-style: solid;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-title"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"],
[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-top: 0;
}
[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label-to"] {
  right: -0.1875pc;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-color: currentColor;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  align-items: center;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  position: absolute;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  top: 0;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="description"] {
  width: 4.583333333in;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  right: 0;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="description"] {
  max-width: 440px;
}
[class~="block-lookbook"][class~="layout-3"]
  [class~="lookbook-intro"]
  [class~="description"] {
  margin-bottom: 30px;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  justify-content: space-between;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-title"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-left: 0;
}
[class~="block-post-cats"] ul li [class~="count"] {
  -o-border-radius: 50%;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-title"] {
  margin-bottom: 0.104166667in;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  font-size: 0;
}
[class~="block-countdown"] [class~="countdown-wrap"],
[class~="block-post-cats"] ul li [class~="count"] {
  text-align: center;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-width: 0.010416667in;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  height: 33.75pt;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-bottom-width: 0.010416667in;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-right-width: 0.010416667in;
}
[class~="block-post-cats"] ul li [class~="count"] {
  background: #f1f1f1;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  font-weight: 400;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-top-width: 0.010416667in;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-color: currentColor;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  -webkit-transform: rotateY(180deg);
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-product-filter"] ul[class~="filter-items"] li span,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-right-style: solid;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"] {
  width: auto;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-style: solid;
}
[class~="block-countdown"] [class~="countdown-img"] {
  padding-left: 11.25pt;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  -webkit-border-radius: 50%;
}
[class~="block-parallax"] {
  background-attachment: fixed;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
[class~="block-countdown"] [class~="countdown-img"],
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-bottom: 0;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  transform: rotateY(180deg);
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  background: transparent;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  padding-left: 1.25pc;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"],
[class~="block-product-filter"] ul[class~="filter-items"] li span,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-top-style: solid;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-color: currentColor;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-left-color: #e9e9e9;
}
[class~="block-post-cats"] ul li [class~="count"] {
  line-height: 0.260416667in;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-image: none;
}
[class~="block-post-cats"] ul li [class~="count"] {
  font-size: 0.145833333in;
}
[class~="block-contact-form"] [class~="block-title"] [class~="sub-title"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  font-size: 1pc;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-bottom-color: #e9e9e9;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-right-color: #e9e9e9;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after {
  border-top-color: #fff;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-title"] {
  margin-right: 0;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-top-color: #e9e9e9;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  font-size: 0;
}
.block-post-tags ul li {
  padding-top: 4.5pt;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  padding-right: 1.25pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-color: #000;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-left: 22.5pt;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-title"] {
  font-size: 22px;
}
[class~="block-testimonial"][class~="layout-1"]:hover
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 0.052083333in;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  right: -6pt;
}
[class~="block-countdown"] [class~="countdown-img"],
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"],
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-top: 0;
}
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  border-radius: 0;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  border-image: none;
}
[class~="block-testimonial"][class~="layout-1"]:hover
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 5px;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-right: 22.5pt;
}
[class~="block-newsletter"][class~="layout-2"][class~="one-col"][class~="align-left"]
  [class~="newsletter-form"] {
  margin-left: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  -moz-border-radius: 50%;
}
[class~="block-countdown"] [class~="countdown-img"] {
  padding-right: 11.25pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  color: transparent;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-color: #ddd;
}
[class~="block-newsletter"][class~="layout-2"][class~="one-col"][class~="align-left"]
  [class~="newsletter-form"] {
  margin-right: 0;
}
.block-newsletter.layout-2.one-col.align-left .newsletter-title-wrap {
  text-align: left;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:hover {
  background: transparent;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  > span,
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  display: inline-block;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  padding-bottom: 0.239583333in;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-bottom-color: #000;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  width: 0.645833333in;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  height: 46.5pt;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount,
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="product-title"]
  a:hover {
  color: #000;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="form-button"],
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount,
[class~="block-parallax"] [class~="content"],
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  > span {
  text-align: center;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  -ms-border-radius: 50%;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  [class~="form-button"] {
  margin-top: 30px;
}
[class~="block-post-cats"] ul li [class~="count"] {
  display: block;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  z-index: 2;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-right-color: #000;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  cursor: pointer;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  height: 42px;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  font-size: 0.270833333in;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-top-color: #000;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  line-height: 31.5pt;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-bottom-color: #ddd;
}
[class~="block-parallax"] {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.block-image.slider .item-image img:hover {
  transform: scale(1.1);
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  text-transform: uppercase;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-image: none;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"]
  [class~="icon-quote"] {
  color: #dedede;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  font-size: 10.5pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  letter-spacing: 0.1875pc;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"]
  [class~="icon-quote"] {
  font-size: 22.5pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"],
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  font-weight: 500;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"],
.block-post-tags ul li {
  margin-left: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  -o-border-radius: 50%;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  background: #fff;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-right-color: #ddd;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  height: 3.125pc;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  padding-right: 20px;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
.block-product-filter .layout-slider {
  width: 100%;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  > span {
  margin-right: 11.25pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-left: 37.5pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  line-height: 37.5pt;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"] {
  padding-top: 0.239583333in;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-bottom: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  margin-bottom: 1.25pc;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount {
  line-height: 3.875pc;
}
.block-product-filter .layout-slider {
  padding-left: 3.75pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-right: 37.5pt;
}
.block-countdown
  .countdown-wrap
  .countdown
  .countdown-content
  .countdown-amount,
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  border-radius: 50%;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-left: 15pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-bottom: 0.9375pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-bottom: 7.5pt;
}
.block-post-tags ul li {
  margin-bottom: 0.25pc;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-top-color: #ddd;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-right: 0;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a:hover,
[class~="block-post-cats"] ul li[class~="current"] a {
  color: #000;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  color: #fff;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-top: 0;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown-button"]
  a:hover {
  background-color: #fff;
}
[class~="block-post-tags"] ul li:hover,
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  background: #000;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  input[type="checkbox"] {
  opacity: 0;
}
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  float: left;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-family: "EB Garamond", Sans-serif;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-left-width: 0.010416667in;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"],
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  display: block;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  input[type="checkbox"] {
  cursor: pointer;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-bottom-width: 0.010416667in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  input[type="checkbox"] {
  position: absolute;
}
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  margin-left: -0.3125pc !important;
}
[class~="block-parallax"] {
  margin-top: 0in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  content: "";
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-right-width: 0.010416667in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-size: 21pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-style: italic;
}
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  margin-top: 2.25pc;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  line-height: 28.5pt;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  margin-bottom: 25px;
}
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  font-size: 0.145833333in;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"] {
  display: flex;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-top-width: 0.010416667in;
}
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  color: black;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  display: none;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"] {
  align-items: center;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-left-style: solid;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-bottom-style: solid;
}
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-image: none;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"],
.block-product-filter
  .layout-slider
  span.jslider.jslider_plastic
  .jslider-value {
  background: transparent;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  left: 0.375pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"],
.block-product-filter ul.filter-items li:hover span {
  border-right-style: solid;
}
.block-product-filter ul.filter-items li:hover span,
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-top-style: solid;
}
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"] {
  width: 100%;
}
[class~="block-post-tags"] ul li:hover,
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-left-color: #000;
}
[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-form"] {
  margin-right: auto;
}
.block-product-filter .layout-slider {
  padding-bottom: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"],
[class~="block-post-tags"] ul li:hover {
  border-bottom-color: #000;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  font-size: 9pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  top: 0.125pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-right-color: #000;
}
[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-title-wrap"] {
  text-align: center;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  font-weight: 500;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  text-transform: uppercase;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  color: #fff;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  margin-top: 10px;
}
[class~="block-post-archives"] ul li a:hover,
[class~="block-post-cats"] ul li a:hover,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  color: #000;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-right: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-top-color: #000;
}
[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-title-wrap"],
.block-product-filter .layout-slider {
  margin-bottom: 0.208333333in;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"] {
  margin-left: 10px;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-content"]
  [class~="countdown-text"] {
  letter-spacing: 0.015625in;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-right: 15pt;
}
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:hover {
  background: rgba(0, 0, 0, 0.75);
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  width: 3.75pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  border-image: none;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  -webkit-transition: all 0.3s ease;
}
[class~="block-instagram"] [class~="right"] [class~="row"]:first-child {
  margin-bottom: 1.875pc;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-deal"] {
  margin-left: 0;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-deal"] {
  margin-bottom: 1.875pc;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-deal"] {
  margin-right: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-top: 7.5pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  transition: all 0.3s ease;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  height: 9pt;
}
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  cursor: pointer;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  font-weight: 400;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-left-width: 0.010416667in;
}
.block-product-filter .layout-slider {
  padding-right: 3.75pt;
}
[class~="block-parallax"] {
  margin-bottom: 60px;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-left-width: 0;
}
[class~="block-countdown"]
  [class~="countdown-wrap"]
  [class~="countdown"]
  [class~="countdown-deal"] {
  margin-top: 1.875pc;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-bottom-width: 0.03125in;
}
[class~="block-countdown"]
  [class~="countdown-img"]
  [class~="sub-img"]
  [class~="image-center"] {
  position: absolute;
}
[class~="block-post-tags"] ul li:hover a {
  color: #fff;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-right-width: 0.1875pc;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-bottom-width: 0.010416667in;
}
.block-post-tags ul li {
  margin-right: 0.0625in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-top-width: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-right-width: 0.010416667in;
}
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-product-filter .layout-slider {
  padding-top: 0;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-width: 0.010416667in;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-left-style: solid;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-bottom-style: solid;
}
[class~="block-countdown"]
  [class~="countdown-img"]
  [class~="sub-img"]
  [class~="image-center"] {
  top: 50%;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-style: solid;
}
[class~="block-countdown"]
  [class~="countdown-img"]
  [class~="sub-img"]
  [class~="image-center"] {
  left: 50%;
}
[class~="block-countdown"]
  [class~="countdown-img"]
  [class~="sub-img"]
  [class~="image-center"] {
  transform: translate(-50%, -50%);
}
[class~="block-parallax"] {
  padding-left: 11.25pt;
}
[class~="block-post-search"] [class~="search-from"] {
  position: relative;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-style: solid;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-left-color: #666;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-bottom-color: #666;
}
[class~="block-countdown"]
  [class~="countdown-img"]
  [class~="sub-img"]
  [class~="image-center"] {
  z-index: 1;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-right-color: #666;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-top-color: #666;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-post-tags"] ul li:hover {
  border-right-color: #000;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-image: none;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-left-color: #000;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  margin-right: 12px;
}
[class~="block-post-search"] [class~="search-from"] {
  overflow: hidden;
}
[class~="block-post-tags"] ul li:hover,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-color: #000;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-bottom-color: #000;
}
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"],
.block-post-tags ul li {
  margin-top: 0;
}
.block-contact-info .info-icon,
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  display: inline-block;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  width: 1pc;
}
.block-contact-info .info-icon {
  font-size: 48.75pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  height: 12pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  cursor: pointer;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  -webkit-border-radius: 0.020833333in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  -moz-border-radius: 2px;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  -ms-border-radius: 2px;
}
[class~="block-parallax"] {
  padding-bottom: 1.875in;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  -o-border-radius: 0.020833333in;
}
.block-contact-info .info-icon {
  margin-bottom: 7.5pt;
}
[class~="block-parallax"] {
  padding-right: 15px;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  -webkit-transform: rotate(45deg);
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 2px;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  -ms-transform: rotate(45deg);
}
[class~="block-contact-info"] [class~="info-title"] {
  margin-bottom: 45px;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-width: 0.75pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-width: 0.75pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  transform: rotate(45deg);
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-width: 0.75pt;
}
.block-post-tags ul li {
  text-transform: capitalize;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-top-width: 0.75pt;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-style: solid;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-style: solid;
}
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"]:hover {
  font-style: italic;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-color: #dbdbdb;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-color: #dbdbdb;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-color: #dbdbdb;
}
[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-image: none;
}
[class~="block-contact-form"] [class~="block-title"],
[class~="block-contact-info"] {
  text-align: center;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-top-color: #dbdbdb;
}
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  background: #fff;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-image: none;
}
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  background-color: #fff;
}
[class~="block-contact-form"] [class~="block-title"] {
  margin-bottom: 2.5pc;
}
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  max-width: 290px;
}
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  margin-left: auto;
}
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  margin-bottom: 0;
}
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  margin-right: auto;
}
[class~="block-contact-form"]
  [class~="contact-us-form"]
  [class~="button"]:hover {
  opacity: 0.8;
}
.block-product-filter ul.filter-items li:hover span {
  border-left-color: #000;
}
.block-sliders.layout-4
  .slick-current
  .item-content
  .item-info.animation-top
  .content {
  -webkit-transform: translate(0);
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  height: 0.1875pc;
}
.block-post-tags ul li {
  position: relative;
}
.block-product-filter ul.filter-items li:hover span {
  border-bottom-color: #000;
}
[class~="block-parallax"] {
  padding-top: 135pt;
}
.block-product-filter ul.filter-items li:hover span {
  border-right-color: #000;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"][class~="jslider-value-to"] {
  float: right;
}
.block-sliders.layout-4
  .slick-current
  .item-content
  .item-info.animation-top
  .content {
  transform: translate(0);
}
.block-product-filter ul.filter-items li:hover span {
  border-top-color: #000;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"][class~="jslider-value-to"] {
  margin-right: -10px;
}
.block-product-filter ul.filter-items li:hover span {
  border-image: none;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  -webkit-border-radius: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  -moz-border-radius: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  -ms-border-radius: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  -o-border-radius: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="l"],
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="r"] {
  display: none;
}
[class~="block-sliders"][class~="layout-9"][class~="diff-col"]
  [class~="item"]
  [class~="item-info"]
  [class~="content"]
  [class~="content-wrap"] {
  padding-left: 63.75pt;
}

/*===================================================================
6. Product Functions
====================================================================*/
[class~="wishlist-popup"][class~="show"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  top: 50% !important;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"] {
  vertical-align: middle;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"] {
  width: 1pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  cursor: pointer;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"] {
  font-weight: 400;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"] {
  font-size: 12pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  background: #fff;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  color: #000;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-left: 1.25pc;
}
[class~="wishlist-items"] tr td:first-child,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th:first-child {
  padding-left: 0.208333333in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  background: transparent;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  position: absolute;
}
[class~="wishlist-popup"][class~="show"] {
  opacity: 1;
}
.wishlist-empty,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  text-align: center;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"] {
  width: 75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  position: relative;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
.wishlist-items .wishlist-item .wishlist-item-remove span:before,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  display: inline-block;
}
[class~="wishlist-popup"][class~="show"] {
  visibility: visible;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-left-width: medium;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  z-index: 9999;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-time"] {
  font-size: 0.875pc;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-collapse: collapse;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-actions"] {
  text-align: right;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-width: 41.875pc !important;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:hover:before {
  color: #cc6055;
}
[class~="wishlist-popup"] [class~="wishlist-popup-inner"] {
  display: block;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  width: 25px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  height: 12pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-width: 480px;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  .remove {
  text-transform: none;
}
[class~="wishlist-items"] {
  margin-left: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-count-wrapper:before {
  content: "(";
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  line-height: 12pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}
[class~="wishlist-items"] tr td {
  border-left-width: 0;
}
.wishlist-items .wishlist-item .wishlist-item-add a:hover {
  opacity: 0.8;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  height: 1.5625pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span[class~="removing"]:before {
  animation: remove-spinner 1s linear infinite;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 3pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  padding-left: 0.0625in;
}
[class~="wishlist-items"] {
  margin-bottom: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -webkit-border-radius: 0 !important;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  z-index: 8 !important;
}
[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  width: 100%;
}
[class~="wishlist-items"] {
  margin-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-count-wrapper"]:after {
  content: ")";
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  text-transform: uppercase;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  content: "";
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -moz-border-radius: 0 !important;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -ms-border-radius: 0 !important;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -o-border-radius: 0 !important;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-bottom: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  background: #fff;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  cursor: pointer;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  .remove,
.wishlist-items .wishlist-item .wishlist-item-name a {
  font-weight: 400;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  width: 90%;
}
.wishlist-items .wishlist-item .wishlist-item-name a {
  font-size: 12pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  height: auto;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-height: 90%;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added"] {
  display: none;
}
[class~="wishlist-items"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  margin-top: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  font-size: 1.375pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  position: absolute;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  width: 71.25pt !important;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  color: #000;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  top: 40%;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  padding-left: 20px;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  content: "";
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before,
[class~="wishlist-popup"] [class~="wishlist-popup-inner"] {
  height: 100%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-left-width: 0.125pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  padding-bottom: 0.0625in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  left: 50%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-bottom-width: 0.125pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transform: translate3d(-50%, -50%, 0);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span[class~="removing"]:before {
  -webkit-animation: remove-spinner 1s linear infinite;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -webkit-transform: translate3d(-50%, -50%, 0);
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  line-height: 2.1875pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  -webkit-transition: all 0.1s ease;
}
[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  position: relative;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  .remove {
  text-decoration: underline;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  transition: all 0.1s ease;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  content: "";
}
[class~="wishlist-popup"] {
  position: fixed;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-right: 1.25pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-top: 0;
}
[class~="wishlist-items"] tr td {
  border-bottom-width: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  padding-bottom: 11.25pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-bottom-width: medium;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  display: flex;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-left-width: 0.010416667in;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  padding-right: 15px;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  font-size: 20px;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  font-family: feather;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  flex-direction: column;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  margin-left: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  display: inline-block;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-width: medium;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-bottom-width: 0.010416667in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  margin-bottom: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  font-weight: 500;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  margin-right: 3.75pt;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  content: "";
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-right-width: 0.010416667in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  padding-right: 0.0625in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  text-transform: capitalize;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]:before {
  top: 3px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-top-width: 0.010416667in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  height: 45pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-width: 1.5pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  padding-top: 0.0625in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  line-height: 3.75pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-left-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-bottom-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-bottom-color: #e5e5e5;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  position: absolute;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -webkit-transition: all 0.5s;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-image: none;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before,
[class~="wishlist-popup"] {
  width: 100%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -moz-transition: all 0.5s;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-top: 11.25pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -ms-transition: all 0.5s;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  height: 100%;
}
[class~="wishlist-items"] tr td {
  border-right-width: 0;
}
[class~="wishlist-popup"],
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  top: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  height: 0.125pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  top: 0.166666667in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  flex: 0 0 auto;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-right-width: 0.125pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-right-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  display: block;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before,
[class~="wishlist-popup"] {
  left: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  margin-right: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-bottom-width: 1.5pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  right: 1pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  -o-transition: all 0.5s;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-top-width: 0.125pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-radius: 100%;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  .remove {
  cursor: pointer;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-top-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-bottom: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  width: 0.625pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-left-color: #e5e5e5;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-size: 1pc;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  background-color: rgba(255, 255, 255, 0.7);
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  margin-top: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  overflow: hidden;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead {
  z-index: 8;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  top: 50%;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  opacity: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  .remove {
  margin-left: 3.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-left: 11.25pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-bottom: 11.25pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-right: 11.25pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-bottom-color: #e5e5e5;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  visibility: hidden;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-right-color: #e5e5e5;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  position: relative;
}
.compare-popup .compare-popup-inner .compare-table .compare-table-inner:before {
  z-index: 7;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-family: "feather";
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-top-color: #e5e5e5;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  speak: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  border-image: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-left-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  padding-left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-bottom-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:after,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:before {
  -webkit-transform: rotate(0deg);
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  display: block;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-right-style: solid;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  width: 100%;
}
[class~="wishlist-items"] tr td {
  border-top-width: 0;
}
.wishlist-popup .wishlist-empty {
  margin-top: 18.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="wishlist-popup"] {
  height: 100%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:before,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:after {
  transform: rotate(0deg);
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  min-height: 5pc;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  box-sizing: border-box;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  -webkit-border-radius: 100%;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  flex: 1 1 auto;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  width: auto;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-right-width: 1.5pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  padding-bottom: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  background-color: #292a30;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  min-width: 2.083333333in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-left-color: #000;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  -moz-border-radius: 100%;
}
[class~="compare-popup"][class~="active"] {
  opacity: 1;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  padding-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  left: calc(50% - 5px);
}
[class~="wishlist-items"] tr td {
  border-left-style: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  text-rendering: optimizeLegibility;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"]
  > p:last-child,
.quickview-container .quickview-single-info .description > p:last-child {
  margin-bottom: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  -webkit-font-smoothing: antialiased;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-top-width: 1.5pt;
}
[class~="wishlist-items"] tr td {
  border-bottom-style: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  font-size: 14px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  padding-top: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  position: fixed;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  position: absolute;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  margin-left: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  top: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td:first-child {
  z-index: 7;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  -ms-border-radius: 100%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  margin-top: -0.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  background-color: hsla(0, 0%, 53%, 0.35);
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  z-index: 99999997;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  -o-border-radius: 100%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  background: transparent;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  pointer-events: auto;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td:first-child {
  padding-left: 0.15625in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-left-width: 0.75pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  -webkit-transition: all 0.3s ease;
}
[class~="wishlist-popup"] {
  z-index: 99999989;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-bottom-color: #000;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-right-color: #000;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  margin-bottom: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  * {
  box-sizing: border-box;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-style: solid;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  max-width: 225pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-bottom-width: 0.75pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-right-width: 0.75pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-right: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-top: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  text-align: center;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-top-width: 0.75pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  box-shadow: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  ins {
  text-decoration: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-width: medium;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-top-color: #000;
}
[class~="wishlist-items"] tr td {
  border-right-style: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  cursor: pointer;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-bottom-style: solid;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  line-height: 18pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-bottom-width: medium;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  top: 7.5pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover {
  border-image: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transition: all 0.3s ease;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  background: #000;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-width: medium;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  background-color: #fff;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  right: 0.625pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:hover:after {
  -webkit-transform: rotate(0deg);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-width: medium;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  color: #000;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  overflow-x: hidden;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  vertical-align: middle;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-right-style: solid;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  content: "";
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  overflow-y: auto;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  color: #fff !important;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  z-index: 10000;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  width: 27pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-top-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-left-color: hsla(0, 0%, 53%, 0.35);
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr:hover
  td {
  background-color: #f8f8f8;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-bottom-color: hsla(0, 0%, 53%, 0.35);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  height: 0.375in;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-style: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  background: #fff;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  position: absolute;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-width: 0.5625pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-right-color: hsla(0, 0%, 53%, 0.35);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-bottom-width: 0.5625pc;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a {
  outline: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"] tr td,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-top-style: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-bottom-style: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  border-left-color: #000;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
[class~="compare-popup"] [class~="compare-popup-inner"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a {
  display: block;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-top-color: hsla(0, 0%, 53%, 0.35);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-right-width: 0.5625pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-top-width: 0.5625pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-style: solid;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-style: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  border-image: none;
}
[class~="wishlist-popup"] {
  background: rgba(0, 0, 0, 0.7);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-bottom-style: solid;
}
[class~="wishlist-items"] tr td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-left-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  height: 0.125pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  padding-left: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-color: transparent;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  padding-bottom: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-bottom-color: transparent;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  width: 0.625pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-bottom-color: currentColor;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  padding-right: 0;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-style: normal;
}
[class~="wishlist-popup"] {
  opacity: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-right-color: transparent;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-color: currentColor;
}
[class~="compare-popup"] [class~="compare-popup-inner"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  width: 100%;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close,
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-weight: 400;
}
[class~="compare-popup"][class~="active"] {
  visibility: visible;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-top-color: transparent;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  border-bottom-color: #000;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  line-height: 36pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-right-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  padding-top: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  border-top-width: 1px;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  border-right-color: #000;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  margin-left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:hover {
  border-top-color: #000;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-image: none;
}
[class~="wishlist-popup"] {
  font-size: 0.875pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-top-color: currentColor;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  margin-bottom: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-image: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  color: #43454b;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  -webkit-border-radius: 100%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  top: 50%;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  text-transform: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  font-weight: 700;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  left: calc(50% - 5px);
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  margin-right: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-left-style: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  -moz-border-radius: 100%;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  border-top-style: solid;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close {
  color: #999;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  border-top-color: #e5e5e5;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  margin-top: -0.75pt;
}
[class~="compare-popup"] [class~="compare-popup-inner"] {
  height: 100%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  width: 2.375pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  height: 2.875pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-left: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-bottom: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  position: absolute;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-bottom-style: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  border-image: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  background-color: hsla(0, 0%, 53%, 0.35);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  -ms-border-radius: 100%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  -o-border-radius: 100%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"][class~="wishlist-notice-show"] {
  top: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-top: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  height: 45pt;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-variant: normal;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  line-height: 1;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  right: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-right: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot,
.quickview-popup .quickview-container .product-detail {
  overflow: hidden;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  background: transparent;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-top: 0;
}
.quickview-popup .quickview-container .product-detail {
  display: block;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-style: none;
}
.quickview-popup .quickview-container .product-detail {
  background: #fff;
}
[class~="wishlist-popup"] {
  visibility: hidden;
}
[class~="quickview-popup"] [class~="quickview-container"] [class~="slick-list"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-track"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  display: flex;
}
.quickview-popup .quickview-container .product-detail {
  color: #868686;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  line-height: 18pt;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  flex-wrap: wrap;
}
[class~="wishlist-items"] tr td:last-child {
  padding-right: 15pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-color: #a9a8a8;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  padding-left: 0.208333333in;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  align-items: center;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-width: medium;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  -webkit-transition: all 0.3s ease;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  flex: 0 0 auto;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-bottom-width: medium;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-left: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"],
[class~="compare-popup"] [class~="compare-popup-inner"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  position: relative;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  transition: all 0.3s ease;
}
.quickview-popup .quickview-container .product-detail {
  max-width: 750pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  text-align: left;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"] {
  width: 55%;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  font-size: 14px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-bottom: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot {
  text-transform: uppercase;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-right: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-right-width: medium;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  background-color: #f7f7f7;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  background-color: #fff;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  text-transform: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  border-radius: 2px;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-width: medium;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"] tr td {
  border-bottom-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  padding-right: 0.208333333in;
}
[class~="wishlist-popup"] {
  -webkit-transition: opacity 0.3s;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  width: 45%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  padding-left: 33.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  -moz-border-radius: 2px;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  padding-left: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-style: none;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  padding-bottom: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  -webkit-border-radius: 2px;
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price-single"],
[class~="single-product"] [class~="entry-summary"] [class~="price-single"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  width: 100%;
}
[class~="wishlist-items"] tr td,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-right-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  padding-right: 33.75pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-bottom-color: #a9a8a8;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-bottom-style: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  padding-top: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  height: 100%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"] tr td {
  border-top-color: currentColor;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  min-width: 39pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  color: #fff;
}
[class~="quickview-popup"][class~="active"] {
  opacity: 1;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  max-height: 100%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-right-style: none;
}
[class~="wishlist-popup"] {
  transition: opacity 0.3s;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-right-color: #a9a8a8;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-style: none;
}
[class~="quickview-popup"][class~="active"] {
  visibility: visible;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  flex-wrap: wrap;
}
[class~="wishlist-popup"] {
  box-sizing: border-box;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  display: inline-block;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"] {
  overflow: hidden;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  line-height: 21pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-left-width: 0.75pt;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-color: currentColor;
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price-single"],
[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  line-height: 100%;
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price-single"],
[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  display: flex;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  /* background-image: url("../img/checkbox-dark.svg"); */
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price-single"],
[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  margin-bottom: 15pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-top-color: #000;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  flex-direction: row;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-image: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  width: 15px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  height: 15px;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  background-size: 16px 0.166666667in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  transform-origin: 50% 50%;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-bottom-width: 0.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-repeat: no-repeat;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  -webkit-transform: rotate(-45deg);
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  justify-content: space-between;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-right-width: 0.75pt;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  padding-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  align-items: center;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  background-position: center left;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  min-height: 39pt;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-top-width: 0.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-color: #eee;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  transform: rotate(-45deg);
}
.quickview-popup .quickview-container {
  position: fixed;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  width: 2.375pc;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  padding-left: 20px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  /* background-image: url("../img/remove-dark.svg"); */
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  height: 2.875pc;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  padding-top: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"] {
  width: 100%;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  -webkit-font-smoothing: antialiased;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  position: absolute;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  left: 0;
}
.quickview-popup .quickview-container {
  z-index: 10;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-left-style: solid;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-top-style: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  font-size: 0.135416667in;
}
.wishlist-popup * {
  box-sizing: border-box;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  -webkit-border-radius: 50%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  -webkit-transform: rotate(45deg);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-bottom-style: solid;
}
.quickview-popup .quickview-container .quickview-close:hover {
  background: #e5e5e5;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  line-height: 0.364583333in;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-left-style: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  bottom: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  -moz-border-radius: 50%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  background: #000;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-right-style: solid;
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="single-product"] [class~="entry-summary"] [class~="price"] {
  color: #000;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-bottom-style: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-left: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-top-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-left-width: medium;
}
[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="single-product"] [class~="entry-summary"] [class~="price"] {
  font-size: 25px;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-bottom-width: medium;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="single-product"] [class~="entry-summary"] [class~="price"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  display: inline-block;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  font-weight: 500;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transform: rotate(45deg);
}
.quickview-popup .quickview-container {
  left: 50%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-left: 0.260416667in;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-position: center;
}
[class~="single-product"] [class~="entry-summary"] [class~="price"],
[class~="quickview-container"] [class~="entry-summary"] [class~="price"] {
  vertical-align: middle;
}
.quickview-popup .quickview-container {
  top: 50%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-right-width: medium;
}
[class~="single-product"] [class~="entry-summary"] [class~="price"],
[class~="quickview-container"] [class~="entry-summary"] [class~="price"] {
  font-weight: 400;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  float: right;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-left-color: #000;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-width: medium;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-top: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-bottom-color: #000;
}
.compare-popup {
  position: fixed;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-right-color: #000;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-right-style: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-top-color: #000;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  border-image: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  align-items: center;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  top: calc(50% - 10px);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  -ms-border-radius: 50%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  line-height: 36pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  font-size: 15pt;
}
.compare-popup {
  top: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  padding-bottom: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-rating"] {
  display: flex;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  display: inline-block;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  margin-right: 7.5pt;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  width: 5.3125pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-right: 0.260416667in;
}
.compare-popup {
  left: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-left-color: currentColor;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  font-size: 9.75pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-top-style: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-left-style: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  background: #000;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  margin-bottom: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-bottom-color: currentColor;
}
.compare-popup {
  width: 100%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-left-width: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-bottom-style: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  padding-left: 9pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-right-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-bottom-width: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  cursor: pointer;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  padding-top: 0;
}
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  -moz-osx-font-smoothing: grayscale;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-top-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  text-transform: uppercase;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  ins
  span {
  color: #ff4545;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  -o-border-radius: 50%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  text-decoration: unset !important;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-right-width: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-top-width: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-right-style: none;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  padding-right: 9pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  line-height: 31px;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  padding-left: 18.75pt;
}
.quickview-popup .quickview-container {
  -webkit-transform: translate(-50%, -50%);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  background: none;
}
.quickview-popup .quickview-container {
  transform: translate(-50%, -50%);
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  -webkit-animation: 2s linear 0s normal none infinite running o;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  display: flex;
}
.compare-popup {
  height: 100%;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="review-link"] {
  position: relative;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="review-link"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"] {
  top: -3.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-image: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="review-link"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"] {
  color: #868686;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  flex: 1;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="review-link"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"] {
  font-size: 14px;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a {
  margin-top: 0.9375pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  padding-bottom: 0;
}
.wishlist-popup .wishlist-popup-inner .wishlist-popup-content > div {
  align-self: stretch;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  margin-left: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"]
  span:before,
.single-product .quickview-single-info .star-rating span:before {
  letter-spacing: 0.3pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-style: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  padding-right: 18.75pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  display: inline-block;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-bottom: 13px;
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  padding-top: 0.3125in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  text-align: center;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  content: "";
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-top: 30px;
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  padding-bottom: 11.25pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th {
  border-image-width: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  padding-top: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after,
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  position: absolute;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  height: 2px;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-bottom-color: currentColor;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-right-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  float: unset;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-left-width: 0.125pc;
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-width: 0.75pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-top-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  max-height: 30.3125pc;
}
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-color: #efefef;
}
[class~="wishlist-items"] tr td,
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-image: none;
}
.compare-popup {
  z-index: 99999979;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  padding-right: 0.5pc;
}
.compare-popup {
  box-sizing: border-box;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-size: 15pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-bottom-width: 0.125pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  overflow-x: hidden;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  width: 100%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-right-width: 0.125pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  text-indent: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  top: 50%;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-bottom: 0.9375pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-right: 0.625pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-top-width: 0.125pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-left-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  display: block !important;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-right-width: medium;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  left: 0;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  margin-top: -0.010416667in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-family: "feather";
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-collapse: collapse;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  background-color: #fff;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-bottom-style: solid;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  margin-bottom: 3.75pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-right-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  visibility: visible !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-family: eleganticons;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  width: 0.395833333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  padding-bottom: 11.25pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  -webkit-transition: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-left-color: #000;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  margin-right: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-style: normal;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-width: 0.75pt;
}
.wishlist-items .wishlist-item-remove span:before {
  content: "" !important;
}
.compare-popup {
  pointer-events: none;
}
[class~="quickview-container"] [class~="product-title"] {
  margin-top: 0;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  top: 2.25pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  background-color: rgba(0, 0, 0, 0.5);
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-style: solid;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  margin-right: 0.0625in;
}
[class~="quickview-container"] [class~="product-title"] {
  margin-bottom: 0.125in;
}
.single-product .quickview-single-info .cart,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="cart"] {
  padding-left: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-color: #efefef;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-image: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-weight: 400;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  content: "N";
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  height: 0.479166667in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  padding-bottom: 11.25pt;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  padding-right: 0.9375pc;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  padding-top: 0.15625in;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-bottom-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  -webkit-transition: all 0.3s ease;
}
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity-button {
  margin-top: 3.75pt;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transition: all 0.3s ease;
}
.single-product .quickview-single-info .cart,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="cart"] {
  padding-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-size: 1pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-variant: normal;
}
[class~="quickview-popup"],
.quickview-popup .quickview-container .quickview-close:before {
  content: "";
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  font-family: ElegantIcons;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-weight: 500;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  margin-right: 3.75pt;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  vertical-align: top;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  background: transparent;
}
.single-product .quickview-single-info .quantity button.minus,
.quickview-popup .quickview-container .quickview-close:before {
  position: absolute;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-bottom-color: #000;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before,
.quickview-popup .quickview-container .quickview-close:before,
.single-product .quickview-single-info .quantity button.minus {
  left: 0;
}
.compare-popup {
  opacity: 0;
}
.single-product .quickview-single-info .quantity button.minus {
  bottom: 0;
}
[class~="quickview-container"] [class~="quickview-single-info"] [class~="cart"],
.single-product .quickview-single-info .cart {
  padding-right: 0;
}
[class~="quickview-container"] [class~="quickview-single-info"] [class~="cart"],
.single-product .quickview-single-info .cart {
  padding-top: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  text-align: left;
}
.single-product .quickview-single-info .quantity button.minus {
  border-left-width: medium;
}
[class~="quickview-container"] [class~="product-title"] {
  font-size: 0.3125in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  width: auto;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-right-color: #000;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  top: 0;
}
[class~="quickview-container"] [class~="product-title"] {
  line-height: 0.416666667in;
}
.single-product .quickview-single-info .quantity button.minus {
  border-bottom-width: medium;
}
.compare-popup {
  visibility: hidden;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-left: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-bottom: 0;
}
.single-product .quickview-single-info .quantity button.minus {
  border-right-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-right: 5.25pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-top-color: #000;
}
.single-product .quickview-single-info .quantity button.minus,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-top-width: medium;
}
.quickview-popup .quickview-container .quickview-close:before {
  height: 2px;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.single-product .quickview-single-info .quantity button.minus {
  border-left-style: none;
}
.compare-popup {
  -webkit-transition: all 0.8s ease-in-out;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-top: 0;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  display: flex;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  border-image: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  letter-spacing: 0.015625in;
}
.compare-popup {
  transition: all 0.8s ease-in-out;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-transform: uppercase;
}
.quickview-popup .quickview-container .quickview-close:before {
  width: 100%;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  font-size: 13px;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-width: 0.0625pc !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  line-height: 3.125pc;
}
.quickview-popup .quickview-container .quickview-close:before {
  top: 50%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  display: inline-block;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.single-product .quickview-single-info .quantity button.minus {
  border-bottom-style: none;
}
.quickview-popup .quickview-container .quickview-close:before {
  margin-top: -0.010416667in;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  position: relative;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  font-weight: 500;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-width: 0.0625pc !important;
}
.quickview-popup .quickview-container .quickview-close:before {
  background-color: rgba(0, 0, 0, 0.5);
}
.single-product .quickview-single-info .quantity button.minus {
  border-right-style: none;
}
.single-product .quickview-single-info .quantity button.minus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-top-color: currentColor;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-width: 0.0625pc !important;
}
.single-product .quickview-single-info .quantity button.minus {
  border-top-style: none;
}
.single-product .quickview-single-info .quantity button.minus {
  border-left-color: currentColor;
}
.single-product .quickview-single-info .quantity button.minus {
  border-bottom-color: currentColor;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  background: rgba(0, 0, 0, 0.75);
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-size: 1.125pc;
}
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  vertical-align: bottom;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  text-transform: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:hover:after {
  transform: rotate(0deg);
}
.single-product .quickview-single-info .quantity button.minus {
  border-right-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  color: #000 !important;
}
.quickview-popup .quickview-container .quickview-close:before {
  -webkit-transition: all 0.3s ease;
}
.quickview-popup .quickview-container .quickview-close:before {
  transition: all 0.3s ease;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  color: #fff;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-width: 0.0625pc !important;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"] {
  text-decoration: unset !important;
}
.single-product .quickview-single-info .quantity button.minus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-image: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  background-color: #000;
}
[class~="quickview-popup"] {
  position: fixed;
}
.single-product .quickview-single-info .quantity button.minus {
  width: 0.395833333in;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  content: "L";
}
.single-product .quickview-single-info .quantity button.minus {
  height: 0.479166667in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  min-width: 200px;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after,
.quickview-popup .quickview-container .quickview-close:before {
  transform-origin: 50% 50%;
}
[class~="wishlist-items"] tr td,
.single-product .quickview-single-info .quantity button.minus {
  background: transparent;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  -webkit-font-smoothing: antialiased;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-align: center;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-style: solid !important;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  height: 3.75pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  margin-right: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-left: 11.25pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-bottom: 0;
}
[class~="quickview-container"] [class~="product-title"] {
  color: #000;
}
.quickview-popup .quickview-container .quickview-close:before {
  -webkit-transform: rotate(-45deg);
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-style: solid !important;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  line-height: 45pt;
}
[class~="quickview-popup"] {
  top: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-right: 11.25pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  top: 0.25pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  margin-left: 0;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-left-width: medium;
}
.quickview-popup .quickview-container .quickview-close:before {
  transform: rotate(-45deg);
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:hover:before {
  -webkit-transform: rotate(0deg);
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-bottom-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-top: 0;
}
[class~="wishlist-items"] tr td {
  padding-left: 0.104166667in;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  cursor: pointer;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  display: inline-block;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  top: 60px;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  flex: 1;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  max-width: 225pt;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-right-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  margin-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-style: solid !important;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  -webkit-transform: rotate(45deg);
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-top-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  vertical-align: top;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transform: rotate(45deg);
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  -moz-osx-font-smoothing: grayscale;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  z-index: 7;
}
[class~="single-product"] [class~="entry-summary"] [class~="price"] del,
[class~="quickview-container"] [class~="entry-summary"] [class~="price"] del {
  font-size: 20px;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  color: #43454b;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-style: solid !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-color: #b5b5b5 !important;
}
.single-product .quickview-single-info .quantity button.plus:before {
  content: "L";
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  color: #fff;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  font-weight: 400;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-color: #b5b5b5 !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-color: #b5b5b5 !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-color: #b5b5b5 !important;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-left-style: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"]:before,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"]:before {
  letter-spacing: 0.3pc;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  text-decoration: underline;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-image: none !important;
}
[class~="quickview-container"] [class~="product-title"] {
  word-break: break-word;
}
.quickview-container .quickview-single-info .quantity button,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  outline-color: transparent;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-bottom-style: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  height: 37.5pt;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.quickview-container .quickview-single-info .quantity button:focus {
  border-right-style: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice,
[class~="quickview-container"] [class~="product-title"] {
  display: block;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  text-align: center;
}
.quickview-container .quickview-single-info .quantity button,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  color: #868686;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice,
[class~="quickview-popup"] {
  width: 100%;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  margin-top: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  white-space: nowrap;
}
.quickview-container .quickview-single-info .quantity button,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  font-size: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  -webkit-transition: none;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-left-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"],
.single-product .quickview-single-info .quantity button.plus:before {
  position: relative;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-bottom-width: medium;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}
.quickview-container .quickview-single-info .quantity button:focus,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-top-style: none;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-right-width: medium;
}
[class~="single-product"] [class~="product-title"] {
  margin-top: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  padding-left: 0.208333333in;
}
[class~="quickview-popup"] {
  left: 0;
}
[class~="quickview-popup"] {
  bottom: 0;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-bottom: 3.75pt;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  width: 1.302083333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-size: 1pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-width: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-weight: 500;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  padding-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-weight: 900;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  padding-left: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  padding-right: 0.208333333in;
}
[class~="single-product"] [class~="product-title"] {
  margin-bottom: 0.125in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-top-width: medium;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  letter-spacing: 0.015625in;
}
[class~="wishlist-items"] tr td {
  padding-bottom: 0.9375pc;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.quickview-container .quickview-single-info .quantity button:focus {
  border-left-color: currentColor;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  margin-bottom: 0;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  margin-right: 5.25pt;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  outline: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-width: 0;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-bottom-color: currentColor;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-right-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  cursor: pointer;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  background-color: rgba(255, 255, 255, 0.7);
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-top-color: currentColor;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  padding-top: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  color: #fff;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-width: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-width: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  font-size: 0.145833333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-transform: uppercase;
}
[class~="single-product"] [class~="product-title"] {
  font-size: 0.3125in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-style: none;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-left-width: 0.0625pc !important;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-bottom-width: 0.0625pc !important;
}
.quickview-container .quickview-single-info .quantity button:focus {
  border-image: none;
}
.quickview-container .quickview-single-info .quantity button:focus,
[class~="single-product"] [class~="product-title"] {
  color: #000;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-right-width: 0.0625pc !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-width: medium;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-width: medium;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  line-height: 3.125pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-width: medium;
}
.single-product .quickview-single-info .quantity button:before {
  font-family: eleganticons;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-right: 0;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  padding-right: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-bottom-style: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-width: medium;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-top-width: 0.0625pc !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-style: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  position: relative;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  background: rgba(0, 0, 0, 0.75);
}
[class~="single-product"] [class~="product-title"] {
  line-height: 0.416666667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-align: center;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  font-weight: 400;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  position: absolute;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-style: none;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  left: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody {
  z-index: 7;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-left-style: solid !important;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-bottom-style: solid !important;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  -webkit-transition: all 0.5s;
}
.single-product .quickview-single-info .quantity button.plus:before {
  top: 0.25pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-style: none;
}
[class~="quickview-popup"] {
  height: 100%;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-color: currentColor;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-top: 3.75pt;
}
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"] {
  margin-bottom: 0;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-right-style: solid !important;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-top-style: solid !important;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-left: 11.25pt;
}
[class~="single-product"] [class~="product-title"] {
  word-break: break-word;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-left-color: #b5b5b5 !important;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-bottom-color: #b5b5b5 !important;
}
[class~="quickview-popup"] {
  z-index: 9999;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  -moz-transition: all 0.5s;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-bottom-color: currentColor;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-right-color: #b5b5b5 !important;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-bottom: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"],
.single-product .quickview-single-info .quantity button:before,
[class~="quickview-popup"] {
  display: inline-block;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-color: currentColor;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span {
  color: #fff;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-right: 11.25pt;
}
[class~="wishlist-items"] tr td {
  padding-right: 0.104166667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  padding-top: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  cursor: pointer;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-top-color: #b5b5b5 !important;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-color: currentColor;
}
[class~="single-product"] [class~="product-title"] {
  display: block;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  vertical-align: top;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  border-image: none !important;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  flex: 1;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  -ms-transition: all 0.5s;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span {
  text-decoration: underline;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  content: "K";
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-left: 0;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  -o-transition: all 0.5s;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-image: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  height: 2.875pc;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-bottom: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-top: 0;
}
.single-product .quickview-single-info .quantity button:before {
  font-size: 1.125pc;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  white-space: nowrap;
}
.single-product .quickview-single-info .quantity button:before {
  font-weight: 900;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-notice {
  transition: all 0.5s;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"] {
  height: 37.5pt;
}
[class~="single-product"] [class~="quickview-single-info"] [class~="quantity"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  position: relative;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  z-index: 6;
}
.quickview-popup .quickview-container .slick-dots,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  position: absolute;
}
.quickview-container .quickview-single-info .button.quick-buy {
  -webkit-transition: none;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
.quickview-container .quickview-single-info .button.quick-buy {
  color: #000;
}
.quickview-popup .quickview-container .slick-dots {
  bottom: 0.364583333in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-right-style: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-track"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-list"] {
  display: flex;
}
.quickview-container .quickview-single-info .button.quick-buy {
  transition: none;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:hover:before {
  transform: rotate(0deg);
}
.quickview-container .quickview-single-info .button.quick-buy {
  font-size: 1pc;
}
.single-product .quickview-single-info .quantity button:focus {
  border-left-width: medium;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-top-style: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  top: 0;
}
[class~="wishlist-items"] tr td {
  padding-top: 0.9375pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  text-align: center;
}
[class~="quickview-container"]
  [class~="product-type-variable"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
[class~="quickview-container"]
  [class~="product-type-variable"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"] {
  margin-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.quickview-container .quickview-single-info .button.quick-buy {
  font-weight: 500;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span {
  outline: none;
}
[class~="quickview-popup"] {
  background: rgba(0, 0, 0, 0.5);
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-left: 2.375pc;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-left-color: currentColor;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  right: 0;
}
.single-product .quickview-single-info .quantity button:focus {
  border-bottom-width: medium;
}
.quickview-container .quickview-single-info .button.quick-buy {
  letter-spacing: 0.09375pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-bottom: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-right: 2.375pc;
}
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  cursor: pointer;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-right-color: currentColor;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  z-index: 5;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  top: 3pt;
}
.single-product .quickview-single-info .quantity button:focus {
  border-right-width: medium;
}
[class~="quickview-popup"] {
  opacity: 0;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-top: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-top-color: currentColor;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  content: "K";
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-image: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  height: 100%;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before,
.quickview-container .quickview-single-info .button.quick-buy {
  position: relative;
}
.quickview-container .quickview-single-info .button.quick-buy {
  text-transform: uppercase;
}
.single-product .quickview-single-info .quantity button:focus {
  border-top-width: medium;
}
.quickview-container .quickview-single-info .button.quick-buy {
  line-height: 0.520833333in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  z-index: 6;
}
.quickview-container .quickview-single-info .button.quick-buy {
  background: transparent;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  font-size: 13.5pt;
}
[class~="wishlist-items"] tr td {
  vertical-align: middle;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-left-width: 0.010416667in;
}
.wishlist-items tr,
.quickview-container .quickview-single-info .button.quick-buy {
  border-bottom-width: 0.75pt;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  overflow: auto;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 15px;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0.9375pc;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  width: 1.302083333in;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"],
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"] {
  margin-top: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-width: 0;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-right-width: 0.010416667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-width: 0;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-top-width: 0.010416667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-width: 0;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-left-style: solid;
}
[class~="quickview-popup"] {
  visibility: hidden;
}
[class~="quickview-popup"] {
  -webkit-transition: all 0.8s ease-in-out;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  font-family: eleganticons;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  content: "$";
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  height: auto;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
[class~="single-product"]
  [class~="product-type-variable"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"],
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"],
.single-product
  .product-type-variable
  .quickview-single-info
  .entry-summary
  > .cart
  .single-add-to-cart-button {
  margin-bottom: 0;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  display: inline-block;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"],
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"] {
  width: 100%;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr
  td {
  border-image-width: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-width: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.single-product .quickview-single-info .quantity button:focus {
  border-left-style: none;
}
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"],
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"] {
  display: flex;
}
.quickview-container .quickview-single-info .button.quick-buy,
.wishlist-items tr {
  border-bottom-style: solid;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  width: 0.083333333in;
}
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"],
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"] {
  flex-wrap: wrap;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.single-product .quickview-single-info .quantity button:focus {
  border-bottom-style: none;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-right-style: solid;
}
[class~="quickview-popup"] {
  transition: all 0.8s ease-in-out;
}
.single-product .quickview-single-info .quantity button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-style: none;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  opacity: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  border-radius: 0.125pc;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-top-style: solid;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  position: relative;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.single-product .quickview-single-info .quantity button:focus {
  border-top-style: none;
}
.quickview-notices-wrapper {
  max-width: 88.125pc;
}
.single-product .quickview-single-info .quantity button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-color: currentColor;
}
.quickview-notices-wrapper {
  margin-left: auto;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  -moz-border-radius: 1.5pt;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-left-color: #000;
}
.single-product .quickview-single-info .quantity button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-color: currentColor;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-bottom-color: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.single-product .quickview-single-info .quantity button:focus {
  border-right-color: currentColor;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  top: 0.03125in;
}
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  line-height: 1;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-right-color: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ),
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ) {
  background: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.single-product .quickview-single-info .quantity button:focus {
  border-top-color: currentColor;
}
.quickview-container .quickview-single-info .button.quick-buy {
  border-top-color: #000;
}
.quickview-container .quickview-single-info .button.quick-buy,
.single-product .quickview-single-info .quantity button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-image: none;
}
.quickview-notices-wrapper {
  margin-bottom: auto;
}
.single-product .quickview-single-info .quantity button:focus {
  color: #000;
}
.quickview-container .quickview-single-info .button.quick-buy,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  text-align: center;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-width: medium;
}
.quickview-container .quickview-single-info .button.quick-buy {
  margin-right: 0;
}
.quickview-notices-wrapper {
  margin-right: auto;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 0.15625in;
}
.quickview-notices-wrapper {
  margin-top: auto;
}
.quickview-container .quickview-single-info .button.quick-buy,
.quickview-notices-wrapper,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  width: 100%;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  border-spacing: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  height: 2.875pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0.15625in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-width: medium;
}
.quickview-container .quickview-single-info .button.quick-buy {
  padding-left: 0.9375pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  height: 0.083333333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-width: medium;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  border-collapse: collapse;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  box-sizing: border-box;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-left: 2.375pc;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
.quickview-container .quickview-single-info .button.quick-buy {
  padding-bottom: 0;
}
.quickview-container .quickview-single-info .button.quick-buy {
  padding-right: 0.9375pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li {
  border-radius: 50%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-width: medium;
}
.quickview-container .quickview-single-info .button.quick-buy,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-top: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  padding-right: 2.375pc;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ),
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ) {
  color: #fff;
}
.quickview-container .quickview-single-info .button.quick-buy {
  cursor: pointer;
}
.quickview-container .quickview-single-info .button.quick-buy {
  display: inline-block;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-style: none;
}
.quickview-container .quickview-single-info .button.quick-buy {
  vertical-align: top;
}
.quickview-container .quickview-single-info .button.quick-buy,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  margin-left: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  font-size: 13.5pt;
}
.quickview-container .quickview-single-info .button.quick-buy {
  margin-bottom: 0.052083333in;
}
.quickview-container .quickview-single-info .button.quick-buy {
  margin-top: 0.052083333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  font-weight: 500;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li {
  width: 0.083333333in;
}
.quickview-container .quickview-single-info .button.quick-buy {
  white-space: nowrap;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-style: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-style: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  -webkit-transition: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  -webkit-border-radius: 50%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-style: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-color: currentColor;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-color: currentColor;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  transition: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  font-size: 1pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li {
  -moz-border-radius: 50%;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  margin-bottom: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-right: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-color: currentColor;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  letter-spacing: 0.09375pc;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li {
  -ms-border-radius: 50%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  text-transform: uppercase;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-color: currentColor;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li {
  -o-border-radius: 50%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-image: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  color: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  line-height: 0.520833333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  position: relative;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  background: transparent;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  margin-top: 0;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  padding-left: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-left-width: 0.010416667in;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  -webkit-border-radius: 1.5pt;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-bottom-width: 0.010416667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-right-width: 0.010416667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-top-width: 0.010416667in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-left-style: solid;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  padding-bottom: 0;
}
.wishlist-items tr {
  border-bottom-color: #e5e5e5;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-bottom-style: solid;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"]:hover:not([class~="disabled"]),
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"]:hover:not([class~="disabled"]) {
  background: #000;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  padding-right: 0;
}
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  visibility: hidden;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-right-style: solid;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-top-style: solid;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-left-color: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-bottom-color: #000;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-right-color: #000;
}
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"]:hover:not([class~="disabled"]),
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"]:hover:not([class~="disabled"]) {
  color: #fff;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  border-top-color: #000;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr.tr-image
  img {
  box-shadow: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
.wishlist-items tr {
  border-image: none;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  text-align: center;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  padding-top: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-left: 0.9375pc;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-right: 0.9375pc;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table {
  z-index: 6;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  cursor: pointer;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  display: inline-block;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  vertical-align: top;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  width: 100%;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-left: 0;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-bottom: 0.052083333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-top: 0.052083333in;
}
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  white-space: nowrap;
}
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr:nth-child(2n)
  td {
  background-color: #fbfbfb;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  top: calc(50% - 10px);
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  font-size: 20px;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  -o-border-radius: 50%;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  a {
  text-decoration: none;
}
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button:before,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button:after {
  content: none;
}
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-rating"]
  td
  [class~="star-rating"] {
  float: left;
}

/*===================================================================
7. Shop
====================================================================*/
/**
 * 7.1. Shop - Top Bar
 */
.products-topbar {
  margin-bottom: 30px;
}
.products-topbar .products-topbar-left {
  float: left;
}
.products-topbar .products-topbar-right {
  float: right;
}
.products-topbar .products-count {
  margin-top: 0px;
  font-size: 16px;
  margin-left: 10px;
}
.products-topbar .products-sort {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  text-transform: capitalize;
  cursor: pointer;
  float: right;
}
.products-topbar .products-sort .sort-toggle {
  position: relative;
  border: 1px solid #000;
  display: inline-block;
  line-height: 34px;
  padding: 0 20px;
}
.products-topbar .products-sort .sort-toggle:after {
  content: "\43";
  font-family: eleganticons;
  font-size: 13px;
  padding-left: 5px;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0;
  vertical-align: 0;
  border: 0;
  line-height: 1;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform-origin: 65% 50%;
  color: #000;
}
.products-topbar .products-sort .sort-list {
  min-width: 210px;
  z-index: 9999;
  right: 0;
  left: auto !important;
  padding: 10px 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  border-radius: 0;
}
.products-topbar .products-sort .sort-list li {
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 400;
}
.products-topbar .products-sort .sort-list li a {
  color: #868686;
}
.products-topbar .products-sort .sort-list li a:hover {
  color: #000;
}
.products-topbar .layout-toggle {
  float: right;
  list-style: none;
  padding: 9px 10px;
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  line-height: 1;
}
.products-topbar .layout-toggle li {
  float: left;
  padding-right: 10px;
  position: relative;
}
.products-topbar .layout-toggle li:last-child {
  padding-right: 0;
}
.products-topbar .layout-toggle li a {
  display: inline-block;
  text-align: center;
  height: 17px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 17px;
  padding: 0;
  border: none;
}
.products-topbar .layout-toggle li a.active,
.products-topbar .layout-toggle li a:hover {
  background: #fff;
  border-color: #000;
}
.products-topbar .layout-toggle li a .icon-column {
  vertical-align: middle;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 0;
  display: inline-block;
}
.products-topbar .layout-toggle li a .icon-column .layer {
  position: absolute;
  left: 0;
  height: 5px;
}
.products-topbar .layout-toggle li a .icon-column .layer.first {
  top: 0;
}
.products-topbar .layout-toggle li a .icon-column .layer.middle {
  top: 6px;
}
.products-topbar .layout-toggle li a .icon-column .layer.last {
  top: 12px;
}
.products-topbar .layout-toggle li a .icon-column .layer > span {
  position: absolute;
  display: block;
  width: 5px;
  height: 100%;
  background: #cecece;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}
.products-topbar .layout-toggle li a.active .icon-column .layer > span,
.products-topbar .layout-toggle li a:hover .icon-column .layer > span {
  background: #000;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:first-child {
  left: 0;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:nth-child(2) {
  left: 6px;
}
.products-topbar .layout-toggle li a .icon-column .layer > span:last-child {
  left: 12px;
}
.products-topbar .layout-toggle li a.layout-list {
  width: 25px;
}
.products-topbar
  .layout-toggle
  li
  a.layout-list
  .icon-column
  .layer
  > span:first-child {
  left: 0;
}
.products-topbar
  .layout-toggle
  li
  a.layout-list
  .icon-column
  .layer
  > span:last-child {
  left: 6px;
  width: 17px;
  height: 1px;
  top: 2px;
}

/**
 * 7.2. Shop - Products
 */
.products-list.sestsellers.grid .product-wapper {
  margin-bottom: 0px;
}
.products-list.grid .product-wapper {
  position: relative;
  padding: 0;
  margin: 0 0 40px;
  text-align: center;
}
.products-list.grid .product-wapper {
  position: relative;
  padding: 0;
  margin: 0 0 40px;
  text-align: center;
}
.products-list.grid .product-wapper .products-thumb {
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.products-list.grid .product-wapper .products-thumb:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}
.products-list.grid .product-wapper .products-thumb .product-lable > div {
  overflow: hidden;
  display: inline-block;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-lable
  > div:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}
.products-list.grid .product-wapper .products-thumb .product-stock {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: #fff;
  color: #000;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.products-list.grid .product-wapper .products-thumb .product-thumb-hover {
  position: relative;
  display: block;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-thumb-hover
  .post-image {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: auto;
}
.products-list.grid
  .product-wapper:hover
  .products-thumb
  .product-thumb-hover
  .post-image {
  opacity: 0;
  visibility: hidden;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-thumb-hover
  .hover-image {
  opacity: 1;
  visibility: visible;
  max-width: 100%;
  height: auto;
}
.products-list.grid
  .product-wapper:hover
  .products-thumb
  .product-thumb-hover
  .hover-image {
  opacity: 1;
  visibility: visible;
}
.products-list.grid .product-wapper .products-thumb .product-button {
  position: absolute;
  bottom: 15px;
  left: 0;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  z-index: 9;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :nth-child(odd) {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.products-list.grid .product-wapper .products-thumb .product-button > * {
  margin: 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  position: relative;
}
.products-list.grid .product-wapper:hover .products-thumb .product-button > * {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}
.products-list.grid .product-wapper .products-thumb .product-button > :before {
  content: attr(data-title);
  position: absolute;
  padding: 0 10px;
  background: #000;
  color: #fff;
  top: -30px;
  line-height: 23px;
  white-space: nowrap;
  left: 50%;
  font-size: 12px;
  z-index: 10;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  pointer-events: none;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :hover:after,
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :hover:before {
  opacity: 1;
  visibility: visible;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn {
  font-size: 0;
  background: #fff;
  text-align: center;
  position: relative;
  white-space: nowrap;
  margin-top: 0;
  border: 0;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:hover {
  background: #000;
  border-color: #000;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:before {
  content: "\e634";
  font-family: themify;
  font-size: 16px;
  line-height: 40px;
  color: #000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:hover:before {
  color: #fff;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.adding:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 16px;
  height: 16px;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 7px;
  left: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.adding:hover:before {
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-top-color: #fff;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.added:before {
  line-height: 40px;
  margin: 0;
  content: "\e90";
  font-family: icomoon;
  color: #000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  position: relative;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.added:hover:before {
  color: #fff;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .product-btn:before {
  content: "\e655";
  top: 0;
  margin: 0;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .product-btn.loading:before {
  content: "";
  margin-top: 11px;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  width: 10px;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-compare
  .product-btn:before {
  content: "\e90f";
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-compare
  .product-btn.adding:before {
  content: "";
}
.products-list.grid .product-wapper .products-thumb .product-button > :after {
  top: -5px;
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview {
  text-align: center;
  background: #fff;
  font-size: 0;
  display: block;
  white-space: nowrap;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  > a {
  background: #000;
  border-color: #000;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  > a {
  color: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 0;
  display: inline-block;
  position: relative;
  outline: unset;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  > a
  > i {
  font-size: 16px;
  color: #000;
  line-height: 40px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 18px;
  height: 18px;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 8px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  .loading:before,
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  > a
  > i {
  color: #fff;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading:hover:before {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-top-color: #fff;
}
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading
  i {
  display: none;
}
.products-list.grid .product-wapper .products-content {
  text-align: left;
  margin-top: 14px;
}
.products-list.grid .product-wapper .products-content h3.product-title {
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 6px;
}
.products-list.grid .product-wapper .products-content h3.product-title a {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.products-list.grid
  .product-wapper
  .products-content
  h3.product-title
  a:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}
.products-list.grid .product-wapper .products-content .rating {
  margin-bottom: 4px;
}
.products-list.grid .product-wapper .products-content .price {
  line-height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.products-list.grid .product-wapper .products-content .price:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}
.products-list.grid .product-wapper .products-content .price del {
  line-height: 100%;
}
.products-list.grid .product-wapper .btn-add-to-cart {
  margin-top: 15px;
  text-align: center;
}
.products-list.grid .product-wapper .btn-add-to-cart a {
  display: inline-block;
  border: 1px solid #e8e8e8;
  color: #a9a8a8;
  font-size: 15px;
  /* line-height: 48px; */
  width: 100%;
  height: 100%;
  padding: 0 13px 5px 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-align: center;
  position: relative;
  text-transform: lowercase;
}
.products-list.grid .product-wapper .btn-add-to-cart a.loading {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.products-list.grid .product-wapper .btn-add-to-cart a:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.products-list.grid .product-wapper .btn-add-to-cart a:before {
  content: "\e901";
  font-family: icomoon;
  font-size: 20px;
  line-height: 30px;
  color: #a9a8a8;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  margin: 0;
  display: inline-block;
  position: relative;
  left: 0;
  top: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 10px;
}
.products-list.grid .product-wapper .btn-add-to-cart a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 4px;
}
.products-list.grid .product-wapper .btn-add-to-cart a:hover:before {
  color: #fff;
}
.products-list.grid .product-wapper .btn-add-to-cart a.added {
  display: none;
}
.products-list.grid .product-wapper .btn-add-to-cart a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  text-indent: 0;
  text-transform: none;
  width: 10px;
}
.products-list.list .product-wapper {
  margin-bottom: 40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.products-list.list .product-wapper .products-thumb {
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
}
.products-list.list .product-wapper .products-thumb .product-thumb-hover {
  position: relative;
  display: block;
  overflow: hidden;
}
.products-list.list .product-wapper .products-thumb .post-image {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.products-list.list .product-wapper .products-thumb:hover .post-image {
  -webkit-transform: translate(100%);
  transform: translate(100%);
}
.products-list.list .product-wapper .products-thumb .hover-image {
  top: 0;
  position: absolute;
  overflow: hidden;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate(-100%);
  transform: translate(-100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.products-list.list .product-wapper .products-thumb:hover .hover-image {
  -webkit-transform: translate(0);
  transform: translate(0);
}
.products-list.list .product-wapper .products-thumb .product-quickview {
  position: absolute;
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  width: 42px;
  height: 42px;
  background: #fff;
  text-align: center;
  line-height: 42px;
  z-index: 9999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.products-list.list .product-wapper:hover .products-thumb .product-quickview {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.products-list.list .product-wapper .products-thumb .product-quickview:hover {
  background: #000;
}
.products-list.list .product-wapper .products-thumb .product-quickview a {
  font-size: 0;
  width: 42px;
  height: 42px;
  display: inline-block;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.products-list.list .product-wapper .products-thumb .product-quickview a i {
  font-size: 16px;
  color: #000;
  line-height: 42px;
}
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 18px;
  height: 18px;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview:hover
  .loading:before,
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview:hover
  > a
  > i {
  color: #fff;
}
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading:hover:before {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-top-color: #fff;
}
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading
  i {
  display: none;
}
.products-list.list .product-wapper .products-content .product-title {
  font-size: 25px;
  margin-top: -2px;
  margin-bottom: 12px;
  font-weight: 400;
}
.products-list.list .product-wapper .products-content .price {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
  display: block;
}
.products-list.list .product-wapper .products-content .rating {
  margin-bottom: 24px;
}
.products-list.list .product-wapper .products-content .product-button {
  display: inline-flex;
  flex-wrap: wrap;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  .product-btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  height: 42px;
  position: relative;
  clear: both;
  padding: 0 30px;
  background: #000;
  border: 1px solid #000;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  .product-btn:hover {
  background: transparent;
  color: #000;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  margin-right: 10px;
}
.products-list.list .product-wapper .products-content .btn-add-to-cart a.added {
  display: none;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  margin-right: 5px;
  vertical-align: bottom;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn {
  margin-top: 0;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 500;
  padding: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  float: left;
  font-size: 0;
  background: var(--wishlist-bk-color);
}
.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:hover {
  cursor: pointer;
  background: #000;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:before {
  content: "\f08a";
  position: absolute;
  left: 0;
  width: 100%;
  color: var(--wishlist-color);
  font-family: FontAwesome;
  font-size: 19px;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:hover:before {
  color: #fff;
}
.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.adding:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 16px;
  height: 16px;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 7px;
  left: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.adding:hover:before {
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-top-color: #fff;
}
.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.added:before {
  line-height: 40px;
  margin: 0;
  content: "\e90";
  font-family: icomoon;
  color: #000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  position: relative;
}
.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.added:hover:before {
  color: #fff;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn {
  text-align: center;
  background: #fff;
  font-size: 0;
  display: block;
  white-space: nowrap;
  color: #000;
  width: 42px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  border: 0;
  cursor: pointer;
  border: 1px solid #000;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:before {
  font-size: 16px;
  color: #000;
  line-height: 38px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  content: "\e90f";
  font-family: icomoon;
  margin: 0;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:hover {
  background: #000;
  border-color: #000;
}
.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:hover:before {
  color: #fff;
}
.products-list.list .product-wapper .products-content .product-description {
  margin-top: 30px;
  display: inline-block;
  width: 100%;
  padding-top: 30px;
  border-top: 1px solid #e5e5e5;
}

/**
 * 7.3. Shop - Details
 */
.shop-details .product-images > .row {
  margin: 0 -7.5px;
}
.shop-details .product-images > .row > div {
  padding: 0 7.5px;
}
.shop-details .product-images .content-thumbnail-scroll {
  position: relative;
}
.shop-details .product-images .content-thumbnail-scroll .slick-list {
  padding: 0 !important;
}
.shop-details .product-images .content-thumbnail-scroll .img-item {
  padding-bottom: 15px;
  vertical-align: top;
  border: 0;
  cursor: pointer;
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .img-item
  .img-thumbnail-scroll {
  vertical-align: top;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid hsla(0, 0%, 67%, 0.25);
  overflow: hidden;
  margin: 1px;
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .img-item.slick-current
  .img-thumbnail-scroll {
  border-color: #000;
}
.shop-details .product-images .content-thumbnail-scroll .slick-arrow {
  right: calc(50% - 15px);
  left: auto;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 0;
  -webkit-transform: none;
  transform: none;
  background: #000;
  color: #fff;
  font-family: FontAwesome;
  text-align: center;
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left {
  top: 0;
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left:before {
  content: "\f106";
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right {
  bottom: 10px;
  top: auto;
}
.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right:before {
  content: "\f107";
}
.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow {
  opacity: 1;
  visibility: visible;
}
.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left {
  top: -10px;
}
.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right {
  bottom: 0;
}
.shop-details
  .product-images
  .image-thumbnail
  .slick-slide.slick-current
  .img-thumbnail-scroll,
.shop-details
  .product-images
  .image-thumbnail
  .slick-slide:hover
  .img-thumbnail-scroll,
.shop-details .product-images .image-thumbnail .slick-slide:hover a,
.shop-details .product-images .image-thumbnail .slick-slide a.active {
  border-color: #000;
}
.shop-details .product-images .img-item img {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.shop-details .product-images .main-image .slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: hsla(0, 0%, 100%, 0.5);
  color: #000;
  font-family: FontAwesome;
  text-align: center;
  top: calc(50% - 15px);
  opacity: 1;
  visibility: visible;
  z-index: 9998;
  font-size: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.shop-details .product-images .main-image .slick-arrow:hover {
  background: #000;
  color: #fff;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-left {
  left: 20px;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-left:before {
  content: "\f104";
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-right {
  right: 20px;
}
.shop-details .product-images .main-image .slick-arrow.fa-angle-right:before {
  content: "\f105";
}
.shop-details .product-info {
  padding-left: 30px;
}
.shop-details .product-info .title {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 12px;
}
.shop-details .product-info .price {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 15px;
  display: block;
}
.shop-details .product-info .price del {
  font-size: 18px;
}
.shop-details .product-info .description {
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid #efefef;
  margin-bottom: 20px;
}
.shop-details .variations .label {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  margin-right: 15px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  width: fit-content;
}
.shop-details .variations .label:after {
  position: relative;
  content: ":";
  top: 0;
  right: 0;
  display: inline-block;
  padding-left: 5px;
}
.shop-details .variations tr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.shop-details .variations .attributes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.shop-details .variations .attributes ul li {
  display: inline-block;
  margin-right: 10px;
}
.shop-details .variations .attributes ul.text li span {
  width: 28px;
  height: 28px;
  line-height: 26px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 13px;
  text-align: center;
  border: 1px solid #d5d5d5;
  cursor: pointer;
}
.shop-details .variations .attributes ul.text li span:hover {
  border: 1px solid #000;
  color: #000;
}
.shop-details .variations .attributes ul.colors li span {
  width: 38px;
  height: 38px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #000;
  cursor: pointer;
  position: relative;
}
.shop-details .variations .attributes ul.colors li span.Blue {
  background: blue;
}
.shop-details .variations .attributes ul.colors li span.Red {
  background: red;
}
.shop-details .variations .attributes ul.colors li span.Green {
  background: green;
}
.shop-details .variations .attributes ul.colors li span.Black {
  background: black;
}
.shop-details .variations .attributes ul.colors li span.Grey {
  background: grey;
}
.shop-details .variations .attributes ul.colors li span.Brown {
  background: brown;
}

.shop-details .variations .attributes ul.colors li span.Beige {
  background: beige;
}
.shop-details .variations .attributes ul.colors li span:before {
  content: "";
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 22.5px);
  width: 45px;
  height: 45px;
  border: 1px solid #000;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: unset;
  transform: unset;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.shop-details .buttons {
  display: flex;
  vertical-align: top;
  flex-wrap: wrap;
}
.shop-details .buttons .add-to-cart-wrap {
  display: flex;
  margin: 5px 0;
  flex-wrap: wrap;
  flex: 1;
}
.shop-details .buttons .add-to-cart-wrap .quantity {
  margin: 0;
  border: 1px solid #b5b5b5 !important;
  height: 50px;
  display: inline-block;
  margin-right: 7px;
  position: relative;
}
.shop-details .buttons .add-to-cart-wrap .quantity button {
  outline-color: transparent;
  color: #868686;
  font-size: 0;
}
.shop-details .buttons .add-to-cart-wrap .quantity button:before {
  font-family: eleganticons;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
}
.shop-details .buttons .add-to-cart-wrap .quantity button:hover {
  border: none;
  color: #000;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.plus {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  line-height: 24px;
  border: none;
  width: 38px;
  height: 46px;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.plus:before {
  content: "\4c";
  position: relative;
  top: 4px;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.minus {
  position: absolute;
  left: 0;
  bottom: 0;
  border: none;
  width: 38px;
  height: 46px;
  background: transparent;
}
.shop-details .buttons .add-to-cart-wrap .quantity button.minus:before {
  content: "\4b";
  position: relative;
  top: 4px;
}
.shop-details .buttons .add-to-cart-wrap .quantity input {
  width: 125px;
  border: 0;
  height: 46px;
  text-align: center;
  padding: 0 38px;
  font-size: 18px;
  font-weight: 500;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart {
  -webkit-transition: none;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 50px;
  position: relative;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  margin-right: 20px;
  padding: 0 15px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  margin: 0;
  white-space: nowrap;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a {
  color: #fff;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart:hover {
  background: #000;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: none;
  -webkit-animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  margin-right: 10px;
}
.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.added {
  display: none;
}
.shop-details
  .buttons
  .add-to-cart-wrap
  .btn-add-to-cart
  a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  margin-right: 5px;
  vertical-align: bottom;
}
.shop-details .buttons .btn-quick-buy {
  width: 100%;
}
.shop-details .buttons .btn-quick-buy .product-btn {
  -webkit-transition: none;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 50px;
  position: relative;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  margin-right: 20px;
  padding: 0 15px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 5px 0;
  white-space: nowrap;
}
.shop-details .buttons .btn-quick-buy .product-btn:hover {
  background: #000;
  color: #fff;
}
.shop-details .product-tabs #review-form .comment-reply-title:before {
  display: none !important;
}
.shop-details .buttons .btn-wishlist .product-btn {
  margin-top: 20px;
  text-align: center;
  color: #000;
  display: inline-flex;
  cursor: pointer;
  vertical-align: top;
  margin-right: 25px;
  align-items: center;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0;
}
.shop-details .buttons .btn-compare .product-btn {
  margin-top: 20px;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0;
}
.shop-details .buttons .btn-wishlist .product-btn:before,
.shop-details .buttons .btn-compare .product-btn:before {
  content: "\e900";
  font-family: icomoon;
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #cecece;
  margin-right: 10px;
  color: black;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.shop-details .buttons .btn-wishlist .product-btn.added:before {
  content: "\e90";
}
.shop-details .buttons .btn-compare .product-btn:before {
  content: "\e90f";
}
.shop-details .buttons .btn-wishlist .product-btn:hover:before,
.shop-details .buttons .btn-compare .product-btn:hover:before {
  color: #fff;
  background: black;
  border-color: #000;
}
.shop-details .product-info .product-meta {
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  border-color: #efefef;
  border-width: 1px 0;
  border-style: solid;
  padding: 20px 0;
  margin: 30px 0;
}
.shop-details .product-info .product-meta > span {
  display: inline-flex;
  color: #868686;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-right: 25px;
}
.shop-details .product-info .product-meta > span span,
.shop-details .product-info .product-meta > span a {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  margin-left: 5px;
}
.shop-details .product-info .social-share > a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #868686;
  font-weight: 500;
  margin-right: 40px;
}
.shop-details .product-info .social-share > a i {
  color: #000;
  font-size: 15px;
  margin-right: 7px;
}
.shop-details .product-tabs {
  margin-top: 60px;
  padding-bottom: 50px;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
}
.shop-details .product-tabs .product-tabs-wrap {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
}
.shop-details .product-tabs .nav-tabs {
  text-align: center;
  padding: 35px 0 0;
  margin-bottom: 35px;
  border-bottom: none;
  display: block;
}
.shop-details .product-tabs .nav-tabs .nav-item {
  display: inline-block;
  margin: 5px 20px;
}
.shop-details .product-tabs .nav-tabs .nav-item a {
  border: none;
  font-size: 24px;
  color: #868686;
  padding: 0 0 5px 0;
  position: relative;
  cursor: pointer;
}
.shop-details .product-tabs .nav-tabs .nav-item a:before {
  position: absolute;
  bottom: 0;
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
  height: 2px;
  background: #000;
}
.shop-details .product-tabs .nav-tabs .nav-item a:hover,
.shop-details .product-tabs .nav-tabs .nav-item a.active {
  color: #000;
}
.shop-details .product-tabs .nav-tabs .nav-item a:hover:before,
.shop-details .product-tabs .nav-tabs .nav-item a.active:before {
  width: 100%;
}
.shop-details .product-tabs .tab-content .tab-pane p {
  line-height: 2em;
}
.shop-details .product-tabs .product-attributes th,
.shop-details .product-tabs .product-attributes td {
  border: 1px solid #e5e5e5;
  padding: 16px 30px;
  vertical-align: top;
}
.shop-details .product-tabs .product-attributes th {
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
}
.shop-details .product-tabs .product-reviews .reviews-title {
  font-size: 25px;
  margin-bottom: 30px;
}
.shop-details .product-tabs .product-reviews .comment-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  margin-bottom: 40px;
}
.shop-details .product-tabs .product-reviews .comment-list li {
  padding: 0 15px;
  flex: 1;
  min-width: 50%;
  margin-bottom: 25px;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .content-comment-container {
  border: 1px solid #d9d9d9;
  padding: 30px;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 10px;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  > img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .comment-text {
  margin: 0;
  padding-left: 15px;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .review-author {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .review-time {
  font-size: 13px;
}
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .content-comment-container
  .description {
  color: #000;
  line-height: 22px;
}
.shop-details .product-tabs #review-form .comment-reply-title {
  color: #000;
  font-size: 13px;
  margin-bottom: 30px;
  display: inline-block;
  border: 2px solid #000;
  line-height: 52px;
  padding: 0 35px;
  text-transform: uppercase;
  font-weight: 500;
}
.shop-details .product-tabs #review-form .comment-reply-title:before {
  content: "\e918";
  font-family: wpbingofont;
  display: inline-block;
  font-size: 20px;
  margin-right: 15px;
  font-weight: 400;
}
.shop-details .product-tabs #review-form .required {
  color: red;
}
.shop-details .product-tabs #review-form .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.shop-details .product-tabs #review-form .comment-form .comment-notes {
  width: 100%;
  padding: 0 10px;
}
.shop-details .product-tabs #review-form .comment-form-rating {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
}
.shop-details .product-tabs #review-form .comment-form-rating label {
  font-weight: 500;
  margin-right: 0;
  margin-bottom: 10px;
  color: #000;
}
.shop-details .product-tabs #review-form .comment-form-rating .star-ratings {
  margin: -3px 10px;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars {
  padding-top: 0;
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 7px;
  padding: 5px 0;
  width: 160px;
  position: relative;
  top: 2px;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars span {
  display: inline-block;
}
.shop-details .product-tabs #review-form .comment-form-rating .stars span a {
  display: inline-block;
  vertical-align: middle;
  line-height: 100%;
  position: relative;
  font-size: 0;
  width: auto;
  margin-right: 3px;
}
.shop-details
  .product-tabs
  #review-form
  .comment-form-rating
  .stars
  span
  a:after {
  content: "\f005";
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: unset;
  text-indent: 0;
  color: #cecece;
}
.shop-details .product-tabs #review-form .comment-form-comment {
  flex: 1;
  padding: 0 10px;
  margin: 0;
  order: 2;
}
.shop-details .product-tabs #review-form .content-info-reviews {
  flex: 1;
  padding: 0 10px;
  order: 1;
  margin: 0;
}
.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .comment-form-author,
.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .comment-form-email {
  margin-bottom: 25px;
}
.shop-details .product-tabs #review-form .content-info-reviews input,
.shop-details .product-tabs #review-form .comment-form-comment textarea {
  background: #fff;
  padding: 14px 20px;
  border: 1px solid #d6d6d6;
  height: 60px;
  font-weight: 300;
  width: 100%;
}
.shop-details .product-tabs #review-form .comment-form-comment textarea {
  height: 230px;
}
.shop-details .product-tabs #review-form .content-info-reviews .form-submit {
  margin: 0;
}
.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .form-submit
  input[type="submit"] {
  background: #fff;
  font-weight: 500;
  padding: 0 50px;
  height: 60px;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 0.1em;
  border: 1px solid #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .form-submit
  input[type="submit"]:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.shop-details .product-related {
  margin-top: 50px;
}
.shop-details .product-related .block-title {
  text-align: center;
  margin-bottom: 50px;
}
.shop-details .product-related .block-title h2 {
  font-size: 28px;
  color: #000;
  margin: 0;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}
.shop-details .product-related .block-title h2:before {
  content: "";
  width: 30%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}

/**
 * 7.4. Shop - Cart
 */
.shop-cart .cart-items thead {
  border: 1px solid #e8e8e8;
}
.shop-cart .cart-items thead tr th {
  font-size: 18px;
  color: #000;
  padding: 15px;
  font-weight: 500;
  border-bottom-width: 1px;
}
.shop-cart .cart-items tbody {
  border: 0px solid #e8e8e8;
  border-width: 0px 1px 0px 1px;
}

.shop-cart .cart-items tfoot {
  border: 0px solid #e8e8e8;
  border-width: 0px 1px 0px 1px;
}

.shop-cart .cart-items tbody tr td {
  padding: 15px;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  vertical-align: middle;
}
.shop-cart .cart-items tbody tr td.product-thumbnail {
  display: flex;
  align-items: center;
}
.shop-cart .cart-items tbody tr td.product-thumbnail img {
  width: 80px;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a {
  font-size: 16px;
  color: #868686;
}
.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a:hover {
  color: #000;
}
.shop-cart .cart-items tbody tr td.product-price span,
.shop-cart .cart-items tbody tr td.product-subtotal span {
  color: #000;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity input.qty {
  border-left: 0;
  border-right: 0;
  width: 30px;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity .minus {
  border-right: 0;
  width: 30px;
}
.shop-cart .cart-items tbody tr td.product-quantity .quantity .plus {
  border-left: 0;
  width: 30px;
}
.shop-cart .cart-items tbody tr td.product-remove a {
  font-size: 0;
  text-align: center;
}
.shop-cart .cart-items tbody tr td.product-remove a:before {
  display: inline-block;
  content: "\ea02";
  font-family: feather;
  font-size: 10px;
  color: #000 !important;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 15px;
}
.shop-cart .cart-items tbody tr td.product-remove a:hover:before {
  color: #fff !important;
  background: #000;
  border-color: #000;
}
.shop-cart .cart-items .actions .bottom-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.shop-cart .cart-items .actions .coupon {
  flex: 0 0 50%;
  display: flex;
}
.shop-cart .cart-items .actions .coupon input {
  line-height: 40px;
  padding: 0 10px;
  flex: 1;
  margin-right: 5px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.shop-cart .cart-items .actions .bottom-cart .button {
  padding: 0 35px;
  background: #000;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 40px;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-weight: 500;
  border: 1px solid #000;
}
.shop-cart .cart-items .actions .bottom-cart .button:hover {
  opacity: 0.8;
}
.shop-cart .cart-items .actions .bottom-cart h2 {
  margin: 0;
  font-size: 15px;
}
.shop-cart .cart-totals {
  background: #f6f6f6;
  padding-bottom: 35px;
  max-width: 400px;
  margin-left: auto;
}
.shop-cart .cart-totals h2 {
  font-size: 18px;
  color: #000;
  padding: 15px 30px 11px;
  background: #e9e9e9;
  margin: 0;
  font-weight: 500;
  line-height: 1.8;
  border-bottom: 0;
}
.shop-cart .cart-totals .cart-subtotal {
  display: flex;
  padding: 15px 30px;
  align-items: center;
  margin-top: 10px;
}
.shop-cart .cart-totals .cart-subtotal > * {
  flex: 1;
}
.shop-cart .cart-totals .cart-subtotal .title {
  color: #000;
  font-size: 16px;
}
.shop-cart .cart-totals .cart-subtotal span {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.shop-cart .cart-totals .shipping-totals {
  display: flex;
  padding: 15px 30px;
  align-items: center;
}
.shop-cart .cart-totals .shipping-totals > * {
  flex: 1;
}
.shop-cart .cart-totals .shipping-totals .title {
  color: #000;
  font-size: 16px;
}
.shop-cart .cart-totals .shipping-totals .shipping-methods {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
}
.shop-cart .cart-totals .shipping-totals .shipping-desc {
  margin-bottom: 0;
}
.shop-cart .cart-totals .order-total {
  display: flex;
  padding: 15px 30px;
  align-items: center;
}
.shop-cart .cart-totals .order-total > * {
  flex: 1;
}
.shop-cart .cart-totals .order-total .title {
  color: #000;
  font-size: 16px;
}
.shop-cart .cart-totals .order-total span {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.shop-cart .cart-totals .proceed-to-checkout {
  padding: 0 30px;
  margin-top: 15px;
}
.shop-cart .cart-totals .proceed-to-checkout .button {
  background: #000;
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  border: 1px solid #000;
  cursor: pointer;
}
.shop-cart .cart-totals .proceed-to-checkout .button:hover {
  opacity: 0.8;
}
.shop-cart-empty .cart-empty {
  border-top: 3px solid #000;
  text-transform: capitalize;
  padding: 12px 22px;
  margin: 0 0 24px;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  width: 100%;
}
.shop-cart-empty .return-to-shop .button {
  line-height: 34px;
  background: #000;
  color: #fff;
  padding: 0 20px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
}
.shop-cart-empty .return-to-shop .button:before {
  content: "\23";
  font-size: 18px;
  font-family: ElegantIcons;
  margin: 0 5px 0 0;
  position: relative;
  top: 3px;
}
.shop-cart-empty .return-to-shop .button:hover {
  background: rgba(0, 0, 0, 0.75);
}

/**
 * 7.5. Shop - Checkout
 */
.shop-checkout .checkout h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
}
.shop-checkout .checkout .form-row {
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}
.shop-checkout .checkout .form-row label {
  color: #000;
  font-weight: 500;
  /* float: left; */
  margin-bottom: 0;
  display: block;
}
.shop-checkout .checkout .form-row .required {
  color: #e42234;
  font-weight: 400;
  border: 0;
}
.shop-checkout .checkout .form-row .input-wrapper {
  width: 100%;
  float: left;
}
.shop-checkout .checkout .form-row .input-wrapper .input-text {
  width: 100%;
  border-width: 0 0 2px;
  border-color: #e5e5e5;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #000;
}
.shop-checkout .checkout .form-row .selection .select2-selection {
  height: 30px;
  line-height: 30px;
  border-width: 0 0 2px;
  border-color: #e5e5e5;
  border-radius: 0;
}
.shop-checkout .checkout .form-row .selection .select2-selection span {
  line-height: 30px;
  padding: 0;
  height: 30px;
}
.shop-checkout .account-fields {
  margin-bottom: 30px;
}
.shop-checkout .checkout .account-fields .form-row {
  margin-bottom: 15px;
}
.shop-checkout .account-fields .checkbox span {
  margin-left: 10px;
}
.shop-checkout .ship-to-different-address .checkbox span {
  margin-left: 10px;
}
.shop-checkout .checkout .form-row .input-wrapper textarea {
  height: 100px !important;
}
.shop-checkout .checkout-review-order {
  padding: 30px 40px;
  border: 1px solid #000;
}
.shop-checkout .checkout-review-order h2 {
  margin: 0;
  font-size: 15px;
}
.shop-checkout .checkout-review-order .review-order-title {
  font-size: 22px;
  color: #000;
  margin-bottom: 22px;
}
.shop-checkout .checkout-review-order .cart-item {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}
.shop-checkout .checkout-review-order .cart-item .info-product {
  display: flex;
}
.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-thumbnail {
  max-width: 55px;
}
.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-thumbnail
  img {
  border: 1px solid #e8e8e8;
}
.shop-checkout .checkout-review-order .cart-item .info-product .product-name {
  padding-left: 20px;
  color: #000;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 14px;
}
.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-name
  .product-quantity {
  display: block;
  font-weight: 500;
}
.shop-checkout .checkout-review-order .cart-item .product-total span {
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .cart-subtotal,
.shop-checkout .checkout-review-order .order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dedede;
  padding: 27px 0;
}
.shop-checkout .checkout-review-order .cart-subtotal {
  margin-top: 30px;
}
.shop-checkout .checkout-review-order .cart-subtotal span,
.shop-checkout .checkout-review-order .order-total span {
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .shipping-totals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dedede;
  padding: 27px 0;
}
.shop-checkout .checkout-review-order .shipping-methods {
  text-align: right;
  padding: 0;
  list-style: none;
  margin: 0;
}
.shop-checkout .checkout-review-order .order-total .total-price span {
  font-size: 18px;
  color: #000;
  letter-spacing: 1.1px;
}
.shop-checkout .checkout-review-order .checkout-payment .payment-methods {
  padding: 20px;
  margin-bottom: 30px;
  list-style: none;
  border: 1px solid #d9d9d9;
}
.shop-checkout .checkout-review-order .checkout-payment .custom-radio li {
  margin-bottom: 10px;
}
.shop-checkout
  .checkout-review-order
  .checkout-payment
  .custom-radio
  li:last-child {
  margin-bottom: 0;
}
.shop-checkout .checkout-review-order .checkout-payment .custom-radio li label {
  color: #000;
  font-weight: 500;
}
.shop-checkout .checkout-review-order .checkout-payment .button {
  background: #000;
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  border: 1px solid #000;
  cursor: pointer;
}
.shop-checkout .checkout-review-order .checkout-payment .button:hover {
  opacity: 0.8;
}

/**
 * 7.6. Shop - Wishlist
 */
.shop-wishlist .wishlist-items {
  border: 1px solid #e5e5e5;
}

/*===================================================================
8. Blog
====================================================================*/
[class~="post-details"] [class~="post-meta"] a {
  color: #ababab;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"],
.posts-list.list .post-entry .post-meta .post-time,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-comment"],
.posts-list.list .post-entry .post-meta .post-categories {
  display: flex;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after,
.post-details .post-meta .post-time:after {
  content: "";
}
[class~="post-details"] [class~="post-meta"] i {
  margin-right: 0.0625in;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  font-size: 0.125in;
}
.posts-list.grid .post-entry .post-categories {
  margin-top: 0.104166667in;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  text-transform: uppercase;
}
.post-details .post-meta .post-time:after {
  width: 0.75pt;
}
.posts-list.list .post-entry .post-meta .post-time,
.posts-list.list .post-entry .post-meta .post-categories,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-comment"],
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"] {
  align-items: center;
}
.post-details .post-meta .post-time:after {
  height: 13.5pt;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover
  i {
  color: #fff;
}
.post-details .post-meta .post-time:after {
  margin-left: 0.9375pc;
}
.posts-list.grid .post-entry {
  margin-bottom: 2.5pc;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after,
.post-details .post-meta .post-time:after {
  margin-bottom: 0;
}
[class~="post-details"] [class~="post-content"] blockquote,
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  font-weight: 500;
}
[class~="posts-list"][class~="list"] [class~="post-entry"] {
  margin-bottom: 41.25pt;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"],
[class~="post-details"] [class~="post-content"] blockquote {
  margin-left: 0;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-left: 0.104166667in;
}
[class~="post-details"] [class~="post-title"] {
  margin-top: 0.208333333in;
}
.post-details.no-sidebar .post-title {
  font-size: 0.375in;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  font-size: 1.25pc;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]:last-child {
  margin-bottom: 0;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  line-height: 1.1;
}
.post-details .comments-area .comments-list .comment-item .comment-avatar {
  float: left;
}
.post-details .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  margin-right: 0.9375pc;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  width: 100%;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content-wrap"] {
  overflow: hidden;
}
.post-details.no-sidebar .post-title {
  text-align: center;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  line-height: 1.2;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
.post-details .post-meta .post-time:after,
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-top: 0;
}
[class~="post-details"][class~="no-sidebar"] [class~="post-meta"] {
  align-content: center;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after,
.post-details .post-meta .post-time:after {
  display: inline-block;
}
[class~="post-details"] [class~="post-title"] {
  margin-bottom: 10px;
}
[class~="post-details"] [class~="post-content"] blockquote {
  margin-bottom: 1.875pc;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"],
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:hover {
  color: #000;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-right: 0.104166667in;
}
[class~="post-details"] [class~="post-content"] blockquote,
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-right: 0;
}
[class~="post-details"] [class~="post-content"] blockquote {
  margin-top: 1.875pc;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  height: 0.0625pc;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  font-size: 0.9375pc;
}
[class~="post-details"][class~="no-sidebar"] [class~="post-meta"] {
  justify-content: center;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  width: 10px;
}
.post-details .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  background: #dedede;
}
[class~="post-details"] [class~="post-meta"] [class~="post-time"],
[class~="post-details"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] [class~="post-categories"],
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  display: flex;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  margin-bottom: 5px;
}
[class~="post-details"] [class~="post-meta"] {
  margin-bottom: 0.208333333in;
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  content: "";
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  width: 0.75pt;
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  height: 13.5pt;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  letter-spacing: 0.008333333in;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  a {
  color: #ababab;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  i {
  margin-right: 4.5pt;
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-left: 0.9375pc;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  background: #d5d5d5;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  margin-top: 0;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-bottom: 0.625pc;
}
[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] [class~="post-time"],
[class~="post-details"] [class~="post-meta"] [class~="post-categories"],
[class~="post-details"] [class~="post-meta"] {
  align-items: center;
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  margin-bottom: 0;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-btn"]
  a {
  text-transform: uppercase;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-btn"]
  a {
  font-size: 9pt;
}
[class~="post-details"] [class~="post-content"] blockquote {
  padding-left: 37.5pt;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-top: 0.1875pc;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  letter-spacing: 0.1em;
}
[class~="post-details"] [class~="post-meta"],
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  flex-wrap: wrap;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-time"] {
  margin-bottom: 4.5pt;
}
[class~="post-details"] [class~="post-meta"],
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-time"],
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  font-size: 10.5pt;
}
[class~="post-details"] [class~="post-content"] blockquote {
  padding-bottom: 0;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"],
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  color: #868686;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i {
  text-align: center;
}
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  display: inline-block;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"],
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  font-weight: 400;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  margin-left: 0;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  background: #000;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  position: absolute;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  margin-right: 0;
}
[class~="post-details"] [class~="post-meta"] [class~="post-comment"] {
  display: flex;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  color: #000;
}
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  font-size: 0.8125pc;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  top: 0.3125pc;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  right: 0;
}
.post-details .post-content blockquote i {
  display: block;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  color: #fff;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-right: 0.15625in;
}
[class~="post-details"] [class~="post-content"] blockquote {
  padding-right: 0;
}
.post-details .post-content blockquote i {
  font-size: 0.239583333in;
}
[class~="post-details"] [class~="post-meta"] [class~="post-comment"] {
  align-items: center;
}
[class~="post-details"] [class~="post-content"] blockquote {
  padding-top: 0;
}
[class~="post-details"] [class~="post-content"] blockquote,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-left-color: #000;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  color: #868686;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-bottom-color: #000;
}
.post-details .post-content .content-img {
  margin-top: 0.3125in;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  color: #c2c2c2;
}
.post-details .post-content-entry .entry-social-share,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] {
  margin-top: 20px;
}
[class~="post-details"] [class~="post-meta"] {
  color: #ababab;
}
[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] {
  letter-spacing: 0.005208333in;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  font-weight: 500;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  line-height: 2.5pc;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  margin-top: 0;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-image"],
.post-details .post-content blockquote i {
  margin-bottom: 0.208333333in;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"] {
  font-size: 2.125pc;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"] {
  line-height: 1.3;
}
.post-details .post-content-entry .entry-social-share {
  margin-bottom: 0;
}
.post-details .post-content blockquote i {
  color: #dadada;
}
.post-details .comments-area .comments-list .comment-item .comment-reply-link {
  font-size: 0;
}
[class~="post-details"] [class~="post-content"] blockquote {
  color: #666;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  margin-left: 0.026041667in;
}
.post-details .prev-next-post .previous-post,
.post-details .prev-next-post .next-post {
  width: 50%;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-right-color: #000;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  margin-bottom: 0.026041667in;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-top-color: #000;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-left: 0;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  display: inline-block;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"]:after {
  background: #dedede;
}
.post-details .comments-area .comment-form .form-group .form-control {
  height: 0.489583333in;
}
[class~="post-details"] [class~="post-content"] blockquote {
  border-left-width: 0.125pc;
}
[class~="post-details"] [class~="post-content"] blockquote,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-left-style: solid;
}
.post-details .comments-area .comment-form .form-group .form-control {
  padding-left: 0.208333333in;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  width: 40px;
}
.posts-list.list .post-entry .post-meta,
[class~="post-details"] [class~="post-content-entry"] {
  display: flex;
}
.post-details .prev-next-post .previous-post,
.post-details .prev-next-post .next-post {
  float: left;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  height: 2.5pc;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  margin-right: 0.026041667in;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-bottom: 7.5pt;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  margin-right: 0.3125pc;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"] {
  margin-right: 0;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  content: "";
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  text-align: center;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  width: 0.010416667in;
}
[class~="post-details"] [class~="post-content-entry"] {
  align-items: center;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  margin-top: 0.026041667in;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-left-width: 0.75pt;
}
[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"],
[class~="post-details"] [class~="post-content"] blockquote h2,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i {
  font-size: 9.75pt;
}
[class~="post-details"] [class~="post-content"] blockquote {
  border-image: none;
}
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] {
  padding-right: 11.25pt;
}
[class~="post-details"] [class~="post-content-entry"] {
  justify-content: space-between;
}
.post-details .comments-area .comment-form .form-group .form-control {
  padding-bottom: 7.5pt;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  line-height: 50px;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  height: 0.1875in;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-left: 0.15625in;
}
[class~="post-details"] [class~="post-content-entry"] {
  flex-wrap: wrap;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-title"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-top: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  padding-right: 0.208333333in;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-bottom-width: 0.0625pc;
}
[class~="post-details"] [class~="post-content"] blockquote {
  position: relative;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-right-width: 0.0625pc;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-top-width: 0.0625pc;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-bottom-style: solid;
}
[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  text-align: right;
}
[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  float: right;
}
[class~="post-details"] [class~="post-content-entry"] {
  margin-top: 15px;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-right-style: solid;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-top-style: solid;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-bottom: 0;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-right: 0.15625in;
}
[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  padding-left: 0.15625in;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-left-color: #e5e5e5;
}
.post-details .comments-area .comment-form .form-group .form-control {
  padding-top: 7.5pt;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-bottom-color: #e5e5e5;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  display: inline-block;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  background: #dedede;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-right-color: #e5e5e5;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  background: #000;
}
[class~="post-details"] [class~="post-content"] blockquote {
  font-size: 12pt;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-top-color: #e5e5e5;
}
.posts-list.list .post-entry .post-meta {
  margin-bottom: 11.25pt;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-left-style: solid;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-image: none;
}
[class~="post-details"] [class~="post-content"] blockquote h2 {
  font-weight: 700;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-left-color: #000;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  font-size: 0;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  overflow-wrap: break-word;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-bottom-color: #000;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a:hover
  [class~="hover-extend"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a:hover
  [class~="title"],
[class~="post-details"] [class~="comments-area"] [class~="comments-title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a:hover
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a:hover
  [class~="hover-extend"] {
  color: #000;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-right-color: #000;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-left-width: medium;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-bottom-width: medium;
}
[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"],
[class~="post-details"] [class~="post-content"] blockquote h2 {
  text-transform: uppercase;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"]
  img,
[class~="post-details"] [class~="post-content"] blockquote h2,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  margin-left: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-right-width: medium;
}
[class~="post-details"] [class~="post-content-entry"] {
  padding-bottom: 0.364583333in;
}
[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"] {
  margin-bottom: 0.3125pc;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-top-color: #000;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  font-size: 0.333333333in;
}
[class~="post-details"] [class~="post-content-entry"] {
  width: 100%;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-top-width: medium;
}
.posts-list.list .post-entry .post-meta {
  flex-wrap: wrap;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  margin-right: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-left-style: none;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-bottom-style: none;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  margin-bottom: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-right-style: none;
}
.posts-list.list .post-entry .post-meta {
  align-items: center;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"]
  img,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  margin-top: 0;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-excerpt"],
.posts-list.list .post-entry .post-meta {
  font-size: 0.15625in;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-top-style: none;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"] {
  display: inline-block;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-left: 20px;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-list"],
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  padding-bottom: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-left-color: currentColor;
}
.posts-list.list .post-entry .post-meta {
  color: #ababab;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-bottom-color: currentColor;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  font-size: 0.208333333in;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-right-color: currentColor;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  overflow: hidden;
}
.posts-list.list .post-entry .post-meta {
  letter-spacing: 0.005208333in;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  margin-top: 40px;
}
[class~="post-details"] [class~="post-content-entry"] {
  border-bottom-width: 1px;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  text-align: center;
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-top-color: currentColor;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  content: "";
}
.post-details .comments-area .comment-form .form-group .form-control {
  border-image: none;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  list-style: none;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  position: absolute;
}
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-excerpt"] {
  margin-bottom: 1.5625pc;
}
[class~="post-details"] [class~="post-content"] blockquote h2,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  label,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"]
  img {
  margin-bottom: 0;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-right: 20px;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  text-overflow: ellipsis;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"],
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-top: 0;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-left: 0;
}
.post-details .comments-area .comment-form .form-group .form-control {
  background: #f5f5f5;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  left: 50%;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"]::placeholder {
  color: #999;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  padding-left: 1.875pc;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  white-space: nowrap;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"]
  img {
  margin-right: 20px;
}
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  background: transparent;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-right: 0;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  top: calc(50% - 39px);
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  overflow-wrap: break-word;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  width: 0.0625pc;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"] [class~="post-content"] blockquote h2,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p {
  margin-right: 0;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"]
  img {
  min-width: 70px;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  height: 4.875pc;
}
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  margin-top: 1.875pc;
}
[class~="post-details"] [class~="comments-area"] [class~="comment-form"] {
  margin-top: 30pt;
}
.post-details .comments-area .comments-list .comment-item,
[class~="post-details"] [class~="post-content-entry"] {
  border-bottom-style: solid;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  label {
  margin-left: 0;
}
.post-details .comments-area .comments-list .comment-item,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"] {
  position: relative;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  label,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  margin-top: 0;
}
[class~="post-details"] [class~="post-content-entry"],
.post-details .comments-area .comments-list .comment-item {
  border-bottom-color: #e5e5e5;
}
.post-details .comments-area .comments-list .comment-item {
  margin-bottom: 30pt;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  font-size: 0.208333333in;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  label {
  margin-right: 2.25pt;
}
[class~="post-details"] [class~="comments-area"] [class~="comment-form"] {
  text-align: center;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  padding-right: 1.875pc;
}
.post-details .comments-area .comments-list .comment-item {
  padding-bottom: 26.25pt;
}
[class~="post-details"] [class~="prev-next-post"]:before {
  background: #e5e5e5;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  overflow: hidden;
}
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  label,
.post-details .comments-area .comment-form .form-header h3 {
  color: #000;
}
.post-details .prev-next-post .next-post a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  color: #ababab;
}
.post-details .prev-next-post .next-post a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  font-size: 15px;
}
.post-details .prev-next-post .next-post a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  text-transform: capitalize;
}
.post-details .comments-area .comments-list .comment-item,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a,
.post-details .prev-next-post .next-post a {
  display: block;
}
[class~="post-details"] [class~="post-content-entry"] {
  border-image: none;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  height: 37.5pt;
}
.post-details .comments-area .comments-list .comment-item,
[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-width: 0.0625pc;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  text-overflow: ellipsis;
}
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  white-space: nowrap;
}
.post-details .comments-area .comment-form .form-header h3 {
  font-size: 24pt;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  line-height: 48px;
}
.post-details .comments-area .comment-form .form-header h3 {
  margin-top: 0;
}
.post-details
  .comments-area
  .comments-list
  .comment-item
  .comment-reply-link:before,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  font-size: 14px;
}
[class~="post-details"] [class~="post-content"] blockquote h2 {
  margin-top: 0.9375pc;
}
.post-details .comments-area .comment-form .form-header h3,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="comment-notes"] {
  margin-bottom: 20px;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p {
  line-height: 1.6;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  text-transform: uppercase;
}
.post-details .comments-area .comments-list .comment-item {
  border-image: none;
}
[class~="post-details"] [class~="prev-next-post"] {
  padding-left: 0;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  font-weight: 500;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="btn"] {
  letter-spacing: 0.1em;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"] {
  margin-bottom: 1.875pc;
}
.post-details
  .comments-area
  .comments-list
  .comment-item
  .comment-reply-link:before {
  content: "";
}
[class~="post-details"] [class~="prev-next-post"],
.post-details
  .comments-area
  .comments-list
  .comment-item
  .comment-reply-link:before {
  display: inline-block;
}
[class~="post-details"] [class~="prev-next-post"] {
  padding-bottom: 1.875pc;
}
[class~="post-details"] [class~="prev-next-post"] {
  padding-right: 0;
}
[class~="post-details"] [class~="prev-next-post"] {
  padding-top: 1.875pc;
}
[class~="post-details"] [class~="prev-next-post"] {
  width: 100%;
}
.post-details
  .comments-area
  .comments-list
  .comment-item
  .comment-reply-link:before {
  font-family: FontAwesome;
}
[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  textarea[class~="form-control"] {
  height: auto;
}
[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-style: solid;
}
[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-color: #e5e5e5;
}
[class~="post-details"] [class~="prev-next-post"] {
  border-image: none;
}
[class~="post-details"] [class~="prev-next-post"] {
  position: relative;
}

/*===================================================================
9. Pages
====================================================================*/
.page-my-account .my-account-navigation ul li a {
  -webkit-transition: all 0.2s ease 0s;
}
.page-my-account .my-account-navigation ul li a {
  transition: all 0.2s ease 0s;
}
.page-login-register .box-form-login h2 {
  text-align: center;
}
.page-login-register .box-form-login .box-content .required {
  color: red;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"] {
  padding-left: 5.625pc;
}
.page-my-account .my-account-navigation ul li a {
  font-weight: 500;
}
.page-login-register .box-form-login .box-content input {
  width: 100%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"] {
  margin-right: 0.104166667in;
}
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .remember-me
  input {
  position: absolute;
}
.page-my-account .my-account-navigation ul li a {
  padding-left: 0;
}
.page-my-account .my-account-navigation ul li a,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"] {
  padding-bottom: 0;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-left-width: 0.125pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"] {
  padding-right: 5.625pc;
}
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .remember-me
  input {
  opacity: 0;
}
.page-my-account .my-account-navigation ul li a {
  padding-right: 0;
}
.page-my-account .my-account-navigation ul li a,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"] {
  padding-top: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"],
.page-login-register .box-form-login h2 {
  position: relative;
}
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .remember-me
  input {
  height: 0.229166667in;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-bottom-width: 0.125pc;
}
[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"],
.page-login-register .box-form-login h2 {
  margin-left: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"],
.page-my-account .my-account-addresses .addresses {
  display: flex;
}
[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"] {
  margin-bottom: 0.15625in;
}
.page-login-register .box-form-login .box-content input {
  height: 3.4375pc;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li a:hover,
[class~="page-my-account"] [class~="my-account-navigation"] ul li a:focus {
  background: transparent;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input {
  padding-left: 1.25pc;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-right-width: 0.125pc;
}
[class~="page-login-register"] [class~="box-form-login"] h2:before {
  font-size: 1.3125pc;
}
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .remember-me
  input {
  cursor: pointer;
}
.page-my-account .my-account-navigation ul li a {
  border-left-width: medium !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"] {
  justify-content: space-between;
}
.page-login-register .box-form-login h2,
[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"] {
  margin-right: 0;
}
.page-login-register .box-form-login h2 {
  margin-bottom: 40px;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  width: 1pc;
}
.page-login-register .box-form-login h2,
.page-my-account .my-account-addresses .addresses,
[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"] {
  margin-top: 0;
}
.page-my-account .my-account-navigation ul li a {
  border-bottom-width: medium !important;
}
.page-my-account .my-account-navigation ul li a {
  border-right-width: medium !important;
}
.page-login-register .box-form-login .box-content input {
  line-height: 3.4375pc;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-top-width: 0.125pc;
}

[class~="page-login-register"] [class~="box-form-login"] {
  border-left-style: solid;
}
.page-my-account .my-account-addresses .addresses,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"] {
  flex-wrap: wrap;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  height: 16px;
}
.page-my-account .my-account-navigation ul li a {
  border-top-width: medium !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  content: "";
}
.page-my-account .my-account-navigation ul li a {
  border-left-style: none !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-login"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"] {
  text-align: center;
}
[class~="page-login-register"] [class~="box-form-login"] h2:before {
  font-family: wpbingofont;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  label,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  label {
  display: block;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-login"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"] {
  margin-top: 1.25pc;
}
.page-login-register .box-form-login h2 {
  font-size: 20px;
}
[class~="page-login-register"] [class~="box-form-login"] h2:before,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  display: inline-block;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:hover:before,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  background: #000;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  label,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  label,
.page-forget-password .reset-password .form-row .input-text {
  width: 100%;
}
.page-my-account .my-account-navigation ul li a {
  border-bottom-style: none !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"] {
  color: #000;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-bottom-style: solid;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  color: #fff;
}
.page-my-account .my-account-addresses .addresses {
  margin-left: -0.9375pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -webkit-border-radius: 50%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -moz-border-radius: 50%;
}
.page-my-account .my-account-navigation ul li a {
  border-right-style: none !important;
}
.page-my-account .my-account-addresses .addresses {
  margin-bottom: 0;
}
.page-my-account .my-account-navigation ul li a {
  border-top-style: none !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -ms-border-radius: 50%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"] {
  font-weight: 500;
}
.page-my-account .my-account-addresses .addresses {
  margin-right: -0.9375pc;
}
.page-login-register .box-form-login h2 {
  padding-bottom: 11.25pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"],
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  cursor: pointer;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  font-size: 11.25pt;
}
.page-my-account .my-account-navigation ul,
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  padding-left: 0;
}
.page-my-account .my-account-navigation ul li a {
  border-left-color: currentColor !important;
}
[class~="page-my-account"] [class~="my-account-content"] a:hover {
  opacity: 0.7;
}
.page-my-account .my-account-navigation ul li a {
  border-bottom-color: currentColor !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  -o-border-radius: 50%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="page-login-register"] [class~="box-form-login"] {
  border-right-style: solid;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-left: 37.5pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-width: 0.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-width: 0.75pt;
}
.page-my-account .my-account-navigation ul,
[class~="page-my-account"] [class~="my-account-navigation"] ul li span,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-bottom: 0;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-left: 0;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-right: 37.5pt;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-bottom: 11.25pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-width: 0.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-width: 0.75pt;
}
[class~="page-login-register"] [class~="box-form-login"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-style: solid;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input,
.page-my-account .my-account-navigation ul,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-top: 0;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-right: 0;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-left-color: #eaeaea;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  line-height: 37.5pt;
}
.page-my-account .my-account-navigation ul li a {
  border-right-color: currentColor !important;
}
.page-my-account .my-account-navigation ul li a {
  border-top-color: currentColor !important;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span,
.page-my-account .my-account-navigation ul,
[class~="page-login-register"] [class~="box-form-login"] {
  padding-right: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input {
  padding-right: 1.25pc;
}
.page-my-account .my-account-navigation ul li a {
  border-image: none !important;
}
[class~="page-my-account"]
  [class~="my-account-navigation"]
  ul
  li
  a[class~="active"] {
  background: transparent;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-bottom-color: #eaeaea;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-right-color: #eaeaea;
}
[class~="page-login-register"] [class~="box-form-login"] h2:before {
  margin-right: 0.625pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-left-color: #0075ff;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-width: 0;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-top-color: #eaeaea;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  -webkit-transition: all 0.2s ease 0s;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-style: solid;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  transition: all 0.2s ease 0s;
}
.page-my-account .my-account-navigation ul {
  list-style: none;
}
[class~="page-login-register"] [class~="box-form-login"] {
  border-image: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-style: solid;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"],
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  font-weight: 500;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-top: 0;
}
[class~="page-about-us"] [class~="block"] [class~="block-title"] {
  text-align: center;
}
[class~="page-about-us"] [class~="block"] [class~="block-title"] {
  margin-bottom: 50px;
}
[class~="page-faq"] [class~="faq-section"] [class~="faq-item"] {
  margin-bottom: 15pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-login"]
  input[type="submit"]:hover {
  opacity: 0.8;
}
.page-my-account .my-account-navigation ul {
  border-bottom-width: medium;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  content: "";
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-color: #000;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-width: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-color: #000;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-color: #000;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  width: 99pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-color: #000;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  em {
  margin-top: 0.052083333in;
}
.page-my-account .my-account-navigation ul,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-style: none;
}
[class~="page-login-register"] [class~="box-form-login"] {
  padding-left: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-image: none;
}
[class~="page-login-register"] [class~="box-form-login"] {
  padding-bottom: 2.5pc;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-width: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-width: medium !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  margin-right: 3.75pt;
}
[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  font-size: 18.75pc;
}
[class~="page-login-register"] [class~="box-form-login"] {
  padding-top: 50px;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-width: medium !important;
}
[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  line-height: 1;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-width: medium !important;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-width: medium !important;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-width: 0;
}
[class~="page-login-register"] [class~="box-form-login"] {
  height: 100%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  position: relative;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-style: none;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  em {
  display: block;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input,
.page-login-register .box-form-login .box-content .password input {
  margin-bottom: 0.9375pc;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  height: 0.125pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  top: 0.1875pc;
}
.page-my-account .my-account-navigation ul,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-color: currentColor;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  z-index: 2;
}
.page-login-register .box-form-login .box-content .password input,
.page-login-register .box-form-login .box-content .username input {
  padding-left: 1.25pc;
}
.page-login-register .box-form-login .box-content .password input {
  padding-bottom: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  background: #f5f5f5;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"],
[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  color: #000;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-style: none;
}
.page-login-register .box-form-login .box-content .password input {
  padding-right: 1.25pc;
}
.page-login-register .box-form-login .box-content .password input {
  padding-top: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-left-width: 0.0625pc;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-style: none;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-width: 0.0625pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  width: 12pt;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  background: #000;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"],
[class~="page-about-us"] [class~="block"] [class~="block-title"] h2 {
  font-size: 50px;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  height: 16px;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  content: "";
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  display: inline-block;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-style: none !important;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-style: none !important;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-style: none !important;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-color: currentColor;
}
.page-my-account .my-account-navigation ul {
  border-image: none;
}
[class~="page-my-account"] [class~="my-account-content"] strong,
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  font-weight: 500;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  margin-bottom: 1.5625pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  -webkit-border-radius: 50%;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-style: none !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  -moz-border-radius: 50%;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-right-width: 0.0625pc;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after,
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  position: absolute;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  -ms-border-radius: 50%;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-top-width: 0.0625pc;
}
[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-style: solid;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  -o-border-radius: 50%;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-color: currentColor !important;
}
[class~="page-faq"] [class~="faq-section"] [class~="section-title"] h2 {
  font-size: 30px;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-color: currentColor !important;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-color: currentColor !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  bottom: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  top: 4.5pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  left: 50%;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  bottom: -2px;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-style: solid;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"]:hover {
  opacity: 0.8;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-color: currentColor !important;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-width: 0.15625pc;
}
[class~="page-faq"] [class~="faq-section"] [class~="section-title"] h2 {
  margin-bottom: 1.25pc;
}
.page-login-register .box-form-login .box-content .username input {
  padding-bottom: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  padding-bottom: 0.104166667in;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  max-width: 3.385416667in;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  display: flex;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-left: auto;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-width: 0.15625pc;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-width: 0.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-width: 0.15625pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-width: 0.15625pc;
}
.page-login-register .box-form-login h2.register:before {
  content: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-bottom-color: #0075ff;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-image: none !important;
}
.page-login-register .box-form-login .box-content .username input {
  padding-right: 1.25pc;
}
[class~="page-login-register"] [class~="box-form-login"] h2:after {
  left: calc(50% - 66px);
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  content: "";
}
.page-login-register .box-form-login .box-content .username input {
  padding-top: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-style: solid;
}
[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-style: solid;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-color: currentColor;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  width: 105pt;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-color: currentColor;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-image: none;
}
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  margin-top: 7.5pt;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  height: 0.020833333in;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  -webkit-transform: translateX(-50%);
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-bottom: auto;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-color: #fff;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-color: #fff;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-color: #fff;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-left-width: 0;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  flex: 0 0 50%;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  max-width: 50%;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-right: auto;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  padding-right: 11.25pt;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-left-color: #f5f5f5;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  padding-left: 0.9375pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-color: #fff;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-top: auto;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-image: none;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-left: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-color: #f5f5f5;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-bottom-width: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  font-size: 0.166666667in;
}
[class~="page-my-account"] [class~="my-account-orders"] table,
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  border-bottom-width: 0.010416667in;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  justify-content: space-between;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  left: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-right-color: #f5f5f5;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li,
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-bottom-style: solid;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-right-width: 0.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  background: #0075ff;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-right-width: 0;
}
.page-login-register .box-form-login .box-content .username input {
  margin-bottom: 0.9375pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .lost-password
  a {
  display: inline-block;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-top-width: 0.75pt;
}
[class~="page-404"],
.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .lost-password
  a {
  position: relative;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-top-width: 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  padding-left: 1.25pc;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-left-style: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  margin-top: 22.5pt;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-top-color: #f5f5f5;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-style: solid;
}
.page-my-account .my-account-navigation ul li:last-child {
  border-bottom-width: medium;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-bottom: 0.15625in;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-left: 2.5pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  z-index: 1;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  opacity: 0;
}
.page-my-account .my-account-navigation ul li:last-child,
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-bottom-style: none;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-right-style: none;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-top-style: none;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  padding-bottom: 15px;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-right: 0;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-left-color: currentColor;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  flex-wrap: wrap;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  padding-right: 1.25pc;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-top: 0.15625in;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  border-bottom-color: hsla(0, 0%, 90%, 0.5);
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="input-text"] {
  width: 100%;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  bottom: 0;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-bottom: 0;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  color: #fff;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
.page-my-account .my-account-navigation ul li:last-child {
  border-bottom-color: currentColor;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-404"] [class~="content-page-404"] [class~="button"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  background: #000;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-right-color: currentColor;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  padding-top: 15px;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  left: calc(50% - 70px);
}
[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  border-image: none;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-left-width: 0;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  align-items: center;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  font-size: 15px;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-right: 2.5pc;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-width: 0;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  padding-left: 3.125pc;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-top: 0;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-right-style: solid;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  height: 3.125pc;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-left-width: 0.0625pc;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  padding-right: 3.125pc;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-right-width: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  line-height: 34.5pt;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  list-style-type: none;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-top-color: currentColor;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
.page-my-account .my-account-navigation ul li:last-child {
  border-image: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  font-weight: 500;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-top-width: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-left-style: none;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-bottom-width: 0.0625pc;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-left: 20px;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  line-height: 37.5pt;
}
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"]:before {
  background: #e1e1e1;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-right-width: 0.0625pc;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  cursor: pointer;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-left: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  font-size: 13.5pt;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  margin-bottom: 2.5pc;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-bottom-style: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  font-size: 10.5pt;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-left: 1.875pc;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-bottom: 0.625pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-radius: 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-top-width: 0.0625pc;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-bottom: 0.125in;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-right: 15pt;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-right-style: none;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-top: 0.125in;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-top-style: none;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-bottom: 1.25pc;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-right: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-top: 0.625pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  -webkit-border-radius: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-left-color: currentColor;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  -moz-border-radius: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-color: currentColor;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  line-height: 1.8;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transform: translateX(-50%);
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-left-style: solid;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  content: none;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-right: 1.875pc;
}
.page-404 .content-page-404 .button:after {
  display: inline-block;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-right-color: currentColor;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  vertical-align: top;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-top: 1.25pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  -ms-border-radius: 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col address,
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-top-style: solid;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  -o-border-radius: 0;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  width: 30%;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-top-color: currentColor;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  vertical-align: middle;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  font-size: 0.166666667in;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  color: #000;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"]:hover {
  opacity: 0.8;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-color: #e6e6e6;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-bottom-style: solid;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-image: none;
}
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  width: 100%;
}
[class~="page-my-account"] [class~="my-account-navigation"] {
  float: left;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-right-style: solid;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  font-size: 9.75pt;
}
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  font-weight: 500;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-bottom-color: #e6e6e6;
}
.page-my-account .my-account-content {
  width: 68%;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-left-style: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  position: relative;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  z-index: 1;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-style: none;
}
.page-my-account .my-account-orders table tr td {
  padding-left: 0.208333333in;
}
.page-my-account .my-account-content {
  float: right;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-left-width: 0.125pc;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-right-color: #e6e6e6;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  position: absolute;
}
.page-my-account .my-account-orders table tr td {
  padding-bottom: 0.625pc;
}
[class~="page-404"] [class~="content-page-404"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-left: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-bottom: 0;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"],
.page-my-account .my-account-orders table tr td {
  padding-right: 0.208333333in;
}
.page-my-account .my-account-orders table tr td {
  padding-top: 0.625pc;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-right: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-top: 0;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  color: #fff;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-bottom-width: 0.125pc;
}
.page-my-account .my-account-content {
  font-size: 15px;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  cursor: pointer;
}
.page-my-account .my-account-orders table tr td {
  line-height: 1.8;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  padding-bottom: 0.75pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-right-width: 0.125pc;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  text-transform: uppercase;
}
.page-my-account .my-account-orders table tr td {
  vertical-align: top;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-image: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-right-color: #0075ff;
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-top-color: #e6e6e6;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  margin-top: 10px;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-top-width: 0.125pc;
}
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  letter-spacing: 0.010416667in;
}
.page-404 .content-page-404 .button:after {
  content: "$";
}
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-image: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  background: #000;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-width: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-width: 0;
}
.page-my-account .my-account-content {
  line-height: 1.4;
}
[class~="page-about-us"]
  [class~="block"]
  [class~="block-title"]
  [class~="sub-title"] {
  font-size: 22px;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-left-style: solid;
}
[class~="page-faq"] [class~="faq-section"] {
  margin-bottom: 3.75pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-width: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-bottom-style: solid;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-width: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-right-style: solid;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-top-style: solid;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-left-color: #000;
}
[class~="page-faq"]
  [class~="faq-section"]
  [class~="faq-item"]
  [class~="faq-question"] {
  color: #656565;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-left-color: #f5f5f5;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-style: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-style: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-bottom-color: #000;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-bottom-color: #f5f5f5;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-right-color: #000;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-style: none;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-top-color: #000;
}
[class~="page-faq"]
  [class~="faq-section"]
  [class~="faq-item"]
  [class~="faq-question"] {
  font-size: 0.166666667in;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-image: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-style: none;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-color: currentColor;
}
[class~="page-faq"]
  [class~="faq-section"]
  [class~="faq-item"]
  [class~="faq-question"] {
  line-height: 1.5;
}
[class~="page-404"] [class~="content-page-404"] {
  padding-bottom: 0.625in;
}
[class~="page-faq"]
  [class~="faq-section"]
  [class~="faq-item"]
  [class~="faq-question"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  font-weight: 500;
}
.page-404 .content-page-404 .button:after {
  font-family: eleganticons;
}
[class~="page-faq"]
  [class~="faq-section"]
  [class~="faq-item"]
  [class~="faq-question"] {
  margin-bottom: 0.375pc;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  letter-spacing: 0.1em;
}
.page-404 .content-page-404 .button:after {
  font-size: 1.25pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-color: currentColor;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-color: currentColor;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-color: currentColor;
}
[class~="page-404"] [class~="content-page-404"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-right: 0;
}
[class~="page-404"] [class~="content-page-404"] [class~="button"]:hover {
  background: #fff;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  padding-top: 0.75pc;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-image: none;
}
[class~="page-my-account"] [class~="my-account-account-details"] legend,
[class~="page-404"] [class~="content-page-404"] [class~="button"]:hover {
  color: #000;
}
.page-404 .content-page-404 .button:after {
  line-height: 1;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  font-size: 9.75pt;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  background: #f5f5f5;
}
[class~="page-my-account"] [class~="my-account-account-details"] fieldset,
[class~="page-my-account"] [class~="my-account-account-details"] legend {
  margin-top: 22.5pt;
}
[class~="page-my-account"] [class~="my-account-account-details"] legend {
  font-weight: 400;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-left: 0;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-right-color: #f5f5f5;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-bottom: 0;
}
.page-404 .content-page-404 .button:after {
  position: relative;
}
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"]:hover {
  opacity: 0.8;
}
[class~="page-404"] [class~="content-page-404"] {
  padding-top: 1.041666667in;
}
.page-404 .content-page-404 .button:after {
  top: 3.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-top: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  color: #fff;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-top-color: #f5f5f5;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  cursor: pointer;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  text-transform: uppercase;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  letter-spacing: 0.010416667in;
}
.page-my-account .my-account-addresses .addresses .addresses-col address {
  border-image: none;
}
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  font-size: 20px;
}
.page-my-account .my-account-addresses .addresses .addresses-col address,
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  margin-bottom: 0;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  width: 100%;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-top-color: #0075ff;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:after {
  opacity: 1;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  height: 0.75pt;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  -webkit-transition: all 0.3s ease;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transition: all 0.3s ease;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  background: #000;
}
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:hover:before {
  width: 50%;
}
[class~="page-404"] {
  text-align: center;
}

/*===================================================================
10. Footer
====================================================================*/
#site-footer.m-t-0,
[class~="home"] #site-footer {
  margin-top: 0;
}
#site-footer[class~="small-space"] [class~="row"] > div {
  padding-left: 0.15625in;
}
#site-footer[class~="small-space"] [class~="footer-top"] {
  padding-bottom: 37.5pt;
}
#site-footer .footer-bottom ul,
#site-footer [class~="footer-bottom"] p {
  margin-bottom: 0;
}
#site-footer[class~="small-space"] [class~="footer-top"] {
  margin-bottom: 50px;
}
#site-footer[class~="m-t-30"] {
  margin-top: 1.875pc;
}
#site-footer.small-space {
  padding-top: 50px;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:hover:before {
  width: 100%;
}
#site-footer.small-space .row {
  margin-left: -0.9375pc;
}
#site-footer.small-space .row,
#site-footer .social-link li {
  margin-bottom: 0;
}
#site-footer[class~="background"] {
  background: #f4f4f4;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  position: absolute;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li:first-child {
  margin-left: 0;
}
#site-footer[class~="dark"] [class~="footer-bottom"],
#site-footer {
  border-top-width: 0.0625pc;
}
#site-footer.small-space .row {
  margin-right: -0.9375pc;
}
#site-footer[class~="background"] {
  border-top-width: medium;
}
#site-footer .social-link li,
#site-footer .footer-bottom .footer-right .block-menu ul li,
#site-footer [class~="social-link"] li a {
  display: inline-block;
}
#site-footer[class~="background"] {
  border-top-style: none;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li:last-child {
  margin-right: 0;
}
#site-footer [class~="footer"] [class~="block"][class~="m-b-15"] {
  margin-bottom: 15px;
}
#site-footer[class~="background"] {
  border-top-color: currentColor;
}
#site-footer.small-space .row,
#site-footer .social-link li {
  margin-top: 0;
}
#site-footer ul,
#site-footer[class~="small-space"] [class~="row"] > div {
  padding-bottom: 0;
}
#site-footer [class~="footer"] [class~="block"] {
  margin-bottom: 30pt;
}
#site-footer ul {
  list-style: none;
}
#site-footer [class~="block"] [class~="block-title"] {
  font-size: 14px;
}
#site-footer ul li a:hover,
#site-footer .social-link li a i,
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  color: #000;
}
#site-footer[class~="background"] {
  border-image: none;
}
#site-footer [class~="block"] [class~="block-title"] {
  text-transform: uppercase;
}
#site-footer ul {
  padding-left: 0;
}
#site-footer [class~="block"] [class~="block-title"] {
  letter-spacing: 2px;
}
#site-footer [class~="block"] [class~="block-title"] {
  line-height: 1;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  content: "";
}
#site-footer [class~="block"] [class~="block-title"],
#site-footer .footer .block.m-b-20 {
  margin-bottom: 15pt;
  font-size: 16pt;
}
#site-footer .social-link li {
  margin-left: 7.5pt;
}
#site-footer[class~="dark"] {
  background: #161616;
}
#site-footer,
#site-footer[class~="dark"] [class~="footer-bottom"] {
  border-top-style: solid;
}
#site-footer[class~="dark"] [class~="footer-bottom"] {
  border-top-color: #2d2d2d;
}
#site-footer[class~="dark"] [class~="footer-bottom"] {
  border-image: none;
}
#site-footer [class~="footer-bottom"][class~="background-white"] {
  background: #fff;
}
#site-footer [class~="footer-bottom"][class~="no-border-top"] {
  border-top-width: medium;
}
#site-footer [class~="footer-bottom"][class~="no-border-top"] {
  border-top-style: none;
}
#site-footer [class~="footer-bottom"][class~="no-border-top"] {
  border-top-color: currentColor;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  background: #000;
}
#site-footer [class~="footer-bottom"][class~="no-border-top"] {
  border-image: none;
}
#site-footer .social-link li {
  margin-right: 7.5pt;
}
#site-footer[class~="small-space"] [class~="row"] > div {
  padding-right: 0.15625in;
}
#site-footer[class~="small-space"] [class~="footer-bottom"] {
  margin-top: 0.104166667in;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  bottom: 0;
}
#site-footer [class~="footer-bottom"] [class~="footer-right"] {
  text-align: right;
}
#site-footer .social-link li,
#site-footer.three-columns .column-center {
  text-align: center;
}
#site-footer.three-columns .column-center {
  border-left-width: 0.75pt;
}
#site-footer [class~="footer-top"] {
  border-bottom-width: 1px;
}
#site-footer.three-columns .column-center {
  border-left-style: solid;
}
#site-footer [class~="footer-top"] {
  border-bottom-style: solid;
}
#site-footer.three-columns .column-center {
  border-left-color: #e8e8e8;
}
#site-footer.three-columns .column-center {
  border-image: none;
}
#site-footer ul {
  padding-right: 0;
}
#site-footer[class~="small-space"] [class~="row"] > div,
#site-footer ul {
  padding-top: 0;
}
#site-footer.three-columns .column-center {
  border-right-width: 0.75pt;
}
#site-footer.three-columns .column-center {
  border-right-style: solid;
}
#site-footer {
  border-top-color: #e5e5e5;
}
#site-footer [class~="footer-top"] {
  border-bottom-color: #e5e5e5;
}
#site-footer.three-columns .column-center {
  border-right-color: #e8e8e8;
}
#site-footer.three-columns .column-center {
  -ms-flex: 0 0 28%;
}
#site-footer.three-columns .column-center {
  flex: 0 0 28%;
}
#site-footer.three-columns .column-center {
  max-width: 28%;
}
#site-footer [class~="footer-top"],
#site-footer {
  border-image: none;
}
#site-footer[class~="three-columns"] [class~="column-left"],
#site-footer.three-columns .column-center,
#site-footer[class~="three-columns"] [class~="column-right"] {
  padding-top: 60px;
}
#site-footer.three-columns .column-center {
  padding-bottom: 60px;
}
#site-footer.three-columns .column-center,
#site-footer[class~="three-columns"] [class~="column-right"] {
  display: flex;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  left: 0;
}
#site-footer.three-columns .column-center {
  align-content: center;
}
#site-footer.three-columns .column-center {
  align-items: center;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  width: 0;
}
#site-footer.three-columns .column-center {
  justify-content: center;
}
#site-footer ul,
#site-footer[class~="three-columns"]
  [class~="column-center"]
  [class~="block"]:last-child {
  margin-bottom: 0;
}
#site-footer [class~="footer-top"] {
  padding-bottom: 0.729166667in;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  font-size: 9pt;
}
#site-footer ul li a {
  color: #868686;
}
#site-footer[class~="three-columns"] [class~="column-right"],
#site-footer[class~="three-columns"] [class~="column-left"] {
  -ms-flex: 0 0 36%;
}
#site-footer [class~="footer-top"] {
  margin-bottom: 70px;
}
#site-footer[class~="three-columns"] [class~="column-right"],
#site-footer[class~="three-columns"] [class~="column-left"] {
  flex: 0 0 36%;
}
#site-footer [class~="footer-bottom"] {
  border-top-width: 0.010416667in;
}
#site-footer [class~="social-link"] li:first-child {
  margin-left: 0;
}
#site-footer [class~="footer-bottom"] {
  border-top-style: solid;
}
#site-footer .social-link li a i {
  font-size: 16px;
}
#site-footer[class~="three-columns"] [class~="column-right"],
#site-footer[class~="three-columns"] [class~="column-left"] {
  max-width: 36%;
}
#site-footer[class~="three-columns"] [class~="column-right"],
#site-footer[class~="three-columns"] [class~="column-left"] {
  padding-bottom: 1.25pc;
}
#site-footer .footer-bottom .footer-right .block-menu ul li {
  margin-left: 15px;
}
#site-footer [class~="footer-bottom"] {
  border-top-color: #e5e5e5;
}
#site-footer [class~="footer-bottom"] {
  border-image: none;
}
#site-footer.dark .block .block-title,
#site-footer[class~="dark"] ul li a:hover,
#site-footer[class~="dark"] [class~="social-link"] li a i {
  color: #fff;
}
#site-footer [class~="footer-bottom"] {
  margin-top: 30px;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  letter-spacing: 2px;
}
#site-footer[class~="three-columns"] [class~="column-right"] {
  justify-content: flex-end;
}
#site-footer[class~="three-columns"] [class~="footer-bottom"] {
  margin-top: 0;
}
#site-footer[class~="four-columns"] [class~="column-1"],
#site-footer.four-columns .column-2,
#site-footer[class~="four-columns"] [class~="column-3"] {
  -ms-flex: 0 0 22.5%;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  line-height: 1.8;
}
#site-footer[class~="four-columns"] [class~="column-3"],
#site-footer[class~="four-columns"] [class~="column-1"],
#site-footer.four-columns .column-2 {
  flex: 0 0 22.5%;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before,
#site-footer .social-link li a i {
  -webkit-transition: all 0.3s ease;
}
#site-footer[class~="four-columns"] [class~="column-1"],
#site-footer[class~="four-columns"] [class~="column-3"],
#site-footer.four-columns .column-2 {
  max-width: 22.5%;
}
#site-footer .footer-bottom .footer-right .block-menu ul li {
  margin-right: 0.15625in;
}
#site-footer [class~="social-link"] li:last-child {
  margin-right: 0;
}
#site-footer [class~="footer-bottom"] {
  padding-left: 0;
}
#site-footer [class~="footer-bottom"] {
  padding-bottom: 15pt;
}
#site-footer [class~="footer-bottom"] {
  padding-right: 0;
}
#site-footer [class~="footer-bottom"] {
  padding-top: 15pt;
}
#site-footer [class~="footer"] [class~="block"][class~="m-b-30"] {
  margin-bottom: 1.875pc;
}
#site-footer[class~="four-columns"] [class~="column-4"] {
  -ms-flex: 0 0 32.5%;
}
#site-footer[class~="three-columns"] {
  padding-top: 0;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  height: 0.0625pc;
}
#site-footer[class~="four-columns"] [class~="column-4"] {
  flex: 0 0 32.5%;
}
#site-footer[class~="four-columns"] [class~="column-4"] {
  max-width: 32.5%;
}
#site-footer ul li,
#site-footer p {
  margin-bottom: 0.5pc;
}
#site-footer {
  padding-top: 0.729166667in;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before,
#site-footer .social-link li a i {
  transition: all 0.3s ease;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  text-transform: uppercase;
}
#site-footer {
  margin-top: 70px;
}
#site-footer {
  color: #868686;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  display: inline-block;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  position: relative;
}
#site-footer .footer-bottom .block-widget-wrap > .row {
  align-items: center;
}
#site-footer [class~="social-link"] li a:hover i {
  font-size: 15pt;
}
#site-footer[class~="three-columns"]
  [class~="column-right"]
  [class~="column-wrap"] {
  max-width: 3.75in;
}

/* extra css */
.demo-img {
  /* width: 100px; */
  height: 300px;
  object-fit: contain;
  object-position: right;
}
.demo-img-1 {
  width: auto !important;
  height: 530px !important;
  margin-left: auto;
  margin-right: 100px;
}
#topbar-menu .input-group {
  border: 1px solid #ced4da;
}
#topbar-menu .input-group .form-control {
  border-radius: 0;
  border: none;
  width: 300px;
}
#topbar-menu .input-group-text {
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-left: 1px solid #ced4da;
  cursor: pointer;
}
.block-banners {
  background-color: #f8f8f8;
}
.block-banners .bg-banner {
  background-color: #f8f8f8;
}
.block-banners .bg-banner .banner-image {
  border: 4px solid #f8f8f8;
}
