.product-wapper .products-thumb .product-button .product-btn-active {
  font-size: 0;
  background: #301f1f !important;
  text-align: center;
  position: relative;
  white-space: nowrap;
  margin-top: 0;
  border: 0;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.product-wapper .products-thumb .product-button .product-btn-active:before {
  content: "\e634";
  font-family: themify;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
