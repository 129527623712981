.wishlis::before {
  content: "\e634" !important;
  font-family: themify !important;
  color: white !important;
  background-color: black !important;
}
.wishlist::before {
  content: "\e634" !important;
  font-family: themify !important;
}
.shop-details .variations .attributes ul.colors li span {
  /* background: blue !important; */
  border: 1px solid !important;
  font-size: 16px;
  text-align: center;
}
.shop-details .variations .attributes ul.colors li span.active:before {
  border: 1px solid black !important;
  visibility: visible;
  opacity: 1;
  /* width: fit-content; */
}
.shop-details .variations .attributes ul.colors li span:hover:before {
  opacity: 1;
  visibility: visible;
}
