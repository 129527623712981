.slick-dots {
  padding-bottom: 40px !important;
}

/* .slick-list{
  padding: 0px 20px;
} */

.slick-prev {
  left: 0% !important;
  z-index: 1;
}
.slick-next {
  right: 0.5% !important;
  z-index: 1;
}

iframe{
  visibility: hidden!important;
}
.skiptranslate{
  margin-bottom: -39px;
}